import React from 'react';
import {
	Badge, Box, Button, Checkbox, Divider, Flex, FormControl, FormLabel, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spacer, Text, VStack, useColorModeValue as mode, Select, InputGroup, InputLeftElement, InputRightElement
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import EndAfter from './After';
import EndOnDate from './OnDate';

import translateLabel from '../../utils/translateLabel';

const End = ({
	id,
	end: {
		mode,
		after,
		onDate,
		options,
	},
	handleChange,
	translations
}) => {
	const isOptionAvailable = option => !options.modes || options.modes.indexOf(option) !== -1;
	const isOptionSelected = option => mode === option;

	return (
		<Box py={3}>
			<FormLabel fontWeight={600}>
				{translateLabel(translations, 'end.label')}
			</FormLabel>
			<Select
				name="end.mode"
				id={id}
				value={mode}
				onChange={handleChange}
			>
				{isOptionAvailable('Never') && <option value="Never">{translateLabel(translations, 'end.never')}</option>}
				{isOptionAvailable('After') && <option value="After">{translateLabel(translations, 'end.after')}</option>}
				{isOptionAvailable('On date') && <option value="On date">{translateLabel(translations, 'end.on_date')}</option>}
			</Select>

			{
				isOptionSelected('After') &&
				<EndAfter
					id={`${id}-after`}
					after={after}
					handleChange={handleChange}
					translations={translations}
				/>
			}
			{
				isOptionSelected('On date') &&
				<EndOnDate
					id={`${id}-onDate`}
					onDate={onDate}
					handleChange={handleChange}
					translations={translations}
				/>
			}
		</Box>
	);
};

End.propTypes = {
	id: PropTypes.string.isRequired,
	end: PropTypes.shape({
		mode: PropTypes.string.isRequired,
		after: PropTypes.number.isRequired,
		onDate: PropTypes.object.isRequired,
		options: PropTypes.shape({
			modes: PropTypes.arrayOf(PropTypes.oneOf(['Never', 'After', 'On date'])),
			weekStartsOnSunday: PropTypes.bool,
		}).isRequired,
	}).isRequired,
	handleChange: PropTypes.func.isRequired,
	translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default End;
