import React from 'react';
import {
	Badge, Box, Button, Checkbox, Divider, Flex, FormControl, FormLabel, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spacer, Text, VStack, useColorModeValue as mode, Select, InputGroup, InputLeftElement, InputRightElement
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import RepeatMonthlyOn from './On';
import RepeatMonthlyOnThe from './OnThe';
import numericalFieldHandler from '../../../utils/numericalFieldHandler';
import translateLabel from '../../../utils/translateLabel';

const RepeatMonthly = ({
	id,
	monthly: {
		mode,
		interval,
		on,
		onThe,
		options,
	},
	handleChange,
	translations
}) => {
	const isTheOnlyOneMode = option => options.modes === option;
	const isOptionAvailable = option => !options.modes || isTheOnlyOneMode(option);

	return (
		<Box mt={4}>
			<HStack>
				<Text>{translateLabel(translations, 'repeat.monthly.every')}</Text>
				<Input
						px={15}
						id={`${id}-interval`}
						name="repeat.monthly.interval"
						aria-label="Repeat monthly interval"
						value={interval}
						onChange={numericalFieldHandler(handleChange)}
					/>
					<Text>{translateLabel(translations, 'repeat.monthly.months')}</Text>
			</HStack>

			{isOptionAvailable('on') && (
				<RepeatMonthlyOn
					id={`${id}-on`}
					mode={mode}
					on={on}
					hasMoreModes={!isTheOnlyOneMode('on')}
					handleChange={handleChange}
					translations={translations}
				/>
			)}
			{isOptionAvailable('on the') && (
				<RepeatMonthlyOnThe
					id={`${id}-onThe`}
					mode={mode}
					onThe={onThe}
					hasMoreModes={!isTheOnlyOneMode('on the')}
					handleChange={handleChange}
					translations={translations}
				/>
			)}

		</Box>
	);
};

RepeatMonthly.propTypes = {
	id: PropTypes.string.isRequired,
	monthly: PropTypes.shape({
		mode: PropTypes.oneOf(['on', 'on the']).isRequired,
		interval: PropTypes.number.isRequired,
		on: PropTypes.object.isRequired,
		onThe: PropTypes.object.isRequired,
		options: PropTypes.shape({
			modes: PropTypes.oneOf(['on', 'on the']),
		}).isRequired,
	}).isRequired,
	handleChange: PropTypes.func.isRequired,
	translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default RepeatMonthly;
