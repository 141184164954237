import React from "react";
import { Box, Flex, Image, chakra, Badge, Text } from "@chakra-ui/react";
import HTMLDecoderEncoder from 'html-encoder-decoder';
import parse from "html-react-parser";
import { useHistory } from 'react-router-dom';

const uribase = 'https://apiv2.beantrack.app/images/';

export default function App({ workshop, openProduct }){
	const history = useHistory()

	return (
		<Box
			maxW="md"
			mx="auto"
			bg="white"
			_dark={{ bg: "gray.700" }}
			shadow="lg"
			rounded="lg"
			w={"100%"}
		>
			<Image
				h={48}
				w="full"
				fit="cover"
				borderTopRadius={"md"}
				src={(workshop.cover&&uribase+workshop.cover)||uribase+"workshop-ph.jpg"}
				alt={workshop.productName}
				cursor={"pointer"}
				onClick={()=>history.push("/workshops/"+workshop.Item)}
			/>
			<Box px={4} py={2} h={{base: "auto", md:"170px"}}>
				<chakra.h1
					color="gray.800"
					_dark={{ color: "white" }}
					fontWeight="bold"
					fontSize="lg"
					cursor={"pointer"}
					onClick={()=>history.push("/workshops/"+workshop.Item)}
					/* textTransform="uppercase" */
				>
					{workshop.productName}
				</chakra.h1>
				<chakra.div
					mt={1}
					fontSize="sm"
					color="gray.600"
					_dark={{ color: "gray.200" }}
				>
					{((workshop.AfspraakAantal&&workshop.AfspraakAantalKlaar<workshop.AfspraakAantal)&&<Badge mb={2} color={"gray.700"} _dark={{color: "gray.200"}}>
						<>{workshop.AfspraakAantal-workshop.AfspraakAantalKlaar} X Beschikbaar tegen {workshop.AfspraakKorting}% korting</>
					</Badge>)||""}
					<Text as={Box} noOfLines={3}>{workshop.productDescription&&parse(HTMLDecoderEncoder.decode(workshop.productDescription))}</Text>
				</chakra.div>
			</Box>
			<Flex
				alignItems="center"
				justifyContent="space-between"
				px={4}
				py={2}
				_dark={{bg: "green.900"}}
				bg="green.800"
				roundedBottom="lg"
			>
				<chakra.h1 color="white" fontWeight="bold" fontSize="lg">
					€ {workshop.NewPrice.toFixed(2)}
				</chakra.h1>
				<chakra.button
					px={2}
					py={1}
					bg="green.400"
					fontSize="xs"
					color="white"
					fontWeight="bold"
					rounded="lg"
					textTransform="uppercase"
					_dark={{
						bg: "green.700",
					}}
					_hover={{
						bg: "green.200",
					}}
					_focus={{
						bg: "green.400",
					}}
					onClick={()=>history.push("/workshops/"+workshop.Item)}
				>
					Meer...
				</chakra.button>
			</Flex>


		</Box>
	);
};

