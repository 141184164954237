import { v4 as uuidv4 } from 'uuid';
import _ from "lodash";

const porto = {
    vatLow: "c7485114-4dcb-4a91-8b21-377ea3fc667d",
    vatHigh: "138066a4-7ab5-41cf-9210-716d8b08b46a",
    free: "739c25fd-f3de-4bc0-bf86-cbfed16f0a00"
};

export const shippingCheck = (items) => {
    const total = _.sumBy(items, "itemTotal");
    if (total === 0) return 0;
    if (total >= 215) return 0;
    if (total < 215) return 6;
};

export const simpShippingCheck = (total) => {
    if (total === 0) return 0;
    if (total >= 215) return 0;
    if (total < 215) return 6;
};

export const calculateCart = (items, getOrderId) => {
    const shippingconst = shippingCheck(items);
    const itemsClean = items.map(o=>{
        return {
            "ID": uuidv4(),
            "Item": o.Item,
            "NetPrice": Number(o.price),
            "OrderID": getOrderId,
            "Quantity": o.quantity,
            "UnitCode": o.Unit,
            "SalesVatCode": o.SalesVatCode,
            "ItemDescription": o.ItemDescription
        }
    });
    const itemsCleann = items.map(o=>{
        return {
            "ID": uuidv4(),
            "Item": o.Item,
            "NetPrice": Number(o.price),
            "OrderID": getOrderId,
            "Quantity": o.quantity,
            "UnitCode": o.Unit,
            "ItemDescription": o.ItemDescription
        }
    })
    const total = _.sumBy(itemsClean, function (o) { return _.round(o.Quantity * o.NetPrice, 2); });
    const groupByVat = _.groupBy(itemsClean, "SalesVatCode");
    let totalForVatHigh, totalForVatLow = null;
    if (_.isEmpty(itemsClean)) return [];
    if (shippingconst===0) {
        return [...itemsCleann, {
            "ID": uuidv4(),
            "Item": porto.free,
            "NetPrice": 0,
            "OrderID": getOrderId,
            "Quantity": 1
        }]
    }
    if (_.has(groupByVat, "4")) {
        totalForVatLow = _.sumBy(groupByVat["4"], function (o) { return _.round(o.Quantity * o.NetPrice, 2); }); }
    if (_.has(groupByVat, "1")) {
        totalForVatHigh = _.sumBy(groupByVat["1"], function (o) { return _.round(o.Quantity * o.NetPrice, 2); }); }
    if (totalForVatHigh && !totalForVatLow) {
        return [...itemsCleann, {
            "ID": uuidv4(),
            "Item": porto.vatHigh,
            "NetPrice": shippingconst,
            "OrderID": getOrderId,
            "Quantity": 1
        }]
    }
    if (totalForVatLow && !totalForVatHigh) {
        return [...itemsCleann, {
            "ID": uuidv4(),
            "Item": porto.vatLow,
            "NetPrice": shippingconst,
            "OrderID": getOrderId,
            "Quantity": 1
        }]
    }
    const vatHighPart = totalForVatHigh / total;
    const vatLowPart = totalForVatLow / total;
    const portoCostVatHigh = _.round(vatHighPart * shippingconst, 2);
    const portoCostVatLow = _.round(vatLowPart * shippingconst, 2);
    return [...itemsCleann, {
        "ID": uuidv4(),
        "Item": porto.vatHigh,
        "NetPrice": portoCostVatHigh,
        "OrderID": getOrderId,
        "Quantity": 1
    }, {
        "ID": uuidv4(),
        "Item": porto.vatLow,
        "NetPrice": portoCostVatLow,
        "OrderID": getOrderId,
        "Quantity": 1
    }]
};