import React from 'react';
import { Box, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
import 'devextreme-react/text-area';
import { useHistory } from "react-router-dom";

function BTpIndex() {
	const history = useHistory();
	const t_listItem_bg = useColorModeValue("white", "gray.700");

	return (

		<Box h="fit-content" maxW="100%" m="0 auto" px={{sm: "2vw", md: "5vw", lg: "7vw"}} py={4}>
            <Box pb='4'>
                <Text fontWeight={600} fontSize={"lg"}>Mijn account</Text>
            </Box>

            <SimpleGrid columns={{md: 1, lg: 2}} spacing='20px'>
                <Box borderRadius={"lg"} bgColor={t_listItem_bg} scale w="100%" py={8} px={8}_hover={{ filter: "brightness(90%)", cursor: "pointer", bg: t_listItem_bg }} onClick={()=>history.push("/mijn-account/wachtwoord-wijzigen")} >
                    <Text fontSize={"1.4rem"}><i className='fad fa-edit'/> Wachtwoord wijzigen</Text>
                </Box>
                <Box borderRadius={"lg"} bgColor={t_listItem_bg} scale w="100%" py={8} px={8}_hover={{ filter: "brightness(90%)", cursor: "pointer", bg: t_listItem_bg }} onClick={()=>history.push("/mijn-account/afleveradressen")} >
                    <Text fontSize={"1.4rem"}><i className='fad fa-truck-loading'/> Afleveradressen</Text>
                </Box>
                <Box borderRadius={"lg"} bgColor={t_listItem_bg} scale w="100%" py={8} px={8}_hover={{ filter: "brightness(90%)", cursor: "pointer", bg: t_listItem_bg }} onClick={()=>history.push("/mijn-account/gebruikers")} >
                    <Text fontSize={"1.4rem"}><i className='fad fa-users'/> Gebruikers</Text>
                </Box>
                {/* <Box borderRadius={"lg"} bgColor={t_listItem_bg} scale w="100%" py={8} px={8}_hover={{ filter: "brightness(90%)", cursor: "pointer", bg: t_listItem_bg }} onClick={()=>{}} >
                    <Text fontSize={"1.4rem"}><i className='fad fa-cog'/> Account settings</Text>
                </Box> */}
			</SimpleGrid>
		</Box>
    );
}

export default BTpIndex;