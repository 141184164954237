import axios from 'axios';
import * as _bulk from "./_exact/bulk";
import * as _crm from "./_exact/crm";
import * as _documents from "./_exact/documents";
import * as _inventory from "./_exact/inventory";
import * as _logistics from "./_exact/logistics";
import * as _manufacturing from "./_exact/manufacturing";
import * as _sales from "./_exact/sales";
import * as _salesInvoice from "./_exact/salesInvoice";
import * as _salesOrder from "./_exact/salesOrder";

const __B2BAPI = axios.create({
	baseURL: `https://apiv2.beantrack.app/api/b2bv1`,
	timeout: 128000,
	headers: { Authorization: "Bearer "+process.env.REACT_APP_BEANTRACK_KEY }
});

const __TRIBE = axios.create({
	baseURL: `http://127.0.0.1:3030/api/v1/`,
	timeout: 128000,
	headers: { Authorization: "Bearer "+process.env.REACT_APP_BEANTRACK_KEY }
});

const __B2BAPI_V3 = axios.create({
	baseURL: `https://apiv3.beantrack.app/api/v3`,
	timeout: 128000,
	headers: { Authorization: "Bearer YmVhbnRyYWNrSUc76ghvfghn653REJ4QVRRd1I2VTgz"}
});

const __newOrderMailer = data => {
    return __B2BAPI.post(`/mailer/newOrder`, data);
};

const  __BEANAPI = axios.create({
	baseURL: `https://api.beantrack.app/api/v1`,
	timeout: 128000,
	headers: {
		'Content-Type': "application/json",
		'Authorization': "Bearer "+process.env.REACT_APP_BEANTRACK_KEY
	}
});

const  __BOCCASQL = axios.create({
	baseURL: `https://sql.bocca.app/api/v1`,
	timeout: 128000,
	headers: {
		'Content-Type': "application/json",
		'Authorization': "Bearer "+process.env.REACT_APP_BEANTRACK_KEY
	}
});

const __boccaAipUrl = "https://api.bocca.app/api/v1";

const __boccaApi = axios.create({
	baseURL: __boccaAipUrl,//https://api.bocca.app/api/v1
	timeout: 128000,
	headers: {
		'Content-Type': "application/json",
		'Authorization': "Bearer "+process.env.REACT_APP_BEANTRACK_KEY
	}
});

const __payApi = axios.create({
	baseURL: "https://api.mollie.com/v2",//https://api.bocca.app/api/v1
	timeout: 128000,
	headers: {
		'Content-Type': "application/json",
		'Authorization': "Bearer test_qaePVaWn7tS9nTyJURUr54PVuHE2VS"
	}
});

export {
	__B2BAPI,
	__B2BAPI_V3,
	__newOrderMailer,
	__BEANAPI,
	__BOCCASQL,
	__boccaAipUrl,
	__boccaApi,
	__payApi,
	__TRIBE,
	_bulk,
	_crm,
	_documents,
	_inventory,
	_logistics,
	_manufacturing,
	_sales,
	_salesInvoice,
	_salesOrder
}