import React from 'react';
import {
	Badge, Box, Button, Checkbox, Divider, Flex, FormControl, FormLabel, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spacer, Text, VStack, useColorModeValue as mode, Select
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import RepeatYearly from './Yearly/index';
import RepeatMonthly from './Monthly/index';
import RepeatWeekly from './Weekly/index';
import RepeatDaily from './Daily/index';
import RepeatHourly from './Hourly/index';
import translateLabel from '../../utils/translateLabel';

const Repeat = ({
	id,
	repeat: {
		frequency,
		yearly,
		monthly,
		weekly,
		daily,
		hourly,
		options,
	},
	handleChange,
	translations
}) => {
	const isOptionAvailable = option => !options.frequency || options.frequency.indexOf(option) !== -1;
	const isOptionSelected = option => frequency === option;

	return (
		<Box py={3}>
			<FormControl>
				<FormLabel
					htmlFor={`${id}-frequency`}
				>
					<strong>
						{translateLabel(translations, 'repeat.label')}
					</strong>
				</FormLabel>
				<Select
					name="repeat.frequency"
					id={`${id}-frequency`}
					value={frequency}
					onChange={handleChange}
				>
					{/* {isOptionAvailable('Yearly') && <option value="Yearly">{translateLabel(translations, 'repeat.yearly.label')}</option>} */}
					{isOptionAvailable('Weekly') && <option value="Weekly">{translateLabel(translations, 'repeat.weekly.label')}</option>}
					{isOptionAvailable('Monthly') && <option value="Monthly">{translateLabel(translations, 'repeat.monthly.label')}</option>}
					{/* {isOptionAvailable('Daily') && <option value="Daily">{translateLabel(translations, 'repeat.daily.label')}</option>}
					{isOptionAvailable('Hourly') && <option value="Hourly">{translateLabel(translations, 'repeat.hourly.label')}</option>} */}
				</Select>
			</FormControl>

			{
				isOptionSelected('Yearly') &&
				<RepeatYearly
					id={`${id}-yearly`}
					yearly={yearly}
					handleChange={handleChange}
					translations={translations}
				/>
			}
			{
				isOptionSelected('Monthly') &&
				<RepeatMonthly
					id={`${id}-monthly`}
					monthly={monthly}
					handleChange={handleChange}
					translations={translations}
				/>
			}
			{
				isOptionSelected('Weekly') &&
				<RepeatWeekly
					id={`${id}-weekly`}
					weekly={weekly}
					handleChange={handleChange}
					translations={translations}
				/>
			}
			{
				isOptionSelected('Daily') &&
				<RepeatDaily
					id={`${id}-daily`}
					daily={daily}
					handleChange={handleChange}
					translations={translations}
				/>
			}
			{
				isOptionSelected('Hourly') &&
				<RepeatHourly
					id={`${id}-hourly`}
					hourly={hourly}
					handleChange={handleChange}
					translations={translations}
				/>
			}
		</Box>
	);
};

Repeat.propTypes = {
	id: PropTypes.string.isRequired,
	repeat: PropTypes.shape({
		frequency: PropTypes.oneOf(['Yearly', 'Monthly', 'Weekly', 'Daily', 'Hourly']).isRequired,
		yearly: PropTypes.object.isRequired,
		monthly: PropTypes.object.isRequired,
		weekly: PropTypes.object.isRequired,
		daily: PropTypes.object.isRequired,
		hourly: PropTypes.object.isRequired,
		options: PropTypes.shape({
			frequency: PropTypes.arrayOf(PropTypes.oneOf(['Yearly', 'Monthly', 'Weekly', 'Daily', 'Hourly'])),
			yearly: PropTypes.oneOf(['on', 'on the']),
			monthly: PropTypes.oneOf(['on', 'on the']),
		}).isRequired,
	}).isRequired,
	handleChange: PropTypes.func.isRequired,
	translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Repeat;
