import React, { useState, useEffect } from 'react';
import {
	Container, SimpleGrid, Box, Button, Center, Flex, HStack, IconButton, Link, Menu, chakra, ModalBody,
	FormControl, Checkbox, FormLabel, MenuList, Stack, Text, useColorMode, useColorModeValue as mode, useColorModeValue, VStack, Drawer ,DrawerOverlay, DrawerCloseButton, DrawerHeader, DrawerContent, Badge, Image, Input, Divider, Spacer, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalFooter, ModalHeader
} from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { __B2BAPI, __boccaApi } from "@bocca-app-repo/app-components/components/api";
import { SelectElement, _data } from "@bocca-app-repo/app-components";
import { useHistory } from 'react-router-dom';

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
const Page = (props) => {
	const t_listItem_bg = useColorModeValue("gray.100", "gray.700");
	const account = _data.useMainStore(state => state.account)
    const [getAccountsInApp, setAccountsInApp] = useState([]);
    const [getAddresses, setAddresses] = useState([]);
    const [getOpenUser, setOpenUser] = useState({});
    const [getSelAcc, setSelAcc] = useState("");
    const [getOpenUserUpdate, setOpenUserUpdate] = useState({});
    const [getDeleteUser, setDeleteUser] = useState({});
    const [getIsNew, setIsNew] = useState(false);
    const [getLoading, setLoading] = useState(false);
    const [getPageLoading, setPageLoading] = useState(true);
    const history = useHistory();

	const [initUserItems, user, initAddresses, initAccount] = _data.useMainStore(state => [state.initUserItems, state.user, state.initAddresses, state.initAccount]);

	const initFn = async () => {
		console.log(user);
		await initUserItems(getSelAcc);
		await initAddresses(getSelAcc);
		await initAccount(getSelAcc);
	};

    const loadUsers = async () => {
		const gotAccounts = await __B2BAPI.get('/appInit/accountsInApp');
		console.log(gotAccounts.data);
			setAccountsInApp(gotAccounts.data)
    };

    useEffect(() => {
        loadUsers();
    }, []);

    return (
        <>
            <Modal size={"sm"} onClose={()=>{}} isOpen={true} isCentered>
				<ModalOverlay
					bg='blackAlpha.300'
					backdropFilter='blur(10px)'
				/>
				<ModalContent>
					<ModalHeader>
                        <HStack>
                            <Text>Switch van account</Text>
                            <Spacer />
                        </HStack>
                    </ModalHeader>
					<ModalBody p={4} h={"100%"} m={0}
						bg="gray.50"
						_dark={{ bg: "gray.800" }}
                    >
						<Box borderRadius={"lg"} scale w="100%">
                                            <HStack>
                                                <Box w="100%">
                                                    <Text fontSize={"md"} fontWeight={600}>Account</Text>
                                                    <SelectElement
														menuPlacement="auto"
														options={
															getAccountsInApp.map(z=>{
																return {
																	...z,
																	value: z.ID,
																	label: z.Code.trim()+" • "+z.Name
																}
															})
														}
														onChange={e=>{setSelAcc(e.ID)}}
													/>
                                                </Box>
                                            </HStack>
						</Box>
					</ModalBody>
					<Divider />
					<ModalFooter>
						<Button onClick={(e)=>{}} disabled={getLoading}>Terug</Button>
						<Spacer/>
						<Button onClick={(e)=>{initFn()}} colorScheme={"green"} disabled={getLoading}>Opslaan</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
        </>
    );
};
export default Page