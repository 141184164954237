import React, { useState } from 'react';
import {
	Box,
	Flex,
	Avatar,
	HStack,
	Link,
	IconButton,
	Button,
	Text,
	MenuButton,
	MenuList,
	MenuItem,
	MenuDivider,
	useDisclosure,
	useColorModeValue,
	useColorMode,
	Stack, Divider, CheckboxGroup, Checkbox, chakra,
	Center, Icon, InputGroup, InputLeftElement, Input, VStack, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, FormLabel, InputLeftAddon, Select, DrawerFooter, TagLabel, Tag, TagCloseButton
} from '@chakra-ui/react';

//import { NavLink } from './NavLink'
import { HamburgerIcon, CloseIcon, AddIcon, MoonIcon, ChevronRightIcon, SearchIcon } from '@chakra-ui/icons';
import { FaFilter } from "react-icons/fa"
import { find, findIndex } from 'lodash'
import { NavLink as RNavLink, useHistory } from 'react-router-dom';

/* const NavLink = ({ children, to }) => (
	<Link
		as={RNavLink}
		px={2}
		py={1}
		rounded={'md'}
		_hover={{
			textDecoration: 'none',
			bg: useColorModeValue('gray.200', 'gray.700'),
		}}
		to={to}>
		{children}
	</Link>
); */

export default function WithAction(props) {
	const { isOpen, onOpen, onClose, getSearch, setSearch, getEenheid, setEenheid, getCatSelected, setCatSelected } = props.data;
	const { colorMode, toggleColorMode } = useColorMode();

	const FilterButton = (props) => {
		return (
			<Button {...props}>
				<HStack>
					<Icon as={FaFilter} color={useColorModeValue('gray.700', 'gray.200')}/>
					<chakra.span
						pos="absolute"
						top="10px"
						right="10px"
						p="4px"
						fontSize="xs"
						fontWeight="bold"
						lineHeight="none"
						color="red.100"
						transform="translate(50%,-50%)"
						bg="red.600"
						rounded="full"
					/>
					<Text>Filters</Text>
				</HStack>
			</Button>
		)
	}

	return (
		<Flex className="sub-header" as="header" position="fixed" w="100%">
			<Box bg={useColorModeValue('gray.300', 'gray.750')} w="100%" px={{sm: "2vw", md: "5vw", lg: "7vw"}}>
				<Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
					<HStack spacing={4} alignItems={'center'}>
						<Text fontSize={{base: "md", md: "lg"}} fontWeight={600}>Bestel geschiedenis</Text>
					</HStack>
					<Flex alignItems={'center'}>
						<Stack direction={'row'} spacing={7}>
							{/* <Button onClick={e=>{}} disabled={loading}>{loading&&<Spinner me={4} />} <Logo /> <Text ms={2}>{`()`} Naar Cropster</Text></Button> */}
							{/* <HStack spacing={3} alignItems="center">
								<InputGroup
									//display={{ sm: 'none', md: 'block' }}
									ml="auto"
									backgroundColor={useColorModeValue('gray.200', 'gray.600')}
									borderRadius={"md"}
								>
									<InputLeftElement pointerEvents="none" >
										<SearchIcon />
									</InputLeftElement>
									<Input type="text" placeholder="Zoeken..." _placeholder={{color: useColorModeValue('gray.600', 'gray.400')}} value={getSearch} onChange={e=>{setSearch(e.target.value);}} maxW={{ base: '150px', md: '250px' }}/>
								</InputGroup>
							</HStack> */}
							{/* <Menu>
								<MenuButton
									as={Button}
									rounded={'full'}
									variant={'link'}
									cursor={'pointer'}
									minW={0}>
									<Avatar
										size={'sm'}
										src={'https://avatars.dicebear.com/api/male/username.svg'}
									/>
								</MenuButton>
								<MenuList alignItems={'center'}>
									<br />
									<Center>
										<Avatar
											size={'2xl'}
											src={'https://avatars.dicebear.com/api/male/username.svg'}
										/>
									</Center>
									<br />
								</MenuList>
							</Menu> */}
						</Stack>
					</Flex>
				</Flex>

				{/* {isOpen ? (
					<Box pb={4} display={{ md: 'none' }}>
						<Stack as={'nav'} spacing={4}>
							{Links.map(({name, href}) => (
								<NavLink key={name} to={href}>{name}</NavLink>
							))}
						</Stack>
					</Box>
				) : null} */}
			</Box>
		</Flex>
	);
}