import { Box, Heading, Text, useColorModeValue as mode } from '@chakra-ui/react'
import React from 'react'
import { BlogMedia } from './BlogMedia'
import { BlogMeta } from './BlogMeta'
import { useHistory } from "react-router-dom";

export const BlogCard = (props) => {
	const history = useHistory();
	const { data, ...rest } = props
	console.log(data);
	const { type, href = '#', title, tags, subTitle, coverImg } = data
	return (
		<Box {...rest} _hover={{shadow: "lg"}} p={4} borderRadius={"md"} onClick={()=>history.push(`/blog/${data.id}`)}>
			<BlogMedia src={"https://apiv2.beantrack.app/images/"+coverImg} alt={title} />
			<Box mt="6">
				{/* <BlogMeta tags={tags} type={type}/> */}
				<Box mb="6">
					<Box as="a" href={href}>
						<Heading size="md" mt="6" mb="4">
							{title}
						</Heading>
					</Box>
					<Text color={mode('gray.800', 'gray.100')} lineHeight="tall">
						{subTitle}
					</Text>
				</Box>
				{/* {author && <BlogAuthor name={author.name} image={author.image} role={author.title} />} */}
			</Box>
		</Box>
	)
}
