import React, { useState, useEffect } from 'react';
import {
	Container, SimpleGrid, Box, Button, Center, Flex, HStack, IconButton, Link, Menu, chakra, ModalBody,
	MenuButton, MenuDivider, MenuItem, MenuList, Stack, Text, useColorMode as mode, useToast, useDisclosure, VStack, FormLabel ,FormControl, ModalHeader, DrawerHeader, DrawerContent, Badge, Image, Input, Divider, Spacer, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalFooter
} from '@chakra-ui/react';
import { SelectBox } from 'devextreme-react/select-box';
import { _countries } from "helpers/addressExtras";
import { _data } from "@bocca-app-repo/app-components";
import { v4 as uuidv4 } from 'uuid';
import { __B2BAPI_V3, _crm } from "@bocca-app-repo/app-components/components/api"

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function InitialFocus() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [getLoading, setLoading] = useState(false);
	const toast = useToast();
	const account = _data.useMainStore(state => state.account)
	const initAddresses = _data.useMainStore(state => state.initAddresses)

	const initialRef = React.useRef(null)
	const finalRef = React.useRef(null)

	const onSave = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
			const data = {
				ID: uuidv4(),
				Account: account.ID,
				AddressLine1: e.target[0].value,
				AddressLine2: "",
				City:  e.target[1].value,
				Country: e.target[3].value,
				CountryName: e.target[4].value,
				Postcode: e.target[2].value,
				Type: 4,
			}
			const nMap = [{
				"updateOne" : {
					"filter": {"ID": data.ID},
					"update": data,
					"upsert": true
				}
			}];
			await _crm.__Addresses('post', '', data);
			await __B2BAPI_V3.post('/d/b2bAddresses', nMap);
			await initAddresses()
			await timeout(500);
			toast({
				title: 'Adres toegevoegd.',
				description: "",
				status: 'success',
				duration: 3000,
				isClosable: true,
			});
			onClose()
			setLoading(false);
        } catch (error) {
            setLoading(false);
			toast({
				title: 'Er is helaas iets misgegaan.',
				description: "",
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
            console.log(error);
        }
        setLoading(false);
    };

	return (
	  <>
		<Button borderRadius="md" size={"xs"} onClick={onOpen}>Nieuwe Adres toev.</Button>

		<Modal
		  initialFocusRef={initialRef}
		  finalFocusRef={finalRef}
		  isOpen={isOpen}
		  onClose={onClose}
		  isCentered
		>
		  <ModalOverlay
				bg='blackAlpha.300'
				backdropFilter='blur(10px)' />
		  <ModalContent borderRadius={"lg"}>
			<ModalHeader bg={"gray.200"} _dark={{bg: "green.900"}}>Nieuwe bezorgadres</ModalHeader>
			<ModalCloseButton />
			<form style={{margin: 0, padding: 0}} onSubmit={onSave}>
			<ModalBody py={6} bg={"white"} _dark={{bg: "gray.800"}}>
				<VStack spacing={4}>
					<FormControl>
						<FormLabel fontWeight={600}>Straat en nummer <small>*</small></FormLabel>
						<Input ref={initialRef} placeholder='straat 45' required/>
					</FormControl>
					<FormControl>
						<FormLabel fontWeight={600}>Stad <small>*</small></FormLabel>
						<Input placeholder='Amster' required />
					</FormControl>
					<FormControl>
						<FormLabel fontWeight={600}>Postcode <small>*</small></FormLabel>
						<Input placeholder='1234 AA' required />
					</FormControl>
					<FormControl mt={4}>
						<FormLabel fontWeight={600}>Land <small>*</small></FormLabel>
						<SelectBox id="custom-templates2" name='shipping-country'
							width="100%"
							/* value={inEditCheck("Country").trim()||""} */
							defaultValue={"NL"}
							dataSource={_countries}
							/* onValueChanged={e=>{editField({target:{value:e.value||""}}, "Country")}} */
							searchEnabled
							searchExpr="Name"
							searchTimeout={200}
							minSearchLength={2}
							searchMode="contains"
							displayExpr="Name"
							valueExpr="Code"
							showDataBeforeSearch
						/>
					</FormControl>
				</VStack>
			</ModalBody>

			<ModalFooter bg={"gray.200"} _dark={{bg: "green.900"}}>
			  <Button colorScheme='whiteAlpha' mr={3} onClick={onClose}>Annuleren</Button>
			  	<Button
					isLoading={getLoading}
					loadingText='Opslaan...'
					spinnerPlacement='start'
					colorScheme='green' type='submit'
				>
					Opslaan
				</Button>
			</ModalFooter>
			</form>
		  </ModalContent>
		</Modal>
	  </>
	)
  }

export default InitialFocus