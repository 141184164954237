import {
	Box, Flex, useColorModeValue
} from '@chakra-ui/react';

export default function WithAction(props) {
	const { Headerr } = props.data;

	return (
		<Flex className="sub-header" as="header" position="fixed" w="100%">
			<Box bg={useColorModeValue('gray.300', 'gray.750')} w="100%" px={{sm: "2vw", md: "5vw", lg: "7vw"}}>
				<Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
					<Headerr />
				</Flex>
			</Box>
		</Flex>
	);
}