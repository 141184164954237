import React, { useState, useEffect } from 'react';
import { CloseIcon, HamburgerIcon, MoonIcon, StarIcon } from '@chakra-ui/icons';
import {
	Avatar, Box, Button, Center, Flex, HStack, chakra, Link, Menu, Spacer, Image, IconButton,
	MenuButton, MenuDivider, MenuItem, MenuList, Stack, Text, useColorMode, useColorModeValue as mode, useDisclosure, VStack, useNumberInput, Input, Badge
} from '@chakra-ui/react';
import { useCart } from "react-use-cart";
import { FaTrashAlt } from "react-icons/fa";
import moment from 'moment';

const Page = (props) => {
	const { order, openOrder } = props.data
	const [getData, setData] = useState({});
	const [loading, setLoading] = useState(false);


    const {
		isEmpty,
		totalUniqueItems,
        totalItems,
		items,
        metadata,
        emptyCart,
		addItem,
		inCart,
		getItem,
		updateItemQuantity,
		removeItem,
        updateCartMetadata
	} = useCart();

	/* useEffect(() => {
		setData({...order, quantity: 0, id:order.Item, price: order.NewPrice.toFixed(2)})
		return () => {};
	}, [order]); */
	const statusRet = (e) => {
		switch (e) {
			case 0:
				return <Badge px="5px" py="1px" rounded={"sm"} bg='orange.300' color={"white"}>Verwerken</Badge>;
			case 1:
				return <Badge px="5px" py="1px" rounded={"sm"} bg='blue.300' color={"white"}>Ingepland</Badge>;
			case 2:
				return <Badge px="5px" py="1px" rounded={"sm"} bg='green.300' color={"white"}>Verzonden</Badge>;
			case 400:
				return <Badge px="5px" py="1px" rounded={"sm"} bg='red.300' color={"white"}>Geannuleerd</Badge>;
			default:
				break;
		}
	};

	return (
		<>
			{/* <Flex
				bg="#edf3f8"
				_dark={{
					bg: "#3e3e3e",
				}}
				p={50}
				w="full"
				alignItems="center"
				justifyContent="center"
			> */}
				<Flex
					w="100%"
					bg="white"
					_dark={{
						bg: "gray.700",
					}}
					shadow="md"
					_hover={{shadow:"lg"}}
					rounded="lg"
					overflow="hidden"
					p={4}
					mb={4}
					cursor={"pointer"}
					onClick={e=>{console.log(e);openOrder(order)}}
				>
					<Center
						mx="auto"
						my="auto"
						w={"90px"}
					>
						{moment(order.orderDate).format("D MMM YY")}
					</Center>
					<Spacer/>
					<Center
						mx="auto"
						my="auto"
						px="20px"
					>
						{statusRet(order.status)}
					</Center>
					{/* <Center
						mx="auto"
						my="auto"
						w={"90px"}
					>
						{moment(order.orderDate).format("D MMM YY")}
					</Center> */}
				</Flex>
		</>
	);
};
export default Page

