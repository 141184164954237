import {
	ChakraProvider, useColorModeValue, Link, Box, Image, Icon, Button, useColorMode
} from '@chakra-ui/react';
import theme from "assets/theme";
import { useIsAuthenticated, useAuthUser } from 'react-auth-kit';
import React, { useEffect,useState } from "react";
import { Toaster } from 'react-hot-toast';
import { debounce } from 'lodash';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { _auth, _data, _templates as MainLayout } from "@bocca-app-repo/app-components";
import Home from "./pages/Home";
import Shop from "./pages/Shop";
import AutoOrder from "./pages/AutoOrder";
import Workshops from "./pages/Workshops";
import WorkshopsOpen from "./pages/Workshops/Open";
import OrderHistory from "./pages/OrderHistory";
import Checkout from "./pages/Cart/Checkout";
import MyAccount from "./pages/MyAccount";
import MyAccountUsers from "./pages/MyAccount/components/Users";
import MyAccountUserPassword from "./pages/MyAccount/components/ChangePassword";
import MyAccountUserAddress from "./pages/MyAccount/components/Addresses";
import BlogPost from "./pages/BlogPost";
import SwitchAcc from "./SwitchAcc";
import "assets/css/css.css"
import Cart from "./pages/Cart";
// layouts Format


//import MainLayout from "./templates/main";
import themes from "devextreme/ui/themes";
import { FiChevronsUp } from "react-icons/fi";

const Links = [
	{name: 'Home', href: "/"},
	{name: `Shop`, href: "/shop"},
	{name: `Herhaal bestelling`, href: "/herhaal-bestelling"},
	{name: `Workshops`, href: "/workshops"},
	{name: `Bestel geschiedenis `, href: "/bestel-geschiedenis"}
];

function AuthRoute({ component: Component, children, ...rest }) {
	const [initAppSettings, getUser, initAddresses, initAccount] = _data.useMainStore(state => [state.initAppSettings, state.getUser, state.initAddresses, state.initAccount]);
	const isAuthenticated = useIsAuthenticated();
	let tablecolor = useColorModeValue("generic.light", "generic.dark");

	const initFn = async (user) => {
		console.log(user);
		await getUser(user.uuid);
		await initAppSettings();
		await initAddresses(user.from);
		await initAccount(user.from);
		console.log("hghghghghgh");
	};

	themes.current(tablecolor);

	if (!isAuthenticated()) {
		return <Redirect to="/login" />;
	};

	return (
		<Route
      		{...rest}
      		render={ props => {
				if (!isAuthenticated()) {
					return (
						<Redirect
							to={{ pathname: "/login", state: { from: props.location } }}
						/>
					)
				}

				return (
					<MainLayout initFn={initFn} Cart={Cart} Links={Links}>
						<Component {...props}  />
					</MainLayout>
				)
			}}
    	/>
	);
};

function topFunction() {
	window.scrollTo({top: 0, behavior: 'smooth'});
};

function App() {
	const [scrollPosition, setScrollPosition] = useState(0);
	const icb = useColorMode("green.50", "green.300")
	const icbi = useColorMode("gray.600", "gray.300")

	const handleScroll = () => {
	    const position = window.pageYOffset;
	    setScrollPosition(position);
	};

	useEffect(() => {
	    window.addEventListener('scroll', debounce(handleScroll, 150), { passive: true });

	    return () => {
	        window.removeEventListener('scroll', debounce(handleScroll, 150));
	    };
	}, []);
	/* const history = useHistory();

	const handleLogin = () => {
		history.push('/');
	};

	const handleLogout = () => {
		history.push('/login');
	}; */

	return (
		<ChakraProvider theme={theme}>
			<Toaster
				position="top-right"
				reverseOrder={false}
				gutter={8}
				containerClassName=""
				containerStyle={{}}
				toastOptions={{
					// Define default options
					className: '',
					success: {
						style: {
							color: "white",
							background: useColorModeValue('#2F855A', '#22543D'),
						},
					},
					error: {
						duration: 3000,
						style: {
							color: "white",
							background: useColorModeValue('#C53030', '#822727'),
						},
					},
					warning: {
					style: {
						color: "white",
						background: useColorModeValue('#B7791F', '#975A16'),
					},
					},
					info: {
					style: {
						color: "white",
						background: useColorModeValue('#2b6cb0', '#2c5282'),
					},
					},
					duration: 3000
				}}
			/>
			<Router>
				<Switch>
					<Route path="/login" exact component={_auth.Login} />
					<Route path="/forget-password" exact component={_auth.ForgetPassword} />

					<AuthRoute path="/" exact component={Home} />
					<AuthRoute path="/blog/:postId" exact component={BlogPost} />
					<AuthRoute path="/shop" exact component={Shop} />
					<AuthRoute path="/herhaal-bestelling" exact component={AutoOrder} />
					<AuthRoute path="/workshops" exact component={Workshops} />
					<AuthRoute path="/workshops/:workshopId" exact component={WorkshopsOpen} />
					<AuthRoute path="/bestel-geschiedenis" exact component={OrderHistory} />
					<AuthRoute path="/checkout" component={Checkout} />
					<AuthRoute path="/checkout/status/:orderNumber" component={Checkout} />
					<AuthRoute path="/mijn-account" exact component={MyAccount} />
					<AuthRoute path="/mijn-account/gebruikers" exact component={MyAccountUsers} />
					<AuthRoute path="/mijn-account/wachtwoord-wijzigen" exact component={MyAccountUserPassword} />
					<AuthRoute path="/mijn-account/afleveradressen" exact component={MyAccountUserAddress} />
					<AuthRoute path="/fnbbdfyjr546456efdfg657" exact component={SwitchAcc} />
				</Switch>
				{scrollPosition > 500 && (
				<Box position='fixed'
					bottom='20px'
					right={['16px', '84px']}
					zIndex={1}
					onClick={topFunction}
				>
					<Button bg={"#80988879"} >
						<Icon as={FiChevronsUp} h={6} w={6} color="white" _dark={{color:"gray.200"}} />
					</Button>

					{/* <Image src='images/icons/top.svg'
						w='60px'
						h='60px'
					/> */}
				</Box>)}
			</Router>
		</ChakraProvider>
	);
};

export default App