/* eslint-disable react-hooks/exhaustive-deps */
import { Badge, Box, Button, chakra, Divider, Flex, FormControl, FormLabel, HStack, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spacer, Text, useColorModeValue as mode, useDisclosure, VStack } from '@chakra-ui/react';

import { _data } from "@bocca-app-repo/app-components";
import { __B2BAPI } from "@bocca-app-repo/app-components/components/api";
import useWindowDimensions from "helpers/screensize";
import HTMLDecoderEncoder from 'html-encoder-decoder';
import parse from "html-react-parser";
import { find, has, isEmpty, round, sumBy } from 'lodash';
import moment from "moment";
import React, { useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

const calcNewPrice = (baseprice, discount) => {
	if (discount === 0) return baseprice;
	return round(baseprice-(baseprice*(discount/100)), 2)
};

export default function ProductPopup() {
	const history = useHistory()
	const { workshopId } = useParams();
	const user = _data.useMainStore(state => state.user);
	const account = _data.useMainStore(state => state.account);
	const [getTrainingsDates, setTrainingsDates] = useState([]);
	const [getSteps, setSteps] = useState(0);
	const { height } = useWindowDimensions();
	const hovercolor = mode("blackAlpha.300", "whiteAlpha.100");
	const [getWorkshop, setWorkshop] = useState({});
	const [getSelectedDate, setSelectedDate] = useState({});
	const [getDeelnemers, setDeelnemers] = useState([]);

	const workshopList = async () => {
		if (!workshopId) return;
		let prod = find(account.userWorkshops, {"Item": workshopId});
		console.log(prod);
		const epost = await __B2BAPI.get(`/d/b2bCustomerDatasV2?customerId=${account.ID}&fields=userItemsTrainingen`);
		const b2bTrainings = await __B2BAPI.get(`/d/b2bTrainings?trainingCode=${prod.ItemCode}&sort=date&startDate>${moment().toISOString(true)}&limit=20`)
		if(has(epost.data[0],"userItemsTrainingen")) {
			const IItenm = find(epost.data[0].userItemsTrainingen, {"Item": prod.Item})
			if (IItenm) {
				prod = {...prod, ...{...IItenm, AfspraakKorting:round(IItenm.AfspraakKorting*100, 2), AfspraakAantalDone:IItenm.AfspraakAantal-IItenm.AfspraakAantalKlaar}}
			}
			console.log(epost.data[0]);
		}
		setWorkshop(prod);
		setTrainingsDates(b2bTrainings.data.map(o=>{
			return {...o, dateformated: moment(o.startDate).format("D MMMM YYYY")+` | ${o.startTime??""} - ${o.endTime??""}`, beschikbaar: (o.soldPlaces&&o.standardPlaces-o.soldPlaces)||o.standardPlaces}
		}))
	};

	React.useEffect(() => {
		workshopList()
	}, []);

	const cartList = React.useMemo(() => {

		return getDeelnemers
	}, [getDeelnemers]);

	if (isEmpty(getWorkshop)) return (<></>);

	const HeadImg = () => {
		return (<Image
			mx="auto"
			h={"150px"}
			w="full"
			fit="cover"
			objectPosition="center"
			src={`https://apiv2.beantrack.app/images/${getWorkshop.cover||"workshop-ph.jpg"}`}
			alt="avatar"
			/* _hover={{
			transform: 'scale(1.05)',
			}} */
		/>)
	}

	const HeadBar = () => {
		return (<Flex alignItems="center" px={{sm: 0, md: "5vw", lg: "7vw"}} py={3} bg="green.800" h={"72px"}>
			<chakra.h1 mx={3} color="white" fontWeight="bold" fontSize="lg">
				{getWorkshop.productName}
			</chakra.h1>
		</Flex>)
	}

	const bookTrainingRet = () => {
		const sol = [];
		if ((getWorkshop.AfspraakAantal&&getWorkshop.AfspraakAantalKlaar<getWorkshop.AfspraakAantal)) {
			if ((getWorkshop.AfspraakAantal-getWorkshop.AfspraakAantalKlaar)<getDeelnemers.length) {
				sol.push({
					"ID" : uuidv4(),
					"Item" : getWorkshop.Item,
					"NetPrice" : calcNewPrice(getWorkshop.NewPrice, getWorkshop.AfspraakKorting),
					"Quantity" : getWorkshop.AfspraakAantal-getWorkshop.AfspraakAantalKlaar
				})
				sol.push({
					"ID" : uuidv4(),
					"Item" : getWorkshop.Item,
					"NetPrice" : getWorkshop.NewPrice,
					"Quantity" : getDeelnemers.length-(getWorkshop.AfspraakAantal-getWorkshop.AfspraakAantalKlaar)
				})
			} else {
				sol.push({
					"ID" : uuidv4(),
					"Item" : getWorkshop.Item,
					"NetPrice" : calcNewPrice(getWorkshop.NewPrice, getWorkshop.AfspraakKorting),
					"Quantity" : getDeelnemers.length
				})
			}
		} else {
			sol.push({
				"ID" : uuidv4(),
				"Item" : getWorkshop.Item,
				"NetPrice" : getWorkshop.NewPrice,
				"Quantity" : getDeelnemers.length
			})
		}
		console.log(sol);
		return sol
	}

	const bookTraining = async () => {
		const invoiceId = uuidv4();
		const sol = [];
		let qwantDeduct = 0;
		if ((getWorkshop.AfspraakAantal&&getWorkshop.AfspraakAantalKlaar<getWorkshop.AfspraakAantal)) {
			if ((getWorkshop.AfspraakAantal-getWorkshop.AfspraakAantalKlaar)<getDeelnemers.length) {
				sol.push({
					"ID" : uuidv4(),
					"Item" : getWorkshop.Item,
					"NetPrice" : calcNewPrice(getWorkshop.NewPrice, getWorkshop.AfspraakKorting),
					"Quantity" : getWorkshop.AfspraakAantal-getWorkshop.AfspraakAantalKlaar
				})
				sol.push({
					"ID" : uuidv4(),
					"Item" : getWorkshop.Item,
					"NetPrice" : getWorkshop.NewPrice,
					"Quantity" : getDeelnemers.length-(getWorkshop.AfspraakAantal-getWorkshop.AfspraakAantalKlaar)
				})
				qwantDeduct = getWorkshop.AfspraakAantal-getWorkshop.AfspraakAantalKlaar;
			} else {
				sol.push({
					"ID" : uuidv4(),
					"Item" : getWorkshop.Item,
					"NetPrice" : calcNewPrice(getWorkshop.NewPrice, getWorkshop.AfspraakKorting),
					"Quantity" : getDeelnemers.length
				})
				qwantDeduct = getDeelnemers.length;
			}
		} else {
			sol.push({
				"ID" : uuidv4(),
				"Item" : getWorkshop.Item,
				"NetPrice" : getWorkshop.NewPrice,
				"Quantity" : getDeelnemers.length
			})
		}
		const holh = {
            "name": account.Name?account.Name.trim():"",
            "contactName": user.name?user.name.trim():account.Name.trim(),
            "contactEmail": user.email?user.email:"",
            "contactPhone": user.superAdmin?"":(user.phone?user.phone.trim():""),
            "status": 420,
            "OrderID": invoiceId,
            "user": user.uuid,
            "orderDate": moment().toISOString(),
            "orderDate_F": moment().format("D MMMM YYYY"),
			"trainingName": getWorkshop.ItemDescription,
			"trainingDateId": getSelectedDate.aid,
			"deelnemers": getDeelnemers,
			"selectedDate": getSelectedDate,
            "mailerLines": [{
				"ItemDescription": getWorkshop.ItemDescription,
				"Quantity": getDeelnemers.length
            }],
            "order": {
                "OrderedBy": account.ID,
                "OrderID": invoiceId,
                "Description": "b2bAppTraining",
                "InvoiceTo": account.ID,
                "Remarks": "",
                "YourRef": "",
				"WarehouseID": "8c2ac062-6574-4427-9467-1ca49745cd91",
                "SelectionCode" : "3254cc82-0758-44ce-a107-e970b227d943",
                "SalesOrderLines": sol
            }
        }
		console.log(holh);
		const nMap = [{
            "insertOne" : {
                "document": holh
            }
        }];
        console.log(nMap);

		/* await __B2BAPI.post(`/sender`, {
			"from": {
				"email": "hello@bocca.app"
			},
			"to": [{
					"email": user.email
			}],
			"bcc": [{
					"email": "workshop@bocca.nl"
			}],

			"variables": [{
				"email": user.email,
				"substitutions": [
					{
						"var": "name",
						"value": account.Name.trim()
					}, {
						"var": "orderDate",
						"value": moment().format("DD MMMM YYYY")
					}, {
						"var": "plannedDate",
						"value": getSelectedDates.dateformated
					}
				]
			}],
			"personalization": [{
				"email": user.email,
				"data": {
					"users": getDeelnemers.map(x=>{
						return {
							"name": x[0],
							"email": x[1],
							"number": x[2]
						}
					}),
					"products": [
						{
							"name": getWorkshop.ItemDescription,
							"amount": getDeelnemers.length
						}
					]
				}
			}],
			"template_id": "pxkjn41mjn94z781"
		});
		await __B2BAPI.post('/d/b2bBookedTrainings', nMap);
		const n2Map2 = [{
			"updateOne" : {
				"filter": {"aid": getSelectedDate.aid},
				"update" : {$inc:{ soldPlaces: getDeelnemers.length}}
			}
		}];
		await __B2BAPI.post(`/d/b2bTrainings`, n2Map2);
		if (qwantDeduct>0) {
			const n2Map1 = [{
				"updateOne" : {
					"filter": {customerId: account.ID, "userItemsTrainingen.Item": getWorkshop.Item},
					"update": { $inc: { "userItemsTrainingen.$.AfspraakAantalKlaar": Number("-"+qwantDeduct) } },
				}
			}];
			await __B2BAPI.post(`/d/b2bCustomerDatasV2`, n2Map1)
		} */

		//closers();
		//toast.success("We hebben " + getDeelnemers.length + " X " +getWorkshop.ItemDescription+' voor je geboekt.', toastOptions_L);

	};

	return (
		<>
			{getSteps!==2&&<HeadImg />}
			<HeadBar />
			<Box w="100%" mx="auto" px={{sm: "2vw", md: "5vw", lg: "7vw"}} pb={3} bg={"white"} _dark={{ bg: "gray.800" }}>
				{getSteps===0&&<>
					<Box h={(height-(398))+"px"}>
						<Box px={4} py={2} h={{base: "auto"}}>
							<chakra.div
								mt={1}
								fontSize="sm"
								color="gray.600"
								_dark={{ color: "gray.200" }}
							>
								{((getWorkshop.AfspraakAantal&&getWorkshop.AfspraakAantalKlaar<getWorkshop.AfspraakAantal)&&<Badge rounded={"md"} mb={2} color={"gray.700"} _dark={{color: "gray.200"}} py={1} px={2}>
									<>{getWorkshop.AfspraakAantal-getWorkshop.AfspraakAantalKlaar} X Beschikbaar tegen {getWorkshop.AfspraakKorting}% korting</>
								</Badge>)||""}
							</chakra.div>
							<chakra.div
								mt={6}
								fontSize="sm"
								color="gray.600"
								_dark={{ color: "gray.200" }}
							>
								{getWorkshop.productDescription&&parse(HTMLDecoderEncoder.decode(getWorkshop.productDescription))}
							</chakra.div>
						</Box>

						<Box pt={4} px={6}>
							{getWorkshop.ExtraInfo&&<chakra.h1
								fontSize="xl"
								fontWeight="bold"
								color="gray.800"
								_dark={{ color: "white" }}
							>
								{getWorkshop.ExtraInfo}
							</chakra.h1>}

							{getWorkshop.productInfo&&<chakra.p py={2} color="gray.700" _dark={{ color: "gray.400" }}>
								{getWorkshop.productInfo}
							</chakra.p>}
						</Box>
					</Box>

					<HStack pt={4} pb={2} px={4}>
						<Spacer/>
						<Text fontWeight={600} fontSize={"lg"}>€ {getWorkshop.NewPrice}</Text>
					</HStack>
				</>}

				{getSteps===1&&<>
					<Box h={(height-(348))+"px"} overflowY={"auto"} py={4}>
						<SimpleGrid columns={{base: 1, md: 2, lg: 2}} spacing={4}>
							{getTrainingsDates.map((e, i) => <Box key={e.dateformated+i} shadow={"md"} borderRadius={"lg"} py={3} bg={"gray.50"} _dark={{ bg: "gray.600", _hover: {bg:hovercolor} }} _hover={{ bg: hovercolor }} cursor={"pointer"} onClick={()=>{setSteps(2); setSelectedDate(e); console.log(e);}}>
								<VStack spacing={1}>
									<Text fontSize={"sm"} fontWeight={600}>{e.dateformated}</Text>
									<Text fontSize={"sm"} >Plaatsen beschikbaar: {e.beschikbaar}</Text>
								</VStack>
							</Box>)}
						</SimpleGrid>
					</Box>
				</>}

				{getSteps===2&&<>
					<Box h={(height-(198))+"px"} overflowY={"auto"} py={4}>
						<SimpleGrid columns={{base: 1, md: 2, lg: 2}} spacing={4} h="100%">
							<Box w={"100%"} mb={2}>
								<VStack spacing={1} align={"left"} bg={"white"} _dark={{ bg: "whiteAlpha.200" }} rounded={"lg"} p={4}>
									<Text fontSize={"lg"} fontWeight={800} mb={4}>Gekozen datum</Text>
									<Text fontSize={"md"} fontWeight={600} ps={4}>{getSelectedDate.dateformated}</Text>
									<Text fontSize={"md"} ps={4}>Plaatsen beschikbaar: {getSelectedDate.beschikbaar}</Text>
									<Divider pt={4} />
									<Box display={{base: "block", md: "none"}}>
										<Flex>
											<Box py='4' my={"auto"}>
												Deelnemers: {getDeelnemers.length}
											</Box>
											<Spacer />
											<Box py='4'>
												<InitialFocus data={{setDeelnemers, dis: getDeelnemers.length===getSelectedDate.beschikbaar}} />
											</Box>
										</Flex>
										<Divider mb={4}/>
										{cartList.map((e,i)=><Box key={i} bg={"blackAlpha.200"} _dark={{ bg: "whiteAlpha.200" }} rounded={"md"} w={"100%"} p={4} mb={2}>
											<Flex>
												<Box my={"auto"}>
													{e[0]}
													{(e[1]||e[2])&&<br />}{e[1]&&<><b>T:</b> {e[1]}</>} {e[2]&&<><b>@:</b> {e[2]}</>}
												</Box>
												<Spacer />
												<Box my={"auto"}>
													<Button onClick={x=>{
														setDeelnemers(zz=>{let z = [...zz]; z.splice(i, 1); console.log(z); return z})
													}} color={"red.600"}><i className='fad fa-times'></i></Button>
												</Box>
											</Flex>
										</Box>)}
									</Box>
									{bookTrainingRet().map((ee, ii)=>
										<Flex key={"ket"+ii} ps={4}>
											<Box py='4' my={"auto"}>
												{ee.Quantity} Deelnemer{ee.Quantity>1&&"s"}
											</Box>
											<Spacer />
											<Box py='4' my={"auto"}>
												€ {round(ee.Quantity * ee.NetPrice, 2)}
											</Box>
										</Flex>
									)}
									<Divider pt={4} />
										<Flex ps={4}>
											<Spacer />
											<Box py='4' my={"auto"}>
												Totaal: € {round(sumBy(bookTrainingRet(), (ee)=>ee.Quantity * ee.NetPrice))}
											</Box>
										</Flex>
								</VStack>
							</Box>
							<Box w={"100%"} py={2} px={4} bg={"blackAlpha.200"} _dark={{ bg: "whiteAlpha.200" }} rounded={"lg"} display={{base: "none", md: "block"}}>
								<Flex>
									<Box py='4' my={"auto"}>
										Deelnemers: {getDeelnemers.length}
									</Box>
									<Spacer />
									<Box py='4'>
										<InitialFocus data={{setDeelnemers, dis: getDeelnemers.length===getSelectedDate.beschikbaar}} />
									</Box>
								</Flex>
								<Divider mb={4}/>
								{cartList.map((e,i)=><Box key={i} bg={"blackAlpha.200"} _dark={{ bg: "whiteAlpha.200" }} rounded={"md"} w={"100%"} p={4} mb={2}>
									<Flex>
										<Box my={"auto"}>
											{e[0]}
											{(e[1]||e[2])&&<br />}{e[1]&&<><b>T:</b> {e[1]}</>} {e[2]&&<><b>@:</b> {e[2]}</>}
										</Box>
										<Spacer />
										<Box my={"auto"}>
											<Button onClick={x=>{
												setDeelnemers(zz=>{let z = [...zz]; z.splice(i, 1); console.log(z); return z})
											}} color={"red.600"}><i className='fad fa-times'></i></Button>
										</Box>
									</Flex>
								</Box>)}
							</Box>
						</SimpleGrid>
					</Box>
				</>}
				{/* <Box py={4} px={6}>
					<HookUsage />
				</Box> */}

				<Divider />
				<HStack w={"100%"} mt={3}>
					{getSteps===0&&<>
						<Button onClick={()=>history.goBack()}>Terug</Button>
						<Spacer/>
						<Button bg={"green.600"} color={"white"} _dark={{bg: "green.600", color: "white"}} onClick={()=>setSteps(1)} disabled={getTrainingsDates.length<1}>Datums bekijken</Button>
					</>}
					{getSteps===1&&<>
						<Button onClick={()=>setSteps(0)}>Terug</Button>
						<Spacer/>
					</>}
					{getSteps===2&&<>
						<Button onClick={()=>{setSteps(1); setSelectedDate({}); setDeelnemers([])}}>Terug</Button>
						<Spacer/>
						<Button bg={"green.600"} color={"white"} _dark={{bg: "green.600", color: "white"}} disabled={getDeelnemers.length===0} onClick={()=>bookTraining()}>Reserveren</Button>
					</>}
				</HStack>
			</Box>
		</>
	)

	function InitialFocus({data}) {
		const { setDeelnemers, dis } = data;
		const { isOpen, onOpen, onClose } = useDisclosure()

		return (
		  <>
			<Button bg={"green.600"} color={"white"} _dark={{ bg: "whiteAlpha.200" }} onClick={onOpen} disabled={dis}>Toevoegen</Button>

			<Modal
				isOpen={isOpen}
				onClose={onClose}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Deelnemers toevoegen</ModalHeader>
					<ModalCloseButton />
					<form style={{padding:0,margin:0}} onSubmit={e=>{
						e.preventDefault();
						setDeelnemers(x=>[...x, [
							e.target[0].value,
							e.target[1].value,
							e.target[2].value
						]])
						console.log([
							e.target[0].value,
							e.target[1].value,
							e.target[2].value
						]);
					}}>
					<ModalBody pb={6}>
						<FormControl>
							<FormLabel>Naam <small>*</small></FormLabel>
							<Input required placeholder='Naam...' />
						</FormControl>

						<FormControl mt={4}>
							<FormLabel>Telefoon</FormLabel>
							<Input type={"tel"} placeholder='06123...' />
						</FormControl>

						<FormControl mt={4}>
							<FormLabel>Email</FormLabel>
							<Input type={"email"} placeholder='e@mail...' />
						</FormControl>
					</ModalBody>

					<ModalFooter>
						<Button onClick={onClose} mr={3}>Annuleren</Button>
						<Button colorScheme='green' type="submit">
							Toevoegen
						</Button>
					</ModalFooter>
					</form>
				</ModalContent>
			</Modal>
		  </>
		)
	}
}