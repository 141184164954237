import { HStack, Icon, Button, useColorModeValue as mode, Text } from '@chakra-ui/react'
import React, {useEffect} from 'react'

export const NavLink = (props) => {
  const { icon, isActive, label, ...rest } = props

  return (
    <Button
      id={label+'btn'}
      variant="gost"
      py={2}
      px={3}
      borderRadius="full"
      transition="all 0.3s"
      fontWeight="medium"
      lineHeight="1.5rem"
      aria-current={isActive ? 'page' : undefined}
      color={mode('white', 'whiteAlpha.800')}
      _hover={{
        bg: mode('gray.50', 'gray.600'),
        color: mode('black', 'white'),
      }}
      _activeLink={{
        bg: mode('green.600', 'green.400'),
        color: mode('white', 'black'),
        fontWeight: 600
      }}
      {...rest}
    >
        {label}
    </Button>
  )
}
