import axios from 'axios';
//const ENDPOINT = "//127.0.0.1:5500/";//https://api.beantrack.app/

export default axios.create({
	baseURL: `https://api.beantrack.app/api/v1`,
	timeout: 60000,
	headers: {
		'Accept': 'application/json',
		'Content-Type': "application/json",
		'Authorization': "Bearer "+process.env.REACT_APP_BEANTRACK_KEY
	}
});