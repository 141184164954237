import BEANAPI from './beanApi';
import { curdiv } from "./config";

/* 
	Use this endpoint to create, read, update and delete materials in a bill of material version linked to a make item.
	You must use previously created make Item, Bill of material version before creating any BillOfMaterialMaterials.
	https://start.exactonline.nl/docs/HlpRestAPIResourcesDetails.aspx?name=CRMAccounts
*/
export const __Accounts = (type, uri, data) => {
	const baseUri = `/exact/${type}/${curdiv}/CRM/Accounts`;
	switch (type) {
		case 'get':
			return BEANAPI.get(baseUri + uri);
		case 'post':
			return BEANAPI.post(baseUri + uri, data);
		case 'put':
			return BEANAPI.put(baseUri + uri, data);
		case 'delete':
			return BEANAPI.delete(baseUri + uri);
		default:
			break;
	}
};

export const __Addresses = (type, uri, data) => {
	const baseUri = `/exact/${type}/${curdiv}/CRM/Addresses`;
	switch (type) {
		case 'get':
			return BEANAPI.get(baseUri + uri);
		case 'post':
			return BEANAPI.post(baseUri + uri, data);
		case 'put':
			return BEANAPI.put(baseUri + uri, data);
		case 'delete':
			return BEANAPI.delete(baseUri + uri);
		default:
			break;
	}
};

export const __Contacts = (type, uri, data) => {
	const baseUri = `/exact/${type}/${curdiv}/CRM/Contacts`;
	switch (type) {
		case 'get':
			return BEANAPI.get(baseUri + uri);
		case 'post':
			return BEANAPI.post(baseUri + uri, data);
		case 'put':
			return BEANAPI.put(baseUri + uri, data);
		case 'delete':
			return BEANAPI.delete(baseUri + uri);
		default:
			break;
	}
};

export const __AccountClassifications = (type, uri) => {
	const baseUri = `/exact/${type}/${curdiv}/CRM/AccountClassifications`;
	switch (type) {
		case 'get':
			return BEANAPI.get(baseUri + uri);
		default:
			break;
	}
};

export const __AccountClassificationNames = (type, uri) => {
	const baseUri = `/exact/${type}/${curdiv}/CRM/AccountClassificationNames`;
	switch (type) {
		case 'get':
			return BEANAPI.get(baseUri + uri);
		default:
			break;
	}
};