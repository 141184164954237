import React from 'react';
import {
	Badge, Box, Button, Checkbox, Divider, Flex, FormControl, FormLabel, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spacer, Text, VStack, useColorModeValue as mode, Select, InputGroup, InputLeftElement, InputRightElement
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import numericalFieldHandler from '../../utils/numericalFieldHandler';
import translateLabel from '../../utils/translateLabel';

const EndAfter = ({
	id,
	after,
	handleChange,
	translations
}) => (
	<Box pt={4}>
		<InputGroup>
				<Input
					id={id}
					name="end.after"
					aria-label="End after"
					value={after}
					onChange={numericalFieldHandler(handleChange)}
				/>
			<InputRightElement w={"4.5rem"}>
				<Text>{translateLabel(translations, 'end.executions')}</Text>
			</InputRightElement>
		</InputGroup>
	</Box>
);

EndAfter.propTypes = {
	id: PropTypes.string.isRequired,
	after: PropTypes.number.isRequired,
	handleChange: PropTypes.func.isRequired,
	translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default EndAfter;
