import React, { useState, useEffect } from 'react';
import {
	AlertIcon, Alert, Box, Button, Center, Flex, HStack, IconButton, Link, Menu, chakra, ModalBody,
	FormControl, Checkbox, FormLabel, MenuList, Stack, Text, useColorMode, useColorModeValue as mode, useColorModeValue, VStack, Drawer ,DrawerOverlay, DrawerCloseButton, DrawerHeader, DrawerContent, Badge, Image, Input, Divider, Spacer, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalFooter, ModalHeader
} from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { SelectBox } from 'devextreme-react/select-box';
import { __B2BAPI, __boccaApi } from "@bocca-app-repo/app-components/components/api";
import { _data } from "@bocca-app-repo/app-components";
import { useHistory } from 'react-router-dom';
import { useSignOut, useAuthUser } from 'react-auth-kit';

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
const Page = (props) => {
	const signOut = useSignOut()
	const t_listItem_bg = useColorModeValue("gray.100", "gray.700");
	const user = _data.useMainStore(state => state.user)
    const [getErrors, setErrors] = useState("");
    const [getDone, setDone] = useState([]);
    const [getAddresses, setAddresses] = useState([]);
    const [getOpenUser, setOpenUser] = useState({});
    const [getOpenUserCheck, setOpenUserCheck] = useState({});
    const [getOpenUserUpdate, setOpenUserUpdate] = useState({});
    const [getDeleteUser, setDeleteUser] = useState({});
    const [getIsNew, setIsNew] = useState(false);
    const [getLoading, setLoading] = useState(false);
    const [getPageLoading, setPageLoading] = useState(true);
    const history = useHistory();

    const changePassSave = async (e) => {
        setLoading(true);
		e.preventDefault();
		if (!_.isEqual(e.target[0].value.trim(), e.target[1].value.trim())) {setErrors("Wachtwoorden komen niet overeen"); setLoading(false); return}
		if (_.isEqual(e.target[0].value.trim(), e.target[1].value.trim())) {setErrors("");}
        setErrors("")
		await __boccaApi.post('/auth/resetPass', {email: user.email, password: e.target[0].value.trim()})
		/* toast.info('Wijzigingen zijn opgeslagen.', toastOptions); */
        setLoading(false)
        signOut();
		history.push("/");
    };

    return (
        <>
            <Modal size={"full"} onClose={()=>{}} isOpen={true} isCentered>
				<ModalOverlay
					bg='blackAlpha.300'
					backdropFilter='blur(10px)'
				/>
				<ModalContent h={"100%"}>
					<ModalHeader>
                        <HStack>
                            <Text>Wachtwoord wijzigen</Text>
                            <Spacer />
                            <Button onClick={()=>history.goBack()} color={"red.600"}><i className="fad fa-times"></i></Button>
                        </HStack>
                    </ModalHeader>
					<ModalBody p={4} h={"100%"} overflowY={"auto"} m={0}
						bg="gray.50"
						_dark={{ bg: "gray.800" }}
                    >
                        <Center>
                            <Box maxW={"2xl"} w={"100%"}>
                                {getErrors ? <Alert status='error' rounded={"lg"} mb={2}>
                                    <AlertIcon />
                                    {getErrors}
                                </Alert> : null}
                                <form onSubmit={changePassSave} id={"subber"}>
                                    <VStack spacing={4}>
                                        <FormControl>
                                            <FormLabel fontWeight={600}>Wachtwoord</FormLabel>
                                            <Input type={"password"} autoComplete="new-password" aria-autocomplete="none" minLength="8" required />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel fontWeight={600}>Wachtwoord herhalen</FormLabel>
                                            <Input type={"password"} autoComplete="new-password" aria-autocomplete="none" minLength="8" required />
                                        </FormControl>
                                    </VStack>
                                </form>
                            </Box>
                        </Center>
					</ModalBody>
					<Divider />
					<ModalFooter>
						{_.isEmpty(getOpenUser)&&<><Button onClick={()=>history.goBack()}>Terug</Button>
						<Spacer/>
                        <Button colorScheme={"green"} type="submit" form='subber'>Opslaan</Button>
						{/* <Button disabled={getLoading} colorScheme={"red"} onClick={onDelete}>Verwijderen</Button> */}</>}
					</ModalFooter>
				</ModalContent>
			</Modal>
        </>
    );
};
export default Page