import {
	Button,
	FormControl,
	Flex,
	Heading,
	Input,
	Stack,
	Text,
	useColorModeValue, useToast, VStack
  } from '@chakra-ui/react';
  import { useState } from "react";
  import { __boccaApi } from "../../api";
  import { v4 as uuidv4 } from 'uuid';

  export default function ForgotPasswordForm() {
	const [getLoading, setLoading] = useState(false);
	const toast = useToast();

	const onSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		const username = e.target[0].value;
		const email = username.trim().toLowerCase();
		try {
			const emaila = await __boccaApi.get('/auth/email-availability?email='+email)
			if (emaila.data.emailAvailable === false) {
				const str = `${uuidv4()}==`+email;
				const enc = window.btoa(str);
				const str2 = window.location.origin+`/reset-wachtwoord?resetLink=`+enc;
				await __boccaApi.post('/auth/resetLink', {email:email, link:str2, data: [{
					"updateOne" : {
						"filter": {"email": email},
						"update" : {resetCode: enc}
					}
				}]})
				setLoading(false);
				toast({
					title: 'Gelukt.',
					description: "Er is een e-mail naar je verzonden met een link waarmee je je wachtwoord kunt resetten.",
					status: 'success',
					duration: 5000,
					isClosable: true,
				});
			} else {
				setLoading(false);
				toast({
					title: 'Je email is niet gevonden.',
					description: "",
					status: 'error',
					duration: 5000,
					isClosable: true,
				});
			}
		} catch (error) {
			setLoading(false);
			toast({
				title: 'Je email is niet gevonden.',
				description: "",
				status: 'error',
				duration: 5000,
				isClosable: true,
			});
		}
	};

	return (
	  	<Flex
			minH={'100vh'}
			align={'center'}
			justify={'center'}
			bg={useColorModeValue('gray.50', 'gray.800')}
		>
			<form onSubmit={onSubmit}>

					<VStack spacing={0} mb={8}>
						<Text fontSize={"x-large"} fontWeight={"extrabold"}>BOCCA</Text>
						<Text fontSize={"large"} fontWeight={"medium"}>Workshops - Admin</Text>
					</VStack>
				<Stack
					spacing={4}
					w={'full'}
					maxW={'md'}
					bg={useColorModeValue('white', 'gray.700')}
					rounded={'xl'}
					boxShadow={'lg'}
					p={6}
					my={12}
				>
					<Heading lineHeight={1.1} fontSize={{ base: '1xl', md: '2xl' }} mb={6}>
						Wachtwoord vergeten?
					</Heading>
					<Text
						fontSize={{ base: 'sm', sm: 'md' }}
						color={useColorModeValue('gray.800', 'gray.400')}>
						Je ontvangt een reset link.
					</Text>
					<FormControl id="email">
						<Input
							placeholder="jou-email@mail.com"
							_placeholder={{ color: 'gray.500' }}
							type="email"
							required
						/>
					</FormControl>
					<Stack spacing={6}>
						<Button
						bg={'blue.400'}
						color={'white'}
						_hover={{
							bg: 'blue.500',
						}} type={"submit"}>
						Reset wachtwoord
						</Button>
					</Stack>
				</Stack>
			</form>
	  </Flex>
	);
  }