import { Box, Heading, SimpleGrid, Text, Divider, Flex, useColorModeValue as mode,HStack, Badge, Img,
	Icon,
	Image,
	Link,
	Skeleton,
	Stack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { _data } from "@bocca-app-repo/app-components";
import { useHistory, useParams } from "react-router-dom";
import { __B2BAPI } from "@bocca-app-repo/app-components/components/api"
import { isEmpty, reverse } from 'lodash';
import moment from 'moment';
import HTMLDecoderEncoder from 'html-encoder-decoder';
import parse from "html-react-parser";

const Home = () => {
	const { postId } = useParams();
	const [getPost, setPost] = useState({});
	const [getAutoOrder, setAutoOrder] = useState({});
	const account = _data.useMainStore(state => state.account);
	const history = useHistory()

	const onPageBeforeIn = async () => {
		/* showNotification() */
		const ez = await __B2BAPI.get(`/d/b2bPosts?id=${postId}`)
		setPost(ez.data[0])
	};
	useEffect(() => {
		onPageBeforeIn();
	}, []);
	return (
		<Box bg={mode('gray.50', 'inherit')} as="section" px={{sm: "0vw", md: "5vw", lg: "7vw"}} mt={{ base: '0', lg: 4 }}>
			<Stack direction={{ base: 'column-reverse', lg: 'row' }} spacing={{ base: '0', lg: '20' }}>
				<Box
					width={{ lg: 'sm' }}
					transform={{ base: 'translateY(-50%)', lg: 'translateY(25%)' }}
					bg={{ base: mode('red.50', 'gray.700'), lg: 'transparent' }}
					mx={{ base: '6', md: '8', lg: '0' }}
					px={{ base: '6', md: '8', lg: '0' }}
					py={{ base: '6', md: '8', lg: '12' }}
				>
					<Stack spacing={{ base: '8', lg: '10' }}>
						<Stack spacing={{ base: '2', lg: '4' }}>
							<Heading size="lg" color={mode('red.500', 'red.300')}>
								{getPost.title}
							</Heading>
							<Heading size="md" fontWeight="normal">
								{getPost.subTitle}
							</Heading>
						</Stack>
					</Stack>
				</Box>
				<Flex flex="1" overflow="hidden">
					<Image
						src={`https://apiv2.beantrack.app/images/${getPost.coverImg}`}
						alt={getPost.title}
						fallback={<Skeleton />}
						maxH="450px"
						minH={{base:"45vh", md:"350px"}}
						minW="300px"
						objectFit="cover"
						flex="1"
						rounded={{ base: 'none', lg: "md" }}
					/>
				</Flex>
			</Stack>
			<Box px={{base: 8}}>
				{getPost.content&&parse(HTMLDecoderEncoder.decode(getPost.content))}
			</Box>
		</Box>
  	)
}
export default Home;