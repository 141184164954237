import { v4 as uuidv4 } from 'uuid';
import _ from "lodash";
//NO OrderID
//NO IDs
const porto = {
    vatLow: {
        "Item": "c7485114-4dcb-4a91-8b21-377ea3fc667d",
        "Quantity": 1,
        "ItemDescription": "Portokosten Collo 9%",
        "ItemCode": "KNPK9%",
        "shipping": true
    },
    vatHigh: {
        "Item": "138066a4-7ab5-41cf-9210-716d8b08b46a",
        "Quantity": 1,
        "ItemDescription": "Portokosten Collo 21%",
        "ItemCode": "KNPK21%",
        "shipping": true
    },
    free: {
        "Item": "739c25fd-f3de-4bc0-bf86-cbfed16f0a00",
        "Quantity": 1,
        "NetPrice": 0,
        "ItemDescription": "Portokosten Collo 100% korting",
        "ItemCode": "KNPK-100%%",
        "shipping": true
    }
};

export const shippingCheck = (items) => {
    const total = _.sumBy(items, function(o) { return o.NetPrice*o.Quantity; });
    if (total === 0) return 0;
    if (total >= 215) return 0;
    if (total < 215) return 6;
};

export const calculateCart = (itemss, isPickup) => {
    const items = itemss.map(e=>{return {...e, SalesVatCode: e.SalesVatCode?e.SalesVatCode.trim():e.SalesVatCode}});
    const shippingconst = isPickup?0:shippingCheck(items);
    
    const total = _.sumBy(items, function (o) { return _.round(o.Quantity * o.NetPrice, 2); });
    const groupByVat = _.groupBy(items, "SalesVatCode");
    let totalForVatHigh, totalForVatLow = null;
    if (_.isEmpty(items)) return [];
    if (isPickup) return items;
    if (shippingconst===0) {
        return [...items, {
            ...porto.free
        }]
    }
    if (_.has(groupByVat, "4")) { 
        totalForVatLow = _.sumBy(groupByVat["4"], function (o) { return _.round(o.Quantity * o.NetPrice, 2); }); }
    if (_.has(groupByVat, "1")) { 
        totalForVatHigh = _.sumBy(groupByVat["1"], function (o) { return _.round(o.Quantity * o.NetPrice, 2); }); }
    if (totalForVatHigh && !totalForVatLow) {
        return [...items, {
            ...porto.vatHigh,
            "ID": uuidv4(),
            "NetPrice": shippingconst
        }]
    }
    if (totalForVatLow && !totalForVatHigh) {
        return [...items, {
            ...porto.vatLow,
            "NetPrice": shippingconst
        }]
    }
    const vatHighPart = totalForVatHigh / total;
    const vatLowPart = totalForVatLow / total;
    //const portoCostVatHigh = _.round(vatHighPart * shippingconst, 2);
    //const portoCostVatLow = _.round(vatLowPart * shippingconst, 2);
    return [...items, {
        ...porto.vatHigh,
        "Quantity": _.round(vatHighPart, 2),
        "NetPrice": shippingconst
    }, {
        ...porto.vatLow,
        "Quantity": _.round(vatLowPart, 2),
        "NetPrice": shippingconst
    }]
};