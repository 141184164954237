import { mode } from "@chakra-ui/theme-tools";

export const globalStyles = {

  "colors": {
    "blue": {
      "900": "#003138",
      "800": "#00414B",
      "700": "#00525D",
      "600": "#006270",
      "500": "#227783",
      "400": "#448C96",
      "300": "#66A1A9",
      "200": "#88B6BC",
      "100": "#AACBCF",
      "50": "#CCE0E2"
    },
    "red": {
      "900": "#40140C",
      "800": "#551A10",
      "700": "#6B2114",
      "600": "#802718",
      "500": "#914437",
      "400": "#A26156",
      "300": "#B37D74",
      "200": "#C49A93",
      "100": "#D5B7B2",
      "50": "#E6D4D1"
    },
    "yellow": {
      "900": "#6B5231",
      "800": "#8F6D41",
      "700": "#B28951",
      "600": "#d6a461",
      "500": "#DCB076",
      "400": "#E1BC8B",
      "300": "#E6C8A0",
      "200": "#ECD5B5",
      "100": "#F1E1CA",
      "50": "#F7EDDF"
    },
    "green": {
      "900": "#505953",
      "800": "#6B776F",
      "700": "#85948A",
      "600": "#a0b2a6",
      "500": "#ADBCB2",
      "400": "#B9C7BE",
      "300": "#C6D1CA",
      "200": "#D3DBD6",
      "100": "#DFE5E1",
      "50": "#ECF0ED"
    },
    "greenA": {
      "900": "#50595390",
      "800": "#6B776F80",
      "700": "#85948A70",
      "600": "#a0b2a660",
      "500": "#ADBCB250",
      "400": "#B9C7BE40",
      "300": "#C6D1CA30",
      "200": "#D3DBD620",
      "100": "#DFE5E110",
      "50": "#ECF0ED05"
    },
    "gray": {
      "900": "#2C2D2D",
      "800": "#373838",
      "750": "#424443",
      "700": "#4D4F4E",
      "600": "#585a59",
      "500": "#6E706F",
      "400": "#858685",
      "300": "#9B9C9B",
      "200": "#B1B2B2",
      "100": "#C7C8C8",
      "50": "#DEDEDE",
      "25": "#F5F5F5"
    },
    "grayA": {
      "900": "#2C2D2D90",
      "800": "#37383880",
      "750": "#42444375",
      "700": "#4D4F4E70",
      "600": "#585a5960",
      "500": "#6E706F50",
      "400": "#85868540",
      "300": "#9B9C9B30",
      "200": "#B1B2B220",
      "100": "#C7C8C810",
      "50": "#DEDEDE05"
    },
    "orange": {
      "50": "#FAF3EA",
      "100": "#F2DDC5",
      "200": "#EAC79F",
      "300": "#E1B17A",
      "400": "#D99B54",
      "500": "#D0852F",
      "600": "#A76B25",
      "700": "#7D501C",
      "800": "#533513",
      "900": "#2A1B09"
    },
    "teal": {
      "50": "#ECF9F7",
      "100": "#C9EDE8",
      "200": "#A7E2DA",
      "300": "#84D7CB",
      "400": "#62CBBC",
      "500": "#3FC0AE",
      "600": "#329A8B",
      "700": "#267368",
      "800": "#194D45",
      "900": "#0D2623"
    },
    "cyan": {
      "50": "#EAF7FA",
      "100": "#C5E9F2",
      "200": "#9FDBEA",
      "300": "#7ACDE1",
      "400": "#54BFD9",
      "500": "#2FB0D0",
      "600": "#258DA7",
      "700": "#1C6A7D",
      "800": "#134753",
      "900": "#09232A"
    },
    "purple": {
      "50": "#F1ECF8",
      "100": "#D6CAED",
      "200": "#BCA8E1",
      "300": "#A285D5",
      "400": "#8863CA",
      "500": "#6E41BE",
      "600": "#583498",
      "700": "#422772",
      "800": "#2C1A4C",
      "900": "#160D26"
    },
    "pink": {
      "50": "#F9ECF8",
      "100": "#EDCAED",
      "200": "#E1A8E1",
      "300": "#D585D5",
      "400": "#CA63C9",
      "500": "#BE41BD",
      "600": "#983497",
      "700": "#722771",
      "800": "#4C1A4C",
      "900": "#260D26"
    }
  },
  styles: {
    global: (props) => ({
      body: {
        bg: mode("gray.50", "gray.800")(props),
        fontFamily: 'Helvetica, sans-serif'
      },
      html: {
        fontFamily: 'Helvetica, sans-serif'
      }
    }),
  },
};
