import {
	Container, Avatar, Box, Button, Center, Flex, HStack, IconButton, Link, Menu, chakra, Icon,
	MenuButton, MenuDivider, MenuItem, MenuList, Stack, Text, useColorMode as mode, useColorModeValue, useDisclosure, VStack, Drawer ,DrawerOverlay, DrawerCloseButton, DrawerHeader, DrawerContent, DrawerBody, DrawerFooter, Input, Divider, Spacer,
	AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay, AlertDialogCloseButton
} from '@chakra-ui/react';

import Cart from './cart';
import Details from './details';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { __B2BAPI, __B2BAPI_V3, _crm } from "@bocca-app-repo/app-components/components/api"
import { find, sumBy, round } from 'lodash';
import { useCart } from "react-use-cart";
import { simpShippingCheck, calculateCart } from "../components/cartCalc";
import { _data, useConfirm } from "@bocca-app-repo/app-components";
import useWindowDimensions from "helpers/screensize";
import moment from "moment";
import { customAlphabet } from 'nanoid';
const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-';
const nanoid = customAlphabet(alphabet, 6);

//const mollieClient = createMollieClient({ apiKey: 'test_qaePVaWn7tS9nTyJURUr54PVuHE2VS' });

export default function App() {
	const history = useHistory();
	const { height } = useWindowDimensions();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [ getConfirmation, Confirmation ] = useConfirm();
	//const userItems = _data.useMainStore(state => state.userItems);
	//const userAddresses = _data.useMainStore(state => state.userAddresses);
	const account = _data.useMainStore(state => state.account);
	const user = _data.useMainStore(state => state.user);
    const [getpoNumber, setpoNumber] = useState("");
    const [getTntEmail, setTntEmail] = useState("");
    const [getSettings, setSettings] = useState({});
    const [getPageLoading, setPageLoading] = useState(true);
    const [isDone, setIsDone] = useState(false);
	const {
		isEmpty,
		cartTotal,
		totalUniqueItems,
        totalItems,
		items,
        metadata,
        emptyCart,
		addItem,
		inCart,
		getItem,
		updateItemQuantity,
		removeItem,
        updateCartMetadata
	} = useCart();

	const cartList = React.useMemo(() => {
		const holder = []
		const holderRemoved = []
		for (let index = 0; index < items.length; index++) {
			const element = items[index];
			const found = find(account.userItems, {Item: element.Item})
			const tott = round(element.quantity*Number(found.NewPrice), 2)
			if (found) holder.push({...element, ...found, itemTotal: tott, itemTotalV: tott.toFixed(2)})
			if (!found) holderRemoved.push(element)
		}
		const total = sumBy(items, e=>{
			const found = find(account.userItems, {Item: e.Item})
			if (found) return e.quantity*Number(found.NewPrice)//NewPrice
			if (!found) return e.quantity*Number(e.NewPrice)
		})

		return {
			items: holder,
			shipping: simpShippingCheck(round(total, 2)),
			total: round(total, 2).toFixed(2)
		}
	}, [items, account.userItems]);

	const adresList = React.useMemo(() => {
		const hoild = account.addresses;
		return hoild
	}, [account.addresses]);

	const loadAddresses = async () => {
		console.log(user);
        setPageLoading(true);
		try {
			const gotAddresses = await __B2BAPI_V3.get(`/d/b2bCustomerDatasV3?customerId=${account.ID}&fields=poNumber,tntEmail`);
			console.log(gotAddresses);
			setpoNumber(gotAddresses.data[0].poNumber?gotAddresses.data[0].poNumber:"")
			setTntEmail(gotAddresses.data[0].tntEmail?gotAddresses.data[0].tntEmail:"")
        	setPageLoading(false);
		} catch (error) {
        	setPageLoading(false);
		}
    };
	const updatePo = async (data) => {
		const status = await getConfirmation({titelText: "Referentie opslaan?", text: "Referentie opslaan als standaard voor alle bestelling"});

		if (status) { //status = true
			console.log(true);
			await __B2BAPI_V3.post('/d/b2bCustomerDatasV3', [{
				"updateOne" : {
					"filter": {"customerId": account.ID},
					"update" : {
						poNumber: data
					}
				}
			}])
			return status;
		} else { //false
			console.log(false);
			return status;
		}
	};
	const updateEmail = async (data) => {
		const status = await getConfirmation({titelText: "Email opslaan?", text: "Email opslaan als standaard voor alle bestelling"});

		if (status) { //status = true
			console.log(true);
			await __B2BAPI_V3.post('/d/b2bCustomerDatasV3', [{
				"updateOne" : {
					"filter": {"customerId": account.ID},
					"update" : {
						tntEmail: data
					}
				}
			}])
			return status;
		} else { //false
			console.log(false);
			return status;
		}
	};
	const setting = async (data) => {
		const settings = await __B2BAPI.get('/d/b2bSettings')
		setSettings(settings.data[0]);
	};
	useEffect(() => {
		setting()
        loadAddresses();
    }, []);

	const placeOrder = async (order) => {
		//console.log(order);
		//TODO check if id exists
		const orderputter = {...order, paymentKey: nanoid()};

		orderputter.order.SalesOrderLines = calculateCart(items, order.OrderID);

		console.log(orderputter);

		try {
            const insertOrder = [{
                "insertOne" : {
                    "document": orderputter
                }
            }];
            //await __B2BAPI.post(`/d/b2bOrders`, insertOrder);
            emptyCart();
			setIsDone(true)
        } catch (err) {
            console.error(err);
            //toast.error("Er is helaas iets misgegaan, probeer het opnieuw of neem contact met ons op als het nogmaals niet lukt.", toastOptions_E);
        }

		/* try {
			const payment = await payApi.post("/payments", {
				amount: {
				  value:    '100.00',
				  currency: 'EUR'
				},
				description: 'Kenmerk: '+orderputter.paymentKey,
				redirectUrl: 'https://mijn.bocca.app/checkout/afgerond/123456',
				webhookUrl:  'https://payapi.bocca.app/webhook/status-update'
			})
			console.log(payment);
			console.log(order);
		} catch (error) {

		} */
    };

	if (!user || getPageLoading) return <></>
	return (
		<Flex
			px={{sm: "0vw", md: "5vw", lg: "7vw"}} m={0}
			direction={{ base: 'column-reverse', md: 'row' }}
			height={{ base: 'auto', md: (height-(60))+"px" }}
		>
			{!isDone&&<><Box w={"100%"} m={0} p={0}>
				<Details adresList={adresList} user={user} getpoNumber={getpoNumber} updatePo={updatePo} getTntEmail={getTntEmail} updateEmail={updateEmail} account={account} placeOrder={placeOrder} getSettings={getSettings} />
			</Box>
			<Box w={"100%"} m={0} p={0} display={{ sm: 'none', md: 'block' }}>
				<Cart cartList={cartList} />
			</Box></>}
			{isDone&&<Box mx={"auto"} mt={"20vh"} maxW={"lg"}><VStack w={"100%"} p={4} spacing={12}>
				<Text mx={"auto"} textAlign={"center"} fontSize={"2xl"}>Hartelijk dank voor je bestelling,</Text>
				<Text mx={"auto"} textAlign={"center"}>{getSettings.orderConfirmToast}</Text>
				<Button w={{base: "full", md:"sm"}} onClick={()=>history.push("/")}>Home</Button>
			</VStack></Box>}
			<Confirmation />
		</Flex>
	);
}