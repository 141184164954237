import React, {useEffect, useState} from "react"
import {
	Flex, Text, Center, Progress, Stack, VStack
} from '@chakra-ui/react'
import { useSignOut, useAuthUser } from 'react-auth-kit'
import { ReactComponent as Logo } from './logosvg.svg'
import Navbar from "./Navbar"
import { __timeout } from "helpers/utils"

const Layout = ({children, initFn, Cart, Links}) => {
	const auth = useAuthUser();
	const user = auth();
	const [ getLoading, setLoading ] = useState(true);

	const init = async () => {
		await __timeout(500)
		await initFn(user);
		await __timeout(1000)
		setLoading(false)
	};

	useEffect(() => {
		init();
	}, [])
//
	if (getLoading) return (<>
		<Center bg='green.600' _dark={{ bg: "green.800"}} h='100vh' w='100%' color='white'>
			<VStack spacing={12} w={"40%"}>
				<Logo
					style={{
						height:'20vh',
						width: 'auto',
					}}
				></Logo>
				<Text fontWeight={600} fontSize={"lg"}>Laden...</Text>
				<Stack w={"100%"}>
					<Progress colorScheme={"green"} size='xs' isIndeterminate />
				</Stack>
			</VStack>
		</Center>
	</>)

	return (
		<>
			<div id="layout-wrapper">
				<Flex className="main-header" as="header" position="fixed" w="100%" >
					<Navbar Cart={Cart} Links={Links} />
				</Flex>{/*
				<Container as="main" mt="20">
					{props.children}
				</Container> */}
				<div className="main-content">
					{children}
				</div>
				{/* <Footer /> */}
			</div>
		</>
	);
}

export default Layout;