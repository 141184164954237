import React, { useEffect, useState } from 'react';
import {
    Box, Button, Divider, HStack, Spacer, Text, useRadio, useRadioGroup
} from '@chakra-ui/react';
import _, { find } from "lodash";
import moment from "moment";
import { RRule } from 'rrule';
import RRuleGen from "@bocca-app-repo/app-components/components/elements/RRuleGen";

const radios = [
    { name: 'Weekijks', value: 'weeks', repeater: 'week(en)' },
    { name: 'Maandelijks', value: 'months', repeater: 'maand(en)' },
];
const radios2 = [
    { name: 'Week', value: "1" },
    { name: '2 Weken', value: "2" },
    { name: '3 Weken', value: "3" }
];
const radios3 = [
    { name: 'Maand', value: "1" },
    { name: '2 Maanden', value: "2" },
    { name: '3 Maanden', value: "3" }
];

const dagenRadios = [
    { name: 'Ma', value: 'monday' },
    { name: 'Di', value: 'tuesday' },
    { name: 'Wo', value: 'wednesday' },
    { name: 'Do', value: 'thursday' },
    { name: 'Vr', value: 'friday' },
];

const getMonthDaySelARadios = [
    { value: '1st', name: '1st' },
    { value: '2nd', name: '2de' },
    { value: '3th', name: '3de' },
    { value: '4th', name: '4de' },
    { value: 'last', name: 'Laatste' },
];

const getMonthDaySelBRadios = [
    { value: 'monday', name: 'Ma' },
    { value: 'tuesday', name: 'Di' },
    { value: 'wednesday', name: 'Wo' },
    { value: 'thursday', name: 'Do' },
    { value: 'friday', name: 'Vr' },
];

const translateArray = [
    { value: '1st', name: '1st' },
    { value: '2nd', name: '2de' },
    { value: '3rh', name: '3de' },
    { value: '4th', name: '4de' },
    { value: 'last', name: 'laatste' },
    { value: 'Monday', name: 'Maandag' },
    { value: 'Tuesday', name: 'Dinsdag' },
    { value: 'Wednesday', name: 'Woensdag' },
    { value: 'Thursday', name: 'Donderdag' },
    { value: 'Friday', name: 'Vrijdag' },
    { value: 'month', name: 'maand' },
    { value: 'months', name: 'maanden' },
    { value: 'weeks', name: 'weken' },
    { value: 'the', name: 'de' },
    { value: 'on', name: 'op' },
    { value: 'every', name: 'elke' },
    { value: 'until', name: 'tot' },
    { value: 'time', name: 'keer' },
    { value: 'times', name: 'keer' },
    { value: 'for', name: 'voor' }
]
const translateText = (text) => {
	const array = text.split(" ")
	const newArray = []
	for (let index = 0; index < array.length; index++) {
		const element = array[index];
		const found = find(translateArray, {value: element})
		if (found) {newArray.push(found.name); continue}
		newArray.push(element)
	}
	return newArray.join(" ")
}
const Page = (props) => {
    const [editOpen, setEditOpen] = useState(false);
    const [getSrrule, setSrrule] = useState("");

    const changeUpdate = (newdata) => {
        const {radioValue, radioValue2, getAmountEvery, getMonthDaySelA, getMonthDaySelB} = props.plannerSettings;
		const plannerSettings_a = {radioValue, radioValue2, getAmountEvery, getMonthDaySelA, getMonthDaySelB};
        const plannerSettings = {...plannerSettings_a, ...newdata}
        const getdates = genDateString(plannerSettings).dates;
        const toPlan = {dateString: genDateString(plannerSettings).dateString, nextDate: Number(moment(getdates[0]).format('YYYYMMDD')), nextDateString: moment(getdates[0]).startOf("day").toISOString(true), plannerSettings}
        props.setOpenUserCheck(xx=>{const x = {...xx}; return {...x, ...toPlan} });
    }

    const genDateString = (e) => {
        let text2 = RRule.fromString(props.getOpenUserCheck.rrule+";COUNT=4")
        return {dates: text2.all()};
        /* const {radioValue, radioValue2, getAmountEvery, getMonthDaySelA, getMonthDaySelB} = e;

        let datestring = "";
        const _repeater = radioValue?.repeater && radioValue.value
        if (_repeater==='weeks') {
            datestring = `every ${getAmountEvery} ${_repeater} on ${radioValue2?.value?radioValue2.value:""}`
        }
        if (_repeater==='months') {
            datestring = `every ${getAmountEvery} ${_repeater} on the ${getMonthDaySelA} ${getMonthDaySelB}`
        }
        if (!datestring) return "";
        datestring = datestring + ' for 4 times';
        let text2 = RRule.fromText(datestring)
        return {dates: text2.all(), dateString: datestring}; */
    };

    return (
        <>
		{(!props.getOpenUserCheck.rrule||editOpen)&&<>
			<RRuleGen onChange={(rrule) =>{
				//props.setOpenUserCheck(xx=>{const x = {...xx}; return {...x, rrule } });
				console.log(`RRule changed, now it's ${rrule}`)
				setSrrule(rrule)
			}} /* value={props.getOpenUserCheck.rrule||""} */ />
			<HStack w={"100%"}>
				<Spacer/>
				{!(!props.getOpenUserCheck.rrule)&&<Button size={"sm"} me={2} onClick={()=>setEditOpen(false)}>Terug</Button>}
				<Button size={"sm"} disabled={!getSrrule} onClick={()=>{
					props.setOpenUserCheck(xx=>{
						let text2 = RRule.fromString(getSrrule+";COUNT=4")
						const ddd = text2.all()[0]
						const x = {...xx}; return {...x, rrule:getSrrule,
							nextDate: Number(moment(ddd).format('YYYYMMDD')),
							nextDateString: moment(ddd).startOf("day").toISOString(true) }
					});
					setEditOpen(false)
				}}>Opslaan</Button>
			</HStack>

		</>}
		{(props.getOpenUserCheck.rrule&&!editOpen)&&
			<><HStack w={"100%"}>
				<Text>{translateText(RRule.fromString(props.getOpenUserCheck.rrule).toText())}</Text>
			</HStack>
			<HStack w={"100%"}>
				<Spacer/>
				<Button size={"sm"} onClick={()=>setEditOpen(true)}>Aanpassen</Button>
			</HStack><Divider /><Box pb={3} w="100%">
                <Text fontWeight={600}>Kies je volgende verzenddatum</Text><Text mb={4}>{moment(props.getOpenUserCheck.nextDate, "YYYYMMDD").format("dddd DD MMM YY")}</Text>
                <HStack>
                    <NextDays data={{nextDate:props.getOpenUserCheck.nextDate, opts: genDateString(props.plannerSettings).dates, changeUpdate, setOpenUserCheck:props.setOpenUserCheck}}/>
                </HStack>
            </Box>
			{props.children}</>
		}
            {/* <Box p={2} w="100%" bg={"blackAlpha.50"} rounded="lg">
                <HStack>
                    <Text fontWeight={600}>Frequentie</Text>
                </HStack>
                <RadioFrequentie data={{frequentie:props.plannerSettings.radioValue?.value, radios, changeUpdate}}/>
            </Box>
            <Box p={2} w="100%" bg={"blackAlpha.50"} rounded="lg">
                <HStack>
                    <Text fontWeight={600}>Elke</Text>
                    {props.plannerSettings.radioValue?.repeater && props.plannerSettings.radioValue.value==="weeks" &&
                    <Radio2Frequentie data={{frequentie:props.plannerSettings.getAmountEvery||"1", radios2, changeUpdate}}/>}
                    {props.plannerSettings.radioValue?.repeater && props.plannerSettings.radioValue.value==="months" &&
                    <Radio2Frequentie data={{frequentie:props.plannerSettings.getAmountEvery||"1", radios2: radios3, changeUpdate}}/>}
                </HStack>
            </Box>
            {props.plannerSettings.radioValue?.repeater && props.plannerSettings.radioValue.value==="weeks" &&<Box p={2} w="100%" bg={"blackAlpha.50"} rounded="lg">
                <HStack>
                    <Text fontWeight={600}>Op</Text>
                    <RadioDays data={{day:props.plannerSettings.radioValue2?.value, dagenRadios, changeUpdate}}/>
                </HStack>
            </Box>}
            {props.plannerSettings.radioValue?.repeater && props.plannerSettings.radioValue.value==="months" && <><Box p={2} w="100%" bg={"blackAlpha.50"} rounded="lg">
                <HStack>
                    <Text fontWeight={600}>Op de</Text>
                    <RadiogetMonthDaySelA data={{day:props.plannerSettings.getMonthDaySelA, getMonthDaySelARadios, changeUpdate}}/>
                </HStack>
            </Box>
            <Box p={2} w="100%" bg={"blackAlpha.50"} rounded="lg">
                <HStack>
                    <RadiogetMonthDaySelB data={{day:props.plannerSettings.getMonthDaySelB, getMonthDaySelBRadios, changeUpdate}}/>
                </HStack>
            </Box></>} */}
            {/* <Divider />
            {!_.isEmpty(genDateString(props.plannerSettings)) && <>
            <Box pb={3} w="100%">
                <Text fontWeight={600}>Kies je volgende verzenddatum</Text><Text mb={4}>{moment(props.getOpenUserCheck.nextDate, "YYYYMMDD").format("dddd DD MMM YY")}</Text>
                <HStack>
                    <NextDays data={{nextDate:props.getOpenUserCheck.nextDate, opts: genDateString(props.plannerSettings).dates, changeUpdate, setOpenUserCheck:props.setOpenUserCheck}}/>
                </HStack>
            </Box></>} */}
        </>
    )
}

function RadioFrequentie({data}) {
    const {radios, frequentie, changeUpdate} = data
    const options = ['react', 'vue', 'svelte']

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'framework',
        defaultValue: frequentie,
        onChange: o=>changeUpdate({radioValue: find(radios, {value: o})}),
    })
    //o=>changeUpdate({radioValue: find(radios, {value: o})})
    const group = getRootProps()
    return (
        <HStack {...group}>
        {radios.map(({value, name}) => {
          const radio = getRadioProps({ value })
          return (
            <RadioCard key={value} {...radio}>
              {name}
            </RadioCard>
          )
        })}
        </HStack>
    )
}

function Radio2Frequentie({data}) {
    const {radios2, frequentie, changeUpdate} = data

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'framework8',
        defaultValue: frequentie+"",
        onChange: o=>changeUpdate({getAmountEvery: Number(o)}),
    })
    //changeUpdate({getAmountEvery: Number(o)}
    const group = getRootProps()
    return (
        <HStack {...group}>
        {radios2.map(({value, name}) => {
          const radio = getRadioProps({ value })
          return (
            <RadioCard key={value} {...radio}>
              {name}
            </RadioCard>
          )
        })}
        </HStack>
    )
}

function RadioDays({data}) {
    const {dagenRadios, day, changeUpdate} = data

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'framework2',
        defaultValue: day,
        onChange: o=>changeUpdate({radioValue2: find(dagenRadios, {value: o})}),
    })
    //changeUpdate({radioValue2: o}
    const group = getRootProps()
    return (
        <HStack {...group}>
        {dagenRadios.map(({value, name}) => {
          const radio = getRadioProps({ value })
          return (
            <RadioCard key={value} {...radio}>
              {name}
            </RadioCard>
          )
        })}
        </HStack>
    )
}

function RadiogetMonthDaySelA({data}) {
    const {getMonthDaySelARadios, day, changeUpdate} = data

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'framework3',
        defaultValue: day,
        onChange: o=>changeUpdate({getMonthDaySelA: o}),
    })
    //changeUpdate({getMonthDaySelA: o}
    const group = getRootProps()
    return (
        <HStack {...group}>
        {getMonthDaySelARadios.map(({value, name}) => {
          const radio = getRadioProps({ value })
          return (
            <RadioCard key={value} {...radio}>
              {name}
            </RadioCard>
          )
        })}
        </HStack>
    )
}

function RadiogetMonthDaySelB({data}) {
    const {getMonthDaySelBRadios, day, changeUpdate} = data

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'framework43',
        defaultValue: day,
        onChange: o=>changeUpdate({getMonthDaySelB: o}),
    })
    //changeUpdate({getMonthDaySelB: o}
    const group = getRootProps()
    return (
        <HStack {...group}>
        {getMonthDaySelBRadios.map(({value, name}) => {
          const radio = getRadioProps({ value })
          return (
            <RadioCard key={value} {...radio}>
              {name}
            </RadioCard>
          )
        })}
        </HStack>
    )
}

function NextDays({data}) {
    const {opts, nextDate, setOpenUserCheck} = data

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'framework266',
        value: nextDate+"",
        onChange: o=>{
            const toPlan = {
                nextDate: Number(moment(o).format('YYYYMMDD')),
                nextDateString: moment(o).startOf("day").toISOString(true)
            }
            console.log(toPlan);
            setOpenUserCheck(xx=>{const x = { ...xx }; return {...x, ...toPlan} })
        },
    })
    //changeUpdate({radioValue2: o}
    const group = getRootProps()
    return (
        <HStack {...group}>
        {opts.map((o) => {
          const radio = getRadioProps({ value:moment(o).format('YYYYMMDD') })
          return (
            <RadioCard key={moment(o).format('YYYYMMDD')} {...radio}>
              {moment(o).format("ddd DD MMM YY")}
            </RadioCard>
          )
        })}
        </HStack>
    )
}

function RadioCard(props) {
    const { getInputProps, getCheckboxProps } = useRadio(props)

    const input = getInputProps()
    const checkbox = getCheckboxProps()

    return (
      <Box as='label'>
        <input {...input} />
        <Box
          {...checkbox}
          cursor='pointer'
          borderWidth='1px'
          borderRadius='md'
          boxShadow='md'
          _checked={{
            bg: 'green.600',
            color: 'white',
            borderColor: 'green.600',
          }}
          _focus={{
            boxShadow: 'outline',
          }}
          px={1}
          py={0}
        >
          {props.children}
        </Box>
      </Box>
    )
}

export default Page;