import { SelectElement, _data } from "@bocca-app-repo/app-components";
import { __B2BAPI } from "@bocca-app-repo/app-components/components/api";
import { Badge, Box, Button, Checkbox, Divider, Flex, FormControl, FormLabel, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spacer, Text, VStack, useColorModeValue as mode, ButtonGroup } from '@chakra-ui/react';
import useWindowDimensions from "helpers/screensize";
import _, { filter } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useCart } from "react-use-cart";
import { v4 as uuidv4 } from 'uuid';
import { calculateCart } from "./cartCalc";
import Navbar from "./components/Navbar";
import PlanListItem from "./components/PlanListItem";
import OrderPlanner from "./planOrder";
import { RiAddFill, RiArrowRightUpLine } from 'react-icons/ri'


function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
// const uribase = 'https://apiv2.beantrack.app/images/';

const Page = (props) => {
	//const userItems = _data.useMainStore(state => state.userItems);
	const account = _data.useMainStore(state => state.account);
	const { userItems } = _data.useMainStore(state => state.account);
	const userAddresses = _data.useMainStore(state => state.userAddresses);
	const user = _data.useMainStore(state => state.user);
	const [getUsers, setUsers] = useState([]);
    const [getSaveChanges, setSaveChanges] = useState(false);
    const [getOpenUser, setOpenUser] = useState({});
    const [getOpenUserCheck, setOpenUserCheck] = useState({});
    const [getDeleteUser, setDeleteUser] = useState({});
    const [getAddItem, setAddItem] = useState(false);
    const [getIsNew, setIsNew] = useState(false);
    const [getStep, setStep] = useState(1);
    const [getLoading, setLoading] = useState(false);
    const [getPageLoading, setPageLoading] = useState(true);
	const { height } = useWindowDimensions();
	const c_opslaanBtn = mode('green.600', 'green.800');
	const c_sendToCartBtn = mode('blue.400', 'blue.600');

    const loadUsers = async () => {
        setPageLoading(true);
        const gotUsers = await __B2BAPI.get(`/d/b2bOrderLists?account=${account.ID}`)
		console.log(gotUsers);
        setUsers(gotUsers.data.map(x=>{
			const xx = {...x};
			let dat = xx;
			if (!("ID" in xx)) {
				dat = {
					ID: xx._id,
					account: account.ID,
					accountName: account.Name,
					user: user.uuid,
					address: xx.address||{},
					dateString: xx.dateString||"",
					deliveryAddress: xx.dateString||"",
					items: xx.items.map(z=>{
						return { Item: z.Item, ItemDescription: z.ItemDescription, NetPrice: z.NewPrice, Quantity: z.quantity, UnitCode: z.Unit, SalesVatCode: z.SalesVatCode, value: z.Item, label: z.ItemDescription+(z.ExtraInfo?" - "+z.ExtraInfo:"") }
					}),
					name: xx.name||"",
					nextDate: xx.nextDate||"",
					nextDateString: "",
					plannerSettings: ((!xx.plannerSettings||_.isEmpty(xx.plannerSettings))&&{
						radioValue: { name: 'Weekijks', value: 'weeks', repeater: 'week(en)' },
						radioValue2: { name: 'Ma', value: 'monday' },
						getAmountEvery: 1,
						getMonthDaySelA: "1st",
						getMonthDaySelB: "monday"
					})||xx.plannerSettings,
					status: false,
					old: true,
					_id: xx._id
				}
			}
			return dat
		}));
        //await timeout(500);
        setPageLoading(false);
    };

	const {
		metadata,
		items,
		updateCartMetadata,
		setItems
	} = useCart();

    const openUser = (e, data) => {
		console.log(data);
        e.preventDefault();
		setIsNew(false)
		if (_.isEmpty(data)) {setOpenUser({});setOpenUserCheck({}); return}
        setOpenUser(_.cloneDeep({...data,
			plannerSettings: ((!data.plannerSettings||_.isEmpty(data.plannerSettings))&&{
				radioValue: { name: 'Weekijks', value: 'weeks', repeater: 'week(en)' },
				radioValue2: { name: 'Ma', value: 'monday' },
				getAmountEvery: 1,
				getMonthDaySelA: "1st",
				getMonthDaySelB: "monday"
			})||data.plannerSettings})
		);
        setOpenUserCheck(_.cloneDeep({...data,
			plannerSettings: ((!data.plannerSettings||_.isEmpty(data.plannerSettings))&&{
				radioValue: { name: 'Weekijks', value: 'weeks', repeater: 'week(en)' },
				radioValue2: { name: 'Ma', value: 'monday' },
				getAmountEvery: 1,
				getMonthDaySelA: "1st",
				getMonthDaySelB: "monday"
			})||data.plannerSettings})
		);
    };

    const openUserNew = (e) => {
        e.preventDefault();
        const data = {
            account: account.ID,
            accountName: account.Name,
            user: user.uuid,
            status: true,
            dateString: "",
            name: "",
            items: [],
            ID: uuidv4(),
			plannerSettings: {
				radioValue: { name: 'Weekijks', value: 'weeks', repeater: 'week(en)' },
				radioValue2: { name: 'Ma', value: 'monday' },
				getAmountEvery: 1,
				getMonthDaySelA: "1st",
				getMonthDaySelB: "monday"
			},
			deliveryAddress: "",
			address: {}
        }
        setOpenUser(_.cloneDeep(data));
        setOpenUserCheck(_.cloneDeep(data));
        setIsNew(true)
    };

    const inEditCheck = (field) => {
        return field in getOpenUserCheck?getOpenUserCheck[field]:getOpenUser[field]
    };

    const editCheckField = (e, field) => {
        setOpenUserCheck(o=>{return {...o, [field]: e.target.checked}});
    };

	const onAddButton = (e, id) => {
        e.preventDefault();
		setOpenUserCheck(xx=>{
			const x = {...xx}
			const fI = _.findIndex(x.items, {Item: id});
			x.items[fI].Quantity = Number(x.items[fI].Quantity+1);
			return x;
		});
	};

	const onSubtractButton = (e, id) => {
        e.preventDefault();
		setOpenUserCheck(xx=>{
			const x = {...xx}
			const fI = _.findIndex(x.items, {Item: id});
			if (x.items[fI].Quantity === 1) return x;
			x.items[fI].Quantity = Number(x.items[fI].Quantity-1);
			return x;
		});
	};

	const removingItem = (e) => {
		setOpenUserCheck(zz=>{
			const z = {...zz};
			const fI = _.findIndex(z.items, {Item: e.Item});
			const tempArr = [...z.items];
			tempArr.splice(fI, 1);
			return {...z, items:tempArr}
		})
	};

	const submitAddItem = () => {
		console.log(getAddItem);
		const fI = _.findIndex(getOpenUserCheck.items, {Item: getAddItem.Item});
		if (fI<0) {
			const dhld = { ...getAddItem, Item: getAddItem.Item, ItemDescription: getAddItem.label, NetPrice: getAddItem.NewPrice, Quantity: getAddItem.quantity, UnitCode: getAddItem.Unit, SalesVatCode: getAddItem.SalesVatCode}
			setOpenUserCheck(zz=>{const z = {...zz};return {...z, items:[...z.items, dhld]}})
			setAddItem({});
			return;
		}
		setOpenUserCheck(zz=>{
			const z = {...zz};
			z.items[fI].Quantity = z.items[fI].Quantity+getAddItem.quantity
			return {
				...z,
				items:[...z.items]
			}
		})
		setAddItem({});
	};

    const onSave = async (e) => {
        e.preventDefault();
        setLoading(true);
		//console.log(getOpenUserCheck);
        try {
            if (getOpenUserCheck.old) {
				const holder = {...getOpenUserCheck}
				delete holder.old;
				delete holder._id;

                await __B2BAPI.post('/d/b2bOrderLists', [{
                    "updateOne" : {
                        "filter": {"_id": getOpenUserCheck._id},
                        "update" : holder
                    }
                }])
                await timeout(500);
                /* toast.success('Wijzigingen zijn opgeslagen.', toastOptions); */
            }
            if (!getIsNew&&!getOpenUserCheck.old) {
                await __B2BAPI.post('/d/b2bOrderLists', [{
                    "updateOne" : {
                        "filter": {"ID": getOpenUserCheck.ID},
                        "update" : getOpenUserCheck
                    }
                }])
                await timeout(500);
                /* toast.success('Wijzigingen zijn opgeslagen.', toastOptions); */
            }
            if (getIsNew) {
                await __B2BAPI.post('/d/b2bOrderLists', [{
                    "insertOne" : {
                        "document": getOpenUserCheck
                    }
                }])
                await timeout(500);
                /* toast.success('Herhaal bestelling toegevoegd.', toastOptions); */
            }
            setOpenUser({});
            setOpenUserCheck({});
            loadUsers();
			if (getSaveChanges) {
				setSaveChanges(false)
			}
        } catch (error) {
            setLoading(false);
            /* toast.error('Er is helaas iets misgegaan.', toastOptions); */
            console.log(error);
        }
        setLoading(false);
    };

    const onDelete = async (e) => {
        setLoading(true);
        e.preventDefault();
		if (getDeleteUser.old) {
			await __B2BAPI.post('/d/b2bOrderLists', [{
				"deleteOne" : {
					"filter": {"_id": getDeleteUser._id}
				}
			}]);
		}
		if (!getDeleteUser.old) {
			await __B2BAPI.post('/d/b2bOrderLists', [{
				"deleteOne" : {
					"filter": {"ID": getDeleteUser.ID}
				}
			}]);
		}
        await timeout(500);
        setDeleteUser({});
        /* toast.info('Herhaal bestelling verwijderd', toastOptions); */
        setLoading(false);
        loadUsers();
    };

	const sendToCart = (e) => {
		e.preventDefault();
		const itemsToCart = []
		for (let index = 0; index < getOpenUserCheck.items.length; index++) {
			const element = getOpenUserCheck.items[index];
			const found = _.find(userItems, {Item: element.Item});
			if (!found) continue;
			itemsToCart.push({...found, quantity: element.Quantity, id:element.Item, price: found.NewPrice.toFixed(2)})
		}
		console.log(itemsToCart);
		setItems(itemsToCart);
		/* if (getIsNew) {
			setSaveChanges(false)
			setOpenUser({});
			setOpenUserCheck({});
			return
		}
		if (!equalCheck) {
			setSaveChanges(true);
		} else {
			setOpenUser({});
			setOpenUserCheck({});
		} */

		/* setOpenUser({});
		setOpenUserCheck({}); */
	};

    useEffect(() => {
        loadUsers();
		console.log(items);
    }, []);

	const equalCheck = React.useMemo(() => {
		return _.isEqual(getOpenUserCheck, getOpenUser)
    }, [getOpenUserCheck, getOpenUser]);

	const totalCalc = React.useMemo(() => {
		if (_.isEmpty(getOpenUserCheck)) return
		const ret = calculateCart(getOpenUserCheck.items)
		return {
			total: _.sumBy(ret.filter(z=>!z.shipping), e=>e.NetPrice*e.Quantity),
			totalShipping: _.sumBy(ret.filter(z=>z.shipping), e=>e.NetPrice*e.Quantity)
		}
    }, [getOpenUserCheck]);

	const Headerr = () => {
		return (
			<>
				{_.isEmpty(getOpenUser)&&<HStack>
					<ButtonGroup size="sm" variant="outline">
					<Button borderColor={"gray.600"} onClick={openUserNew} iconSpacing="1" leftIcon={<RiAddFill fontSize="1.25em" />}>
                        toev.
                    </Button></ButtonGroup>
					<Text fontSize={{base: "md", md: "lg"}} fontWeight={600}>Herhaal bestellingen</Text>
					{/* <Spacer />
					<Button onClick={()=>history.goBack()} color={mode("black", "white")} bg={"blackAlpha.200"}><i className="fad fa-times"></i></Button> */}
				</HStack>}
				{!_.isEmpty(getOpenUser)&&<HStack>
					<Text fontSize={{base: "md", md: "lg"}} fontWeight={600}>Herhaal bestelling {getIsNew?"aanmaken":"aanpassen"}</Text>
					<Spacer />
				</HStack>}
			</>
		)
	};

	return (
		<>
		<Navbar data={{ Headerr }}/>
			<Box w={"100%"}
				px={{sm: "0vw", md: "5vw", lg: "7vw"}}  pt={"63px"}
			>
				<Box w={"100%"} display={{base: "none", md: "block"}}>
					{!_.isEmpty(getOpenUser)&&
						<>
							<Flex
								//px={{sm: "0vw", md: "5vw", lg: "7vw"}} pt={"63px"}
								direction={{ base: 'column', md: 'row' }}
								height={{ base: (height-(190))+"px", md: (height-(190))+"px" }} overflowY={"auto"}
							>

								<Box
									w={"100%"}
									bg={"blackAlpha.200"}
									_dark={{ bg: "whiteAlpha.200" }}
									rounded={"lg"}
									py={2}
									mt={{ base: 0, md: 2 }}
									px={{ base: 2, md: 4 }}
									mb={{ base: 4, md: 0 }}
									mx={{ base: 0, md: 2 }}
								>
									<Text fontWeight={600} mb={2}>
										Items <Button bg={"blackAlpha.200"} _dark={{ bg: "whiteAlpha.200" }} onClick={z=>{z.preventDefault();setAddItem({quantity: 0})}}><i className="fad fa-plus"></i></Button>
									</Text>
									<Divider/>
										<Box overflowY={"auto"} h={{ base: 'auto', md: (height-(330))+"px" }} >{getOpenUserCheck.items.map(product=><PlanListItem data={{ product, onSubtractButton, onAddButton, removingItem, setOpenUserCheck }} key={product.Item} />)}</Box>
									<Divider/>
									<Box w={"full"}>
										<HStack
											justifyContent="space-between"
											pt={4}
											width="full">
											<Text fontSize={"md"}>Verzendkosten</Text>
											<Text size="md">€ {totalCalc.totalShipping.toFixed(2)}</Text>
										</HStack>
										<HStack
											justifyContent="space-between"
											width="full">
											<Text fontSize={"lg"}>Totaal</Text>
											<Text size="lg">€ {totalCalc.total.toFixed(2)}</Text>
										</HStack>
									</Box>
								</Box>
								<Box
									bg={"white"}
									_dark={{bg: "gray.700"}}
									maxW={"2xl"}
									w={"100%"}
									h={{ base: "auto" }}
									rounded={{ sm: 'none', md: 'lg' }}
									py={{ base: 4, md: 2 }}
									px={{ base: 2, md: 4 }}
									mt={{ base: 0, md: 2 }}
									mx={{ base: 0, md: 2 }}
								>
									<form onSubmit={onSave} id={"subber"}>
										<VStack spacing={4}>
											<FormControl>
												<FormLabel fontWeight={600}>Status</FormLabel>
												<Checkbox
													size={"lg"}
													colorScheme={inEditCheck("status")?"green":"orange"}
													isChecked={inEditCheck("status")}
													isIndeterminate={!inEditCheck("status")}
													onChange={e=>editCheckField(e, "status")}
												>
													{inEditCheck("status")?"Actief":"Gepauzeerd"}
												</Checkbox>
											</FormControl>
											<OrderPlanner setOpenUserCheck={setOpenUserCheck} getOpenUserCheck={getOpenUserCheck} plannerSettings={getOpenUserCheck.plannerSettings} edited={equalCheck}>
												<FormControl>
													{("deliveryAddress" in getOpenUserCheck)&&<>
														<FormLabel fontWeight={"semibold"}>Bezorgadres</FormLabel>
														<SelectElement
															menuPortalTarget={document.body}
															menuPosition={'fixed'}
															menuPlacement="auto"
															defaultValue={getOpenUserCheck.address}
															options={
																filter(account.addresses, x=>{ return x.Type===4&&x.AddressLine1&&x.Postcode }).map(z=>{
																	return {
																		...z,
																		value: z.ID,
																		label: z.AddressLine1+ ", " +z.Postcode||""
																	}
																})
															}
															onChange={e=>{setOpenUserCheck(zz=>{
																const z = {...zz};
																return {
																	...z,
																	deliveryAddress: e.ID,
																	address: e,
																}
															})}}
														/>
													</>}
												</FormControl>
											</OrderPlanner>

										</VStack>
									</form>
								</Box>
							</Flex>

							<Divider display={{ sm: 'block', md: 'none' }}/>

							<HStack my={3} px={{base: "2vw", md: "0vw"}}>
								<Button onClick={(e)=>openUser(e, {})} disabled={getLoading}>Terug</Button>
								<Spacer/>
								<Button bg={c_sendToCartBtn} color={"white"} onClick={sendToCart} disabled={getLoading||_.isEmpty(getOpenUserCheck.items)}><i className='far fa-cart-arrow-down'/></Button>
								<Button  bg={c_opslaanBtn} color={"white"} disabled={getLoading||_.isEmpty(getOpenUserCheck.address)||_.isEmpty(getOpenUserCheck.items)} type="submit" form='subber'>Opslaan</Button>
							</HStack>
						</>
					}
				</Box>
				<Box w={"100%"} display={{base: "block", md: "none"}}>
					{!_.isEmpty(getOpenUser)&&
						<>
							<Flex
								//px={{sm: "0vw", md: "5vw", lg: "7vw"}} pt={"63px"}
								direction={{ base: 'column', md: 'row' }}
								//height={{ base: (height-(190))+"px", md: (height-(190))+"px" }} overflowY={"auto"}
							>

								{getStep===1&&<Box
									w={"100%"}
									bg={"white"}
									_dark={{ bg: "whiteAlpha.200" }}
									rounded={"lg"}
									py={2}
									mt={{ base: 0, md: 2 }}
									px={{ base: 2, md: 4 }}
									//mb={{ base: 4, md: 0 }}
									mx={{ base: 0, md: 2 }}
								>
									<Text fontWeight={600} mb={2}>
										Items <Button bg={"blackAlpha.200"} _dark={{ bg: "whiteAlpha.200" }} onClick={z=>{z.preventDefault();setAddItem({quantity: 0})}}><i className="fad fa-plus"></i></Button>
									</Text>
									<Divider/>
										<Box overflowY={"auto"} h={{ base: (height-(320))+"px" }} >{getOpenUserCheck.items.map(product=><PlanListItem data={{ product, onSubtractButton, onAddButton, removingItem, setOpenUserCheck }} key={product.Item} />)}</Box>
									<Divider/>
									<Box w={"full"}>
										<HStack
											justifyContent="space-between"
											pt={4}
											width="full">
											<Text fontSize={"md"}>Verzendkosten</Text>
											<Text size="md">€ {totalCalc.totalShipping.toFixed(2)}</Text>
										</HStack>
										<HStack
											justifyContent="space-between"
											width="full">
											<Text fontSize={"lg"}>Totaal</Text>
											<Text size="lg">€ {totalCalc.total.toFixed(2)}</Text>
										</HStack>
									</Box>
								</Box>}
								{getStep===2&&<Box
									bg={"white"}
									_dark={{bg: "gray.700"}}
									maxW={"2xl"}
									w={"100%"}
									rounded={{ sm: 'none', md: 'lg' }}
									py={{ base: 4, md: 2 }}
									px={{ base: 2, md: 4 }}
									mt={{ base: 0, md: 2 }}
									mx={{ base: 0, md: 2 }}
									overflowY={"auto"} h={{ base: (height-(190))+"px" }}
								>
									<form onSubmit={onSave} id={"subber"}>
										<VStack spacing={4}>
											<FormControl>
												<FormLabel fontWeight={600}>Status</FormLabel>
												<Checkbox
													size={"lg"}
													colorScheme={inEditCheck("status")?"green":"orange"}
													isChecked={inEditCheck("status")}
													isIndeterminate={!inEditCheck("status")}
													onChange={e=>editCheckField(e, "status")}
												>
													{inEditCheck("status")?"Actief":"Gepauzeerd"}
												</Checkbox>
											</FormControl>
											<OrderPlanner setOpenUserCheck={setOpenUserCheck} getOpenUserCheck={getOpenUserCheck} plannerSettings={getOpenUserCheck.plannerSettings} edited={equalCheck}>

												<FormControl>
													{("deliveryAddress" in getOpenUserCheck)&&<>
														<FormLabel fontWeight={"semibold"}>Bezorgadres</FormLabel>
														<SelectElement
															menuPortalTarget={document.body}
															menuPosition={'fixed'}
															menuPlacement="auto"
															defaultValue={getOpenUserCheck.address}
															options={
																filter(account.addresses, x=>{ return x.Type===4&&x.AddressLine1&&x.Postcode }).map(z=>{
																	return {
																		...z,
																		value: z.ID,
																		label: z.AddressLine1+ ", " +z.Postcode||""
																	}
																})
															}
															onChange={e=>{setOpenUserCheck(zz=>{
																const z = {...zz};
																return {
																	...z,
																	deliveryAddress: e.ID,
																	address: e,
																}
															})}}
														/>
													</>}
												</FormControl>
											</OrderPlanner>
										</VStack>
									</form>
								</Box>}
							</Flex>

							<Divider display={{ sm: 'block', md: 'none' }}/>

							<HStack my={3} px={{base: "2vw", md: "0vw"}}>
								{getStep===1&&<>
								<Button onClick={(e)=>openUser(e, {})} disabled={getLoading}>Terug</Button>
								<Spacer/>
								<Button bg={c_opslaanBtn} color={"white"} onClick={()=>setStep(2)}>Volgende</Button>
								</>}
								{getStep===2&&<>
									<Button onClick={(e)=>setStep(1)} disabled={getLoading}>Terug</Button>
									<Spacer/>
									<Button bg={c_sendToCartBtn} color={"white"} onClick={sendToCart} disabled={getLoading||_.isEmpty(getOpenUserCheck.items)}><i className='far fa-cart-arrow-down'/></Button>
									<Button  bg={c_opslaanBtn} color={"white"} disabled={getLoading||_.isEmpty(getOpenUserCheck.address)||_.isEmpty(getOpenUserCheck.items)} type="submit" form='subber'>Opslaan</Button>
								</>}
							</HStack>
						</>
					}
				</Box>
				{_.isEmpty(getOpenUser)&&<Box w={"100%"}>
					{(getPageLoading&&
						<></>)
					||
						<>
							<SimpleGrid columns={{md: 1, lg: 2}} spacing='20px' py={{base: 2, md: 4}} px={{base: 2, md: 0}}>
								{getUsers.map(e=>{
									return (
										<Box key={e.ID} borderRadius={"lg"} shadow={"md"} bg={"white"} _dark={{ bg: "gray.700" }} scale w="100%" py={4} px={8} >
											<HStack>
												<Box>
													<Text fontWeight={600}>Status:
														{e.status&&<Badge ms={2} bg={'green.600'} color={"white"} rounded={"md"}>Actief</Badge>}
														{!e.status&&<Badge ms={2} className="ms-1" bg='orange.600' color={"white"} rounded={"md"}>Gepauzeerd</Badge>}
													</Text>
													<Text fontWeight={600}>Volgende zending:</Text>
													<Text ms={4}>{moment(e.nextDateString).format("ddd DD MMM YY")}</Text>
													<br/>
													<span>{e.items.length} Producten</span>
												</Box>
												<Spacer/>
												<VStack>
													<Button h={10} w={10} color={"red.700"} _dark={{ bg: "gray.750", color: "red.300" }} onClick={x=>setDeleteUser(e)}>
														<i className="fad fa-times"></i>
													</Button>
													<Button h={10} w={10} color={"blue.700"} _dark={{ bg: "gray.750", color: "blue.200" }}  onClick={x=>openUser(x, e)}>
														<i className="fad fa-pen"></i>
													</Button>
												</VStack>
											</HStack>
										</Box>
									)
								})}
							</SimpleGrid>
						</>
					}
				</Box>}
			</Box>
            <Modal onClose={()=>setDeleteUser({})} isOpen={!_.isEmpty(getDeleteUser)} isCentered>
				<ModalOverlay
					bg='blackAlpha.300'
					backdropFilter='blur(10px)'
				/>
				<ModalContent borderRadius={"lg"}>
					<ModalHeader>Herhaal bestelling verwijderen</ModalHeader>
					<ModalBody
						bg="white"
						_dark={{ bg: "gray.800" }}>
					<ModalCloseButton color={"white"} bg={"gray.700"} />
					<p>Weet je zeker dat je deze herhaal bestelling wil verwijderen?</p>
					</ModalBody>
					<Divider />
					<ModalFooter>
						<Button onClick={()=>setDeleteUser({})}>Sluit</Button>
						<Spacer/>
						<Button disabled={getLoading} colorScheme={"red"} onClick={onDelete}>Verwijderen</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
            {!_.isEmpty(getAddItem)&&<Modal onClose={()=>setAddItem({})} isOpen={getAddItem} isCentered>
				<ModalOverlay
					bg='blackAlpha.300'
					backdropFilter='blur(10px)'
				/>
				<ModalContent borderRadius={"lg"}>
					<ModalHeader>Item tovoegen</ModalHeader>
					<form onSubmit={z=>{z.preventDefault();console.log(getAddItem);submitAddItem()}}>
					<ModalBody
						bg="white"
						_dark={{ bg: "gray.800" }}
					>
						<ModalCloseButton color={"white"} bg={"gray.700"} />
						<FormControl>
							{("deliveryAddress" in getOpenUserCheck)&&<>
							<FormLabel fontWeight={600}>Kies een item om toe te voegen?</FormLabel>
								<SelectElement
									options={
										_.sortBy(account.userItems.filter(z=>!(z.ItemGroupCode==="TRAIN")), "ItemDescription").map(z=>{
											return {
												...z,
												value: z.Item,
												label: (z.Naam||z.ItemDescription)+(z.ExtraInfo?" - "+z.ExtraInfo:"")
											}
										})
									}
									onChange={x=>setAddItem({...x, quantity: 1})}
								/>
							</>}
						</FormControl>
						<FormControl my={2}>
							<FormLabel fontWeight={600}>Aantal</FormLabel>
							<Input
								type={"number"}
								min={1}
								placeholder='aantal...'
								required
								value={getAddItem.quantity}
								onChange={e=>setAddItem(x=>{ return{...x, quantity: Number(e.target.value)}})}
							/>
						</FormControl>
					</ModalBody>
					<Divider />
					<ModalFooter>
						<Button onClick={()=>setAddItem(false)}>Sluit</Button>
						<Spacer/>
						<Button type='submit' disabled={!("ID" in getAddItem)} colorScheme={"green"}>Toevoegen</Button>
					</ModalFooter>
					</form>
				</ModalContent>
			</Modal>}
		</>
	);
};

export default Page;