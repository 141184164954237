import React, { useState, useEffect } from 'react';
import {
	Container, SimpleGrid, Box, Button, Center, Flex, HStack, IconButton, Link, Menu, chakra, ModalBody,
	FormControl, Checkbox, FormLabel, MenuList, Stack, Text, useColorMode, useColorModeValue as mode, useColorModeValue, VStack, Drawer ,DrawerOverlay, DrawerCloseButton, DrawerHeader, DrawerContent, Badge, Image, Input, Divider, Spacer, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalFooter, ModalHeader, ButtonGroup
} from '@chakra-ui/react';
import { RiAddFill, RiArrowRightUpLine } from 'react-icons/ri'
import { v4 as uuidv4 } from 'uuid';
import _, { find, isEmpty, isEqual } from 'lodash';
import { SelectBox } from 'devextreme-react/select-box';
import { __B2BAPI_V3, __boccaApi,_crm } from "@bocca-app-repo/app-components/components/api";
import { SelectElement, _data } from "@bocca-app-repo/app-components";
import useWindowDimensions from "helpers/screensize";
import { useHistory } from 'react-router-dom';

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
const Page = (props) => {
	const t_listItem_bg = useColorModeValue("gray.100", "gray.700");
	const account = _data.useMainStore(state => state.account)
    const [getUsers, setUsers] = useState([]);
    const [getAddresses, setAddresses] = useState([]);
    const [getOpenUser, setOpenUser] = useState({});
    const [getOpenUserCheck, setOpenUserCheck] = useState({});
    const [getOpenUserUpdate, setOpenUserUpdate] = useState({});
    const [getDeleteUser, setDeleteUser] = useState({});
    const [getIsNew, setIsNew] = useState(false);
    const [getLoading, setLoading] = useState(false);
    const [getPageLoading, setPageLoading] = useState(true);
    const history = useHistory();

    const loadUsers = async () => {
        setPageLoading(true);
        const gotUsers = await __B2BAPI_V3.get(`/auth?from=${account.ID}&fields=-password`)
        setUsers(gotUsers.data);
        const gotAddresses = await __B2BAPI_V3.get(`/d/b2bAddresses?Account=${account.ID}`);
        setAddresses(gotAddresses.data.filter(e=>!_.isEmpty(e.AddressLine1)&&e.Type===4));
        await timeout(1000);
        setPageLoading(false);
    };

    const openUser = (e, data) => {
        e.preventDefault();
        setOpenUser(data);
        setOpenUserCheck(data);
        setOpenUserUpdate({})
    }
    const openUserNew = (e) => {
        e.preventDefault();
        const data = {
            from: account.ID,
            fromName: account.Name,
            status: true,
            admin: true,
            superAdmin: false,
            isLocation: false,
            uuid: uuidv4(),
            password: "kjgvkjg#$6546546kjhgfi"
        }
        setOpenUser(data);
        setOpenUserCheck(data);
        setOpenUserUpdate(data);
        setIsNew(true)
    }

    const inEditCheck = (field) => {
        return field in getOpenUserUpdate?getOpenUserUpdate[field]:getOpenUser[field]
    }
    const editField = (e, field) => {
        setOpenUserUpdate(x=>{
            let hld = {...x};
            if (e.target.value === getOpenUser[field] && !getIsNew) delete hld[field];
            if (!(e.target.value === getOpenUser[field]) || getIsNew) hld[field] = e.target.value;
            setOpenUserCheck(o=>{return {...o, [field]: e.target.value}});
            return hld
        })
    }
    const editCheckField = (e, field) => {
        setOpenUserUpdate(x=>{
            let hld = {...x};
            if (e.target.checked === getOpenUser[field] && !getIsNew) delete hld[field];
            if (!(e.target.checked === getOpenUser[field]) || getIsNew) hld[field] = e.target.checked;
            setOpenUserCheck(o=>{return {...o, [field]: e.target.checked}});
            return hld
        })
    }

    const onSave = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            if (!getIsNew) {
                await __boccaApi.post('/auth/update', [{
                    "updateOne" : {
                        "filter": {"uuid": getOpenUser.uuid},
                        "update" : getOpenUserUpdate
                    }
                }])
                await timeout(1000);
                /* toast.success('Wijzigingen zijn opgeslagen.', toastOptions); */
            }
            if (getIsNew) {
                let newnew = getOpenUserUpdate.email;
                const str = `${uuidv4()}==`+newnew;
                const enc = window.btoa(str);
                const str2 = `https://bocca.app/nieuwe-account?resetLink=`+enc;
                await __boccaApi.post('/auth/register', {...getOpenUserUpdate, link:str2, resetCode: enc});
                await timeout(1000);
                /* toast.success('Gebruiker toegevoegd.', toastOptions); */
            }
            setOpenUser({});
            setOpenUserCheck({});
            setOpenUserUpdate({});
            loadUsers();
        } catch (error) {
            setLoading(false);
            /* toast.error('Er is helaas iets misgegaan.', toastOptions); */
            console.log(error);
        }
        setLoading(false);
    };

    const onDelete = async (e) => {
        setLoading(true);
        e.preventDefault();
        await __boccaApi.post('/auth/update', [{
            "deleteOne" : {
                "filter": {"uuid": getDeleteUser.uuid}
            }
        }]);
        await timeout(1000);
        setDeleteUser({});
        /* toast.info('Gebruiker verwijderd', toastOptions); */
        setLoading(false);
        loadUsers();
    };

    useEffect(() => {
        loadUsers();
    }, []);

    return (
        <>
            <Modal size={"full"} onClose={()=>{}} isOpen={true} isCentered>
				<ModalOverlay
					bg='blackAlpha.300'
					backdropFilter='blur(10px)'
				/>
				<ModalContent h={"100%"}>
					<ModalHeader>
                        {_.isEmpty(getOpenUser)&&<HStack>
                            <ButtonGroup size="sm" variant="outline">
                                <Button onClick={openUserNew} iconSpacing="1" leftIcon={<RiAddFill fontSize="1.25em" />}>
                                    gebruiker
                                </Button>
                            </ButtonGroup>
                            <Text>Gebruikers</Text>
                            <Spacer />
                            <Button onClick={()=>history.goBack()} color={"gray.25"}><i className="fad fa-times"></i></Button>
                        </HStack>}
                        {!_.isEmpty(getOpenUser)&&<HStack>
                            <Text>Gebruiker aanpassen</Text>
                            <Spacer />
                            <Button onClick={()=>history.goBack()} color={"gray.25"}><i className="fad fa-times"></i></Button>
                        </HStack>}
                    </ModalHeader>
					<ModalBody p={4} h={"100%"} overflowY={"auto"} m={0}
						bg="gray.50"
						_dark={{ bg: "gray.800" }}
                    >
                        {!_.isEmpty(getOpenUser)&&
                            <Center><Box maxW={"2xl"} w={"100%"}>
                                <form onSubmit={onSave} id={"subber"}>
                                    <VStack spacing={4}>
                                        <FormControl>
                                            <Checkbox
                                                isChecked={inEditCheck("status")}
                                                onChange={e=>editCheckField(e, "status")}
                                            >
                                                Actief
                                            </Checkbox>
                                        </FormControl>
                                        <FormControl>
                                            <Checkbox
                                                isChecked={inEditCheck("admin")}
                                                onChange={e=>editCheckField(e, "admin")}
                                            >
                                                Admin gebruiker
                                            </Checkbox>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel fontWeight={600}>Email <small>*</small></FormLabel>
                                            <Input type={"email"} placeholder='mijn@email.nl...' required value={inEditCheck("email")} onChange={e=>editField(e, "email")} bg={"white"} _dark={{ bg: "gray.800" }}/>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel fontWeight={600}>Naam</FormLabel>
                                            <Input placeholder='naam...' value={inEditCheck("name")} onChange={e=>editField(e, "name")} bg={"white"} _dark={{ bg: "gray.800" }}/>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel fontWeight={600}>Telefoonnummer</FormLabel>
                                            <Input type={"tel"} placeholder='061234...' value={inEditCheck("phone")} onChange={e=>editField(e, "phone")} bg={"white"} _dark={{ bg: "gray.800" }}/>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel fontWeight={600}>Standaard adres</FormLabel>
                                            <SelectBox id="custom-templates2"
                                                width="100%"
                                                value={inEditCheck("standardAddress")}
                                                dataSource={getAddresses.filter(e=>!_.isEmpty(e.AddressLine1)&&e.Type===4)}
                                                onValueChanged={e=>{editField({target:{value:e.value||""}}, "standardAddress")}}
                                                searchEnabled
                                                searchExpr="AddressLine1"
                                                searchTimeout={200}
                                                minSearchLength={2}
                                                searchMode="contains"
                                                displayExpr="AddressLine1"
                                                valueExpr="ID"
                                                showClearButton
                                                showDataBeforeSearch
                                            />
                                        </FormControl>
                                    </VStack>
                                    {/* <br />
                                    <div className='modal-footer'>
                                        <button className="btn btn-outline-danger" onClick={(e)=>openUser(e, {})} type="button" disabled={getLoading}>Annuleren</button>
                                        <button className="btn btn-primary" disabled={_.isEqual(getOpenUser, getOpenUserCheck)||getLoading} type="submit" >
                                            Opslaan
                                        </button>

                                    </div> */}
                                </form>
                            </Box></Center>
                        }
                        {_.isEmpty(getOpenUser)&&
                            <SimpleGrid columns={{md: 1, lg: 2}} spacing='20px'>
                                {getUsers.filter(e=>!e.isLocation).map(e=>{
                                    return (
                                        <Box key={e.uuid} borderRadius={"lg"} bgColor={t_listItem_bg} scale w="100%" py={4} px={8} >
                                            <HStack>
                                                <Box>
                                                    <Text fontSize={"md"} fontWeight={600}><Box as={"span"} color={e.status?"green.600":"red.600"}><i className={`fad fa-${e.status?"check":"times"}`}></i> </Box>{e.email}</Text>
                                                    <Text fontSize={"md"}>{e.phone}</Text>
                                                    {e.admin&&<Badge bg='info'>Admin</Badge>}

                                                </Box>
                                                <Spacer/>
                                                <VStack>
                                                    <Button h={10} w={10} bg={"gray.200"} color={"red.700"} _dark={{ bg: "gray.750", color: "red.500" }} onClick={x=>setDeleteUser(e)}>
                                                        <i className="fad fa-times"></i>
                                                    </Button>
                                                    <Button h={10} w={10}  bg={"gray.200"} color={"blue.700"} _dark={{ bg: "gray.750", color: "blue.200" }} onClick={x=>openUser(x, e)}>
                                                        <i className="fad fa-pen"></i>
                                                    </Button>
                                                </VStack>
                                            </HStack>
                                        </Box>
                                    )
                                })}
                            </SimpleGrid>
                        }
					</ModalBody>
					<Divider />
					<ModalFooter>
						{!_.isEmpty(getOpenUser)&&<><Button onClick={(e)=>openUser(e, {})} disabled={getLoading}>Terug</Button>
						<Spacer/>
						<Button bg={"green.600"} color={"white"} disabled={_.isEqual(getOpenUser, getOpenUserCheck)||getLoading} type="submit" form='subber'>Opslaan</Button></>}
						{_.isEmpty(getOpenUser)&&<><Button onClick={()=>history.goBack()}>Terug</Button>
						<Spacer/>
						{/* <Button disabled={getLoading} colorScheme={"red"} onClick={onDelete}>Verwijderen</Button> */}</>}
					</ModalFooter>
				</ModalContent>
			</Modal>
            <Modal onClose={()=>setDeleteUser({})} isOpen={!_.isEmpty(getDeleteUser)} isCentered>
				<ModalOverlay
					bg='blackAlpha.300'
					backdropFilter='blur(10px)'
				/>
				<ModalContent borderRadius={"lg"}>
					<ModalHeader>Gebruiker verwijderen</ModalHeader>
					<ModalBody
						bg="white"
						_dark={{ bg: "gray.800" }}>
					<ModalCloseButton color={"white"} bg={"gray.700"} />
					<p>Weet je zeker dat je deze gebruiker wil verwijderen?</p>
					</ModalBody>
					<Divider />
					<ModalFooter>
						<Button onClick={()=>setDeleteUser({})}>Sluit</Button>
						<Spacer/>
						<Button disabled={getLoading} colorScheme={"red"} onClick={onDelete}>Verwijderen</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
        </>
    );
};
export default Page