import { isEmpty, find } from "lodash";
import { __B2BAPI, __B2BAPI_V3, _bulk } from '../../api';
import toast from 'react-hot-toast';
import moment from "moment";

export const getAccount = async (ID) => {
    try {
        const account = await __B2BAPI_V3.get(`/app/accounts/userAccount/${ID}`)
        console.log(account.data);
        return account.data;
    } catch (error) {
        throw error
    }
}

export const getAccounts = async () => {
    try {
        const b2bmain = await __B2BAPI_V3.get(`/app/accounts/accounts`)
        return b2bmain.data.filter(e=>e.ActiveStatus&&e.Status==="C");
    } catch (error) {
        throw error
    }
}

export const getAddresses = async (ID) => {
    const b2bAdresGet = await __B2BAPI_V3.get(`/d/b2bAddresses?Account=${ID}&Type=4&fields=-_id`);
    return b2bAdresGet.data;
};


export const updateAccount = async (ID, data) => {
    const res = await __B2BAPI.post('/d/Accounts', [{
        "updateOne": {
            "filter": {"ID": ID},
            "update": data
        }
    }])
    return res.data;
}

export const upsertAccount = async (ID, data) => {
    const res = await __B2BAPI.post('/d/Accounts', [{
        "updateOne": {
            "filter": {"ID": ID},
            "update": data,
            "upsert": true
        }
    }])
    return res.data;
}

export const removeAccount = async (ID) => {
    const res = await __B2BAPI.post('/d/Accounts', [{
        "deleteOne": {
            "filter": {"ID": ID}
        }
    }])
    return res.data;
}

export const deleteAppAccount = async (ID) => {
    const res = await __B2BAPI_V3.post('/d/b2bCustomerDatasV3', [{
        "deleteOne": {
            "filter": {"customerId": ID}
        }
    }])
    return res.data;
}





export const getAccountsFromExact = async () => {
    toast.info('Accounts update gestart');
    let bulkwright = [];
    return new Promise((resolve, reject) => {
        const ufe = async (params) => {
            const uri = params?params:`?$select=ID,Accountant,AccountManager,AccountManagerFullName,AccountManagerHID,ActivitySector,ActivitySubSector,AddressLine1,AddressLine2,AddressLine3,Blocked,BusinessType,CanDropShip,City,Classification,Classification1,Classification2,Classification3,Classification4,Classification5,Classification6,Classification7,Classification8,Code,Country,CountryName,CreditLinePurchase,CreditLineSales,DiscountPurchase,DiscountSales,Division,Email,EndDate,InvoiceAccount,InvoiceAccountCode,InvoiceAccountName,InvoiceAttachmentType,InvoicingMethod,MainContact,Name,Parent,Phone,PhoneExtension,Postcode,PriceList,PurchaseCurrency,PurchaseCurrencyDescription,PurchaseVATCode,PurchaseVATCodeDescription,Remarks,Reseller,ResellerCode,SalesCurrency,SalesCurrencyDescription,SearchCode,StartDate,State,StateName,Status,Type`;
            try {
                const e = await _bulk.__Accounts('get', uri);
                bulkwright = [...bulkwright, ...e.data.d.results];
                if (bulkwright.length > 0) {
                    if (e.data.d.__next) {
                        let next = "?$" + e.data.d.__next.split("?$").pop();
                        ufe(next)
                    } else {
                        const momentNow = Number(moment().format("YYYYMMDD"));
                        const toUper = bulkwright.filter(o=> o.Status === "C").map(o=>{
                            let sd = Number(moment.unix(Number(o.StartDate.split("e(")[1].split(")")[0])/1000).format("YYYYMMDD"))
                            let ed = o.EndDate?Number(moment.unix(Number(o.EndDate.split("e(")[1].split(")")[0])/1000).format("YYYYMMDD")):null;
                            let status = true;
                            if ((sd <= momentNow) && (ed?(ed>=momentNow):true)) {status=true} else {status=false}

                            return {
                                "insertOne" : {
                                    "document" : {
                                        ...o,
                                        ActiveStatus: status,
                                        StartDate: sd,
                                        EndDate: ed
                                    }
                                }
                            }
                        })
                        //console.log(toUper);
                        await __B2BAPI.delete("/d/Accounts/remove");
                        await __B2BAPI.post('/d/Accounts', toUper.filter(o=> o.insertOne.document.ActiveStatus === true))
                        //console.log(toUper.filter(o=> o.ActiveStatus === true));
                        toast.success('Accounts update uitgevoerd');
                        resolve();
                    }
                } else {
                    toast.error('Kon data niet ophalen.');
                    reject('Kon data niet ophalen.');
                };
            } catch (error) {
                toast.error('Kon data niet ophalen.');
                reject('Kon data niet ophalen.');
            };
        };
        ufe();
    });
};