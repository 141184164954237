import { Badge, Box, Button, chakra, Divider, Flex, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, SimpleGrid, Spacer, Text, useColorModeValue as mode, VStack } from '@chakra-ui/react';

import { _data } from "@bocca-app-repo/app-components";
import { __B2BAPI } from "@bocca-app-repo/app-components/components/api";
import useWindowDimensions from "helpers/screensize";
import HTMLDecoderEncoder from 'html-encoder-decoder';
import parse from "html-react-parser";
import { find, has, orderBy, round } from 'lodash';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import WorkshopElement from "./components/WorkshopElement";

export default function App() {
	const account = _data.useMainStore(state => state.account);
	const [getWorkshops, setWorkshops] = useState([]);
	const [isOpenProduct, setIsOpenProduct] = useState(false);
	const [getOpenProductData, setOpenProductData] = useState({});

	const openProduct = (data) => {
		setOpenProductData(data);
		setIsOpenProduct(true);
	}
	const CloseProduct = () => {
		setOpenProductData({});
		setIsOpenProduct(false);
	}

	useEffect(() => {
		workshopList();
	}, []);

	const workshopList = async () => {
		const prods = orderBy(account.userWorkshops, ['ItemDescription'], ['asc']);
		const epost = await __B2BAPI.get(`/d/b2bCustomerDatasV2?customerId=${account.ID}&fields=userItemsTrainingen`)
		let holdArray = []
		if(has(epost.data[0],"userItemsTrainingen")) {
			holdArray = prods.map(o=>{
				if (find(epost.data[0].userItemsTrainingen, {"Item": o.Item})) {
					const IItenm = find(epost.data[0].userItemsTrainingen, {"Item": o.Item})
					return {...o, ...{...IItenm, AfspraakKorting:round(IItenm.AfspraakKorting*100, 2), AfspraakAantalDone:IItenm.AfspraakAantal-IItenm.AfspraakAantalKlaar}}
				} else {
					return {...o}
				}
			})
			console.log(epost.data[0]);
			setWorkshops(holdArray);
		} else {
			setWorkshops(prods);
		};
	};

	return (
		<Box pt={4} px={{sm: "2vw", md: "5vw", lg: "7vw"}} pb={12}>
			<SimpleGrid columns={{base: 1, md: 2, lg: 3}} spacing={8}>
				{getWorkshops.map(e => <WorkshopElement workshop={e} key={e.ID} openProduct={openProduct} />)}
			</SimpleGrid>

			<ProductPopup data={{isOpenProduct, CloseProduct, getOpenProductData}}/>
		</Box>
	);
}

function ProductPopup({data}) {
	const {isOpenProduct, CloseProduct, getOpenProductData} = data;
	const [getTrainingsDates, setTrainingsDates] = useState([]);
	const [getSteps, setSteps] = useState(0);
	const { height } = useWindowDimensions();
	const hovercolor = mode("blackAlpha.300", "blackAlpha.100")

	React.useEffect(() => {
		if (!getOpenProductData) return;
		__B2BAPI.get(`/d/b2bTrainings?trainingCode=${getOpenProductData.ItemCode}&sort=date&startDate>${moment().toISOString(true)}&limit=20`).then((b2bTrainings) => {
			setTrainingsDates(b2bTrainings.data.map(o=>{
				return {...o, dateformated: moment(o.startDate).format("LL")+` | ${o.startTime??""} - ${o.endTime??""}`, beschikbaar: (o.soldPlaces&&o.standardPlaces-o.soldPlaces)||o.standardPlaces}
			}))
			console.log(b2bTrainings.data);
		})
		.catch(error => {console.log(error);});
	}, [getOpenProductData]);

	return (
		<>
			<Modal size={"full"} onClose={CloseProduct} isOpen={isOpenProduct} isCentered>
				<ModalOverlay
					bg='blackAlpha.300'
					backdropFilter='blur(10px)'
				/>
				<ModalContent borderRadius={"lg"}>
					<ModalBody p={0}
						bg="white"
						_dark={{ bg: "gray.800" }}>
					<ModalCloseButton color={"white"} bg={"gray.700"} />
					<Box
						h="100%"
						w="100%"
						mx="auto"
					>
						{getSteps===0&&<><Image
							mx="auto"
							h={48}
							w="full"
							fit="cover"
							objectPosition="center"
							src={`https://apiv2.beantrack.app/images/${getOpenProductData.cover||"workshop-ph.jpg"}`}
							alt="avatar"
							/* _hover={{
							transform: 'scale(1.05)',
							}} */
						/>

						<Flex alignItems="center" px={2} py={3} bg="green.800">
							<chakra.h1 mx={3} color="white" fontWeight="bold" fontSize="lg">
								{getOpenProductData.Naam||getOpenProductData.ItemDescription}
							</chakra.h1>
						</Flex>

						<Box px={4} py={2} h={{base: "auto"}}>
							<chakra.div
								mt={1}
								fontSize="sm"
								color="gray.600"
								_dark={{ color: "gray.200" }}
							>
								{(getOpenProductData.AfspraakAantal&&getOpenProductData.AfspraakAantalKlaar<getOpenProductData.AfspraakAantal)&&<Badge mb={2} color={"gray.700"} _dark={{color: "gray.200"}}>
									<>{getOpenProductData.AfspraakAantal-getOpenProductData.AfspraakAantalKlaar} X Beschikbaar tegen {getOpenProductData.AfspraakKorting}% korting</>
								</Badge>}
								{getOpenProductData.productDescription&&parse(HTMLDecoderEncoder.decode(getOpenProductData.productDescription))}
							</chakra.div>
						</Box>

						<Box pt={4} px={6}>
							{getOpenProductData.ExtraInfo&&<chakra.h1
								fontSize="xl"
								fontWeight="bold"
								color="gray.800"
								_dark={{ color: "white" }}
							>
								{getOpenProductData.ExtraInfo}
							</chakra.h1>}

							{getOpenProductData.productInfo&&<chakra.p py={2} color="gray.700" _dark={{ color: "gray.400" }}>
								{getOpenProductData.productInfo}
							</chakra.p>}
						</Box>

						<Box pt={4} px={6}>
							<Text fontWeight={600} fontSize={"lg"}>€ {getOpenProductData.NewPrice}</Text>
						</Box></>}

						{getSteps===1&&<>
							<Image
								mx="auto"
								h={48}
								w="full"
								fit="cover"
								objectPosition="center"
								src={`https://apiv2.beantrack.app/images/${getOpenProductData.cover||"workshop-ph.jpg"}`}
								alt="avatar"
								/* _hover={{
								transform: 'scale(1.05)',
								}} */
							/>
							<Box
							h={(height-(265))+"px"} overflowY={"auto"} bg={"gray.50"} _dark={{ bg: "gray.700" }} p={6}>
								<SimpleGrid columns={{base: 1, md: 2, lg: 2}} spacing={4} >
									{getTrainingsDates.map((e, i) => <Box key={e.dateformated+i} shadow={"lg"} borderRadius={"lg"} py={3} bg={"gray.200"} _dark={{ bg: "gray.600", _hover: {bg:hovercolor} }} _hover={{ bg: hovercolor }}>
										<VStack >
											<Text fontSize={"sm"}>{e.dateformated}</Text>
											<Text fontSize={"sm"} >Plaatsen beschikbaar: {e.beschikbaar}</Text>
										</VStack>
									</Box>)}
								</SimpleGrid>
							</Box>
						</>}
					</Box>
					</ModalBody>
					<Divider />
					<ModalFooter>
						<Button onClick={CloseProduct}>Sluit</Button>
						<Spacer/>
						<Button colorScheme={"green"} onClick={()=>setSteps(1)}>Datums bekijken</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}