import { Box, Heading, SimpleGrid, Text, Divider, Flex, useColorModeValue as mode,HStack, Badge } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { _data } from "@bocca-app-repo/app-components";
import { useHistory } from "react-router-dom";
import { BlogCard } from './components/BlogCard'
import { __B2BAPI } from "@bocca-app-repo/app-components/components/api"
import { isEmpty, reverse } from 'lodash';
import BlogHeader from './components/BlogHeader';
import OrderAdvice from './components/OrderAdvice';
import moment from 'moment';

const Home = () => {
	const [getPosts, setPosts] = useState([]);
	const [getAutoOrder, setAutoOrder] = useState({});
	const account = _data.useMainStore(state => state.account);
	const history = useHistory()

	const onPageBeforeIn = async () => {
		/* showNotification() */
		const ez = await __B2BAPI.get('/d/b2bPosts')
		const gotUsers = await __B2BAPI.get(`/d/b2bOrderLists?account=${account.ID}&sort=nextDateString&limit=1`)
		console.log(ez);
		setPosts(reverse(ez.data))
		setAutoOrder((!isEmpty(gotUsers.data)&&gotUsers.data[0])||{})
	};
	useEffect(() => {
		onPageBeforeIn();
	}, []);
	return (
		<Box bg={mode('gray.50', 'inherit')} as="section" px={{sm: "0vw", md: "5vw", lg: "7vw"}}>
			<Flex
				//px={{sm: "0vw", md: "5vw", lg: "7vw"}} pt={"63px"} bg={"gray.600"}
				direction={{ base: 'column', md: 'row' }}
			>
				<Box w={{base: "auto", md: "2xl"}} py={4} px={2} display={{ base: 'none', md: 'block' }}>
					<Box bg={"white"} borderRadius={"lg"} py={2} px={2} shadow={"md"} mb={4}>
						<Text fontWeight={600} fontSize={"xl"}>Herhaal bestelling</Text>
						<Divider my={2}/>
						{(!isEmpty(getAutoOrder)&&
							<Box onClick={()=>history.push("/herhaal-bestelling")} cursor={"pointer"} _hover={{shadow:"md"}} borderRadius={"lg"} bgColor={"blackAlpha.200"} scale w="100%" py={2} px={4}>
								<HStack>
									<Box>
										<Text fontWeight={600}>Status:
											{getAutoOrder.status&&<Badge ms={2} bg='green.600' color={"white"} rounded={"md"}>Actief</Badge>}
											{!getAutoOrder.status&&<Badge ms={2} className="ms-1" bg='orange.600' color={"white"} rounded={"md"}>Gepauzeerd</Badge>}
										</Text>
										<Text fontWeight={600}>Items: <Text fontWeight={400} as={"span"}>{getAutoOrder.items.length}</Text></Text>
										<Text fontWeight={600}>Volgende zending:</Text>
										<Text ms={4}>{moment(getAutoOrder.nextDateString).format("ddd DD MMM YY")}</Text>
									</Box>
								</HStack>
							</Box>)
							||
							<Box>
								Nog niet ingesteld. <br />
								Herhaal bestelling instellen?
							</Box>
						}
					</Box>
					{/* <Box bg={"white"} py={2} px={2} borderRadius={"lg"} shadow={"md"}>
						<Text fontWeight={600} fontSize={"xl"}>Besteladvies</Text>
						<Divider my={2}/>
						<OrderAdvice />
					</Box> */}

				</Box>
				<Box bg={"white"} borderRadius={"lg"} my={4} mx={2}
					maxW={{
						base: 'xl',
						md: '2xl',
						lg: '7xl',
					}}
				>
					<Box textAlign="center" maxW="md" mx="auto" py={6}>
						<Text fontSize={{base: "md", md: "xl"}} fontWeight="extrabold" letterSpacing="tight">
							Nieuws {"&"} updates
						</Text>
					</Box>
					<Box display={{ base: 'none', md: 'block' }}>
					<BlogHeader data={!isEmpty(getPosts)&&{title: getPosts[0].title, subTitle: getPosts[0].subTitle, coverImg: `https://apiv2.beantrack.app/images/${getPosts[0].coverImg}`, id:getPosts[0].id}} />
					</Box>
						<Divider />
						<Box >
							<SimpleGrid
								mt="8"
								columns={{
									base: 1,
									lg: 3,
								}}
								spacing="14"
							>
								{getPosts.map((item, index) => (
									<BlogCard key={index} data={item} />
								))}
							</SimpleGrid>
						</Box>

				</Box>
			</Flex>
		</Box>
  	)
}
export default Home;