import React, { useState, useEffect } from 'react';
import {
	Container, SimpleGrid, Box, Button, Center, Flex, HStack, IconButton, Link, Menu, chakra, ModalBody,
	FormControl, Checkbox, FormLabel, MenuList, Stack, Text, useColorMode, useColorModeValue as mode, useColorModeValue, VStack, Drawer ,DrawerOverlay, DrawerCloseButton, DrawerHeader, DrawerContent, Badge, Image, Input, Divider, Spacer, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalFooter, ModalHeader, ButtonGroup
} from '@chakra-ui/react';
import { RiAddFill, RiArrowRightUpLine } from 'react-icons/ri'
import { v4 as uuidv4 } from 'uuid';
import _, { find, isEmpty, isEqual } from 'lodash';
import { SelectBox } from 'devextreme-react/select-box';
import { __B2BAPI_V3, __boccaApi,_crm } from "@bocca-app-repo/app-components/components/api";
import { SelectElement, _data } from "@bocca-app-repo/app-components";
import useWindowDimensions from "helpers/screensize";
import { useHistory } from 'react-router-dom';
import AddressAdd from 'helpers/Address';
import { _countries, _addressTypes } from "./addressExtras";


function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
const Page = (props) => {
    const { height } = useWindowDimensions();
	const t_listItem_bg = useColorModeValue("gray.100", "gray.700");
	const account = _data.useMainStore(state => state.account)
	const initAddresses = _data.useMainStore(state => state.initAddresses)
	const userAddresses = _data.useMainStore(state => state.userAddresses);
    const [getUsers, setUsers] = useState([]);
    const [getAddresses, setAddresses] = useState([]);
    const [getOpenUser, setOpenUser] = useState({});
    const [getOpenUserCheck, setOpenUserCheck] = useState({});
    const [getOpenUserUpdate, setOpenUserUpdate] = useState({});
    const [getDeleteUser, setDeleteUser] = useState({});
    const [getIsNew, setIsNew] = useState(false);
    const [getLoading, setLoading] = useState(false);
    const [getPageLoading, setPageLoading] = useState(true);
    const history = useHistory();

    const openUser = (e, data) => {
        e.preventDefault();
        setOpenUser(data);
        setOpenUserCheck(data);
        setOpenUserUpdate({})
    }

    const openUserNew = (e) => {
        e.preventDefault();
        const data = {
            Account: getOpenUser.ID,
            AddressLine1: "",
            City: "",
            Country: "NL",
            ID: uuidv4(),
            Main: false,
            Postcode: "",
            Type: 4
        }
        setOpenUser(data);
        setOpenUserCheck(data);
        setOpenUserUpdate(data);
        setIsNew(true)
    }


    const onSave = async (e) => {
        e.preventDefault();
        setLoading(true);

        console.log(getOpenUser);
        try {
            const nMap = [{
				"updateOne" : {
					"filter": {"ID": getOpenUser.ID},
					"update": getOpenUser,
                    "upsert": true
				}
			}];
            if (getIsNew) {
                await _crm.__Addresses('post', '', getOpenUser);
            }
            if (!getIsNew) {
                await _crm.__Addresses('put', `(guid'${getOpenUser.ID}')`, {
                    AddressLine1: getOpenUser.AddressLine1,
                    City: getOpenUser.City,
                    Country: getOpenUser.Country,
                    Postcode: getOpenUser.Postcode
                });
            }

			await __B2BAPI_V3.post('/d/b2bAddresses', nMap);

            setOpenUser({});
            setOpenUserCheck({});
            setOpenUserUpdate({});
            initAddresses(account.ID);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
        setLoading(false);
    };

    const onDelete = async (e) => {
        setLoading(true);
        e.preventDefault();
        await __boccaApi.post('/auth/update', [{
            "deleteOne" : {
                "filter": {"uuid": getDeleteUser.uuid}
            }
        }]);
        await timeout(1000);
        setDeleteUser({});
        /* toast.info('Gebruiker verwijderd', toastOptions); */
        setLoading(false);
        initAddresses(account.ID);
    };


    const BtnsCell = (e) => {
        return (<Center>
            <HStack direction="row" spacing="0" align="center" my={"auto"}>
                <Button variant="gost" color="blue.500" onClick={x=>openUser(x, e.data)} p={0}>
                    <i className='fad fa-edit' />
                </Button>
                {/* <Button variant="gost" color="red.500" onClick={x=>setDeleteUser(e.data)} p={0}>
                    <i className='fad fa-trash' />
                </Button> */}
            </HStack>
        </Center>)
    };

    return (
        <>
            <Modal size={"full"} onClose={()=>{}} isOpen={true} isCentered>
				<ModalOverlay
					bg='blackAlpha.300'
					backdropFilter='blur(10px)'
				/>
				<ModalContent h={"100%"}>
					<ModalHeader>
                        <HStack>

                            {isEmpty(getOpenUser)&&<>
                                <ButtonGroup size="sm" variant="outline">
                                    <Button onClick={openUserNew} iconSpacing="1" leftIcon={<RiAddFill fontSize="1.25em" />}>
                                        Adres
                                    </Button>
                                </ButtonGroup>
                                <Text>Adressen</Text>
                            </>}
                            {!isEmpty(getOpenUser)&&<>
                                {getIsNew&&<Text>Adres toevoegen</Text>}
                                {!getIsNew&&<Text>Adres aanpassen</Text>}
                            </>}
                            <Spacer />
                            <Button onClick={()=>history.goBack()} color={mode("red.500", "gray.25")}><i className="fad fa-times"></i></Button>
                        </HStack>
                    </ModalHeader>

					<Divider />
					<ModalBody p={4} h={"100%"} overflowY={"auto"} m={0}
						bg="gray.50"
						_dark={{ bg: "gray.800" }}
                    >
                        <Box overflowX="auto">
                            {isEmpty(getOpenUser)&&<>
                                <SimpleGrid columns={{md: 1, lg: 2}} spacing='20px'>
                                    {userAddresses.filter(e=>e.AddressLine1).map(e=>{
                                        return (
                                            <Box key={e.ID} borderRadius={"lg"} bgColor={t_listItem_bg} _hover={{shadow: "md"}} scale w="100%" py={4} px={8} cursor={"pointer"} onClick={x=>openUser(x, e)}>
                                                <HStack>
                                                    <Box>
                                                        <Text fontSize={"md"} fontWeight={600}>{e.AddressLine1} {e.AddressLine2}</Text>
                                                        <Text fontSize={"md"}>{e.Postcode} {e.City}</Text>
                                                        <Text fontSize={"md"}>{e.CountryName}</Text>
                                                    </Box>
                                                    <Spacer/>
                                                    <VStack>
                                                        {/* <Button h={10} w={10} colorScheme={"red"} onClick={x=>setDeleteUser(e)}>
                                                            <i className="fad fa-times"></i>
                                                        </Button> */}
                                                        <Button h={10} w={10} bg={mode("gray.200", "gray.750")} onClick={x=>openUser(x, e)}>
                                                            <i className="fad fa-pen"></i>
                                                        </Button>
                                                    </VStack>
                                                </HStack>
                                            </Box>
                                        )
                                    })}
                                </SimpleGrid>
                            </>}
                            {!isEmpty(getOpenUser)&&<>
                                <Center mt={3}>
                                    <Box maxW={"2xl"} w={"100%"} h={(height-240)+"px"}>
                                        <form onSubmit={onSave} id={"subber"}>
                                            <VStack spacing={4}>
                                                <FormControl>
                                                    <FormLabel fontWeight={600}>Straat en nummer <small>*</small></FormLabel>
                                                    <Input placeholder='...straat 45' required value={getOpenUser.AddressLine1||""} onChange={(e)=>setOpenUser(x=>{return {...x, AddressLine1: e.target.value}})} bg={mode("white", "gray.800")}/>
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel fontWeight={600}>Stad <small>*</small></FormLabel>
                                                    <Input placeholder='...amster' required value={getOpenUser.City||""} onChange={(e)=>setOpenUser(x=>{return {...x, City: e.target.value}})} bg={mode("white", "gray.800")}/>
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel fontWeight={600}>Postcode <small>*</small></FormLabel>
                                                    <Input placeholder='...1234 AA' required value={getOpenUser.Postcode||""} onChange={(e)=>setOpenUser(x=>{return {...x, Postcode: e.target.value}})} bg={mode("white", "gray.800")}/>
                                                </FormControl>
                                                <FormControl mt={4}>
                                                    <FormLabel fontWeight={600}>Land <small>*</small></FormLabel>
                                                    <SelectElement
                                                        menuPlacement="auto"
                                                        options={_countries}
                                                        defaultValue={find(_countries, {value: getOpenUser.Country.trim()})}
                                                        onChange={(e)=>setOpenUser(x=>{return {...x, Country: e.value}})}
                                                    />
                                                </FormControl>
                                            </VStack>
                                            {/* <br />
                                            <div className='modal-footer'>
                                                <button className="btn btn-outline-danger" onClick={(e)=>openUser(e, {})} type="button" disabled={getLoading}>Annuleren</button>
                                                <button className="btn btn-primary" disabled={isEqual(getOpenUser, getOpenUserCheck)||getLoading} type="submit" >
                                                    Opslaan
                                                </button>

                                            </div> */}
                                        </form>
                                    </Box>
                                </Center>
                            </>}
                        </Box>
					</ModalBody>
					<Divider />
					<ModalFooter>
                        {isEmpty(getOpenUser)&&<>
                            <Button onClick={()=>history.goBack()} disabled={getLoading}>Terug</Button>
                            <Spacer/>
                        </>}
                        {!isEmpty(getOpenUser)&&<>
                            <Button onClick={(e)=>openUser(e, {})} disabled={getLoading}>Terug</Button>
                            <Spacer />
                            <Button bg={"green.600"} color={"white"} disabled={isEqual(getOpenUser, getOpenUserCheck)||getLoading} type="submit" form='subber'>Opslaan</Button>
                        </>}
					</ModalFooter>
				</ModalContent>
			</Modal>
            {/* <Modal onClose={()=>setDeleteUser({})} isOpen={!isEmpty(getDeleteUser)} isCentered>
				<ModalOverlay
					bg='blackAlpha.300'
					backdropFilter='blur(10px)'
				/>
				<ModalContent borderRadius={"lg"}>
					<ModalHeader>Gebruiker verwijderen</ModalHeader>
					<ModalBody
						bg="white"
						_dark={{ bg: "gray.800" }}>
					<ModalCloseButton color={"white"} bg={"gray.700"} />
					<p>Weet je zeker dat je deze gebruiker wil verwijderen?</p>
					</ModalBody>
					<Divider />
					<ModalFooter>
						<Button onClick={()=>setDeleteUser({})}>Sluit</Button>
						<Spacer/>
						<Button disabled={getLoading} colorScheme={"red"} onClick={onDelete}>Verwijderen</Button>
					</ModalFooter>
				</ModalContent>
			</Modal> */}
        </>
    );
};
export default Page