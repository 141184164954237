import {
	Box,
	Flex,
	Heading,
	HStack,
	Icon,
	Image,
	Link,
	Skeleton,
	Stack,
	useColorModeValue,
  } from '@chakra-ui/react'
  import React from 'react'
  import { useHistory} from 'react-router-dom'
  import { FaArrowRight } from 'react-icons/fa'

 const App = ({data}) => {
	const history = useHistory();
	return (
	<Box maxW="7xl" mx="auto" px={{ base: '0', lg: '12' }} py={{ base: '0', lg: '12' }} bg={"white"} borderRadius={"lg"}>
	  <Stack direction={{ base: 'column-reverse', lg: 'row' }} spacing={{ base: '0', lg: '20' }}>
		<Box
		  width={{ lg: 'sm' }}
		  transform={{ base: 'translateY(-50%)', lg: 'none' }}
		  bg={{ base: useColorModeValue('red.50', 'gray.700'), lg: 'transparent' }}
		  mx={{ base: '6', md: '8', lg: '0' }}
		  px={{ base: '6', md: '8', lg: '0' }}
		  py={{ base: '6', md: '8', lg: '12' }}
		>
		  <Stack spacing={{ base: '8', lg: '10' }}>
			<Stack spacing={{ base: '2', lg: '4' }}>
			  <Heading size="lg" color={useColorModeValue('red.500', 'red.300')}>
				{data.title}
			  </Heading>
			  <Heading size="md" fontWeight="normal">
			  	{data.subTitle}
			  </Heading>
			</Stack>
			<HStack spacing="3">
			  <Link color={useColorModeValue('red.500', 'red.300')} fontWeight="bold" fontSize="lg" onClick={()=>history.push(`/blog/${data.id}`)}>
				Meer....
			  </Link>
			  <Icon color={useColorModeValue('red.500', 'red.300')} as={FaArrowRight} />
			</HStack>
		  </Stack>
		</Box>
		<Flex flex="1" overflow="hidden">
		  <Image
			src={data.coverImg}
			alt={data.title}
			fallback={<Skeleton />}
			maxH="450px"
			minH={{base:"65vh", md:"350px"}}
			minW="300px"
			objectFit="cover"
			flex="1"
			rounded={"md"}
		  />
		</Flex>
	  </Stack>
	</Box>
)}
export default App