import { Box, Button, chakra, Divider, Flex, HStack, Text, Input, Spacer, useColorModeValue as mode, VStack } from '@chakra-ui/react';
import { findIndex } from "lodash";
import { FaTrashAlt } from "react-icons/fa";

const Page = ({ data }) => {
	const { product, onSubtractButton, onAddButton, setOpenUserCheck, removingItem } = data;

	return (
		<>
			<Divider />
			<Flex
				bg="gray.50"
				_dark={{
					bg: "gray.700",
				}}
			>
				{/* <Center
					p={0}
					w={{sm: "80px", md: "125px", xl: "155px"}}
					h={{sm: "80px", md: "125px", xl: "155px"}}>
					<Image
						maxW={{sm: "65px", md: "95px", xl: "115px"}}
						maxH={{sm: "65px", md: "95px", xl: "115px"}}
						src={`https://apiv2.beantrack.app/images/${product.cover||"placeholder-image.png"}`} alt={product.ItemDescription}
					/>
				</Center> */}
				<Box flex='1' px={{sm: 2, md: 2}}>
					<VStack align={"left"} p={2}>
						<Box>
							<chakra.h1
								fontSize={{sm: "sm", md: "md", xl: "md"}}
								fontWeight="bold"
								color="gray.800"
								_dark={{
									color: "white",
								}}
							>
								{product.Naam||product.ItemDescription}
							</chakra.h1>

							<chakra.p
								mt={1}
								fontSize="sm"
								color="gray.600"
								_dark={{
									color: "gray.200",
								}}
							>
								{(!(product.unitTagger==="GEEN")&&product.unitTagger)||product.ExtraInfo}
							</chakra.p>
							<chakra.h1 color={mode("gray.600", "gray.200")} fontWeight="bold" fontSize="md" display={{ sm: 'block', md: 'none' }}>
								€ {product.NetPrice.toFixed(2)}
							</chakra.h1>
						</Box>
						<HStack pt={{sm: 0, md: 4}}>
							<chakra.h1 color={mode("gray.600", "gray.200")} fontWeight="bold" fontSize="lg" display={{ sm: 'none', md: 'block' }}>
								€ {product.NetPrice.toFixed(2)}
							</chakra.h1>
							<Spacer />
							<HookUsage />
						</HStack>
					</VStack>
				</Box>
			</Flex>
		</>
	);

	function HookUsage() {
		function debounce(func, timeout = 1500){
			let timer;
			return (...args) => {
			  clearTimeout(timer);
			  timer = setTimeout(() => { func.apply(this, args); }, timeout);
			};
		};

		const debounceInput = debounce((x) => handleInput(x));

		const handleInput = (x) => {
			if (x.target.value===0||x.target.value==="") { removingItem(product); return; }
			setOpenUserCheck(zz=>{
				const z = {...zz};
				const fI = findIndex(z.items, {Item: product.Item});
				z.items[fI].Quantity = Number(x.target.value)
				return {
					...z,
					items:[...z.items]
				}
			});
		};

		return (
			<HStack maxW='186px'>
				<Button me={2} borderRadius={"md"} size={"sm"} onClick={()=>removingItem(product)}><Text color={mode("red.500", "red.200")}><i className='fad fa-times' /></Text></Button>
				<Button borderRadius={"md"} size={"sm"} onClick={o=>onSubtractButton(o, product.Item)}><i className='fad fa-minus' /></Button>
				<Input textAlign={"center"} borderRadius={"lg"} size={"sm"} type={"number"} defaultValue={product.Quantity||""} onChange={debounceInput} bg={"white"} _dark={{bg: "gray.700", borderColor: "gray.100"}}/>
				<Button borderRadius={"md"} size={"sm"} onClick={o=>onAddButton(o, product.Item)}><i className='fad fa-plus' /></Button>
			</HStack>
		)
	}
};
export default Page