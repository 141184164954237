import {
	AlertDialog,
	AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter,
	AlertDialogHeader, AlertDialogOverlay, Button, useColorModeValue as mode
} from '@chakra-ui/react';
import React, { useState } from 'react';

const createPromise = () => {
    let resolver;
    return [ new Promise(( resolve, reject ) => {

        resolver = resolve
    }), resolver]
}

const useConfirm = () => {
  	const [ open, setOpen ] = useState(false);
  	const [ resolver, setResolver ] = useState({ resolver: null })
  	const [ label, setLabel ] = useState('')
  	const [ titel, setTitel ] = useState('')
	const cancelRef = React.useRef()

  	const getConfirmation = async ({titelText, text}) => {
  	    setLabel(text);
  	    setTitel(titelText);
  	    setOpen(true);
  	    const [ promise, resolve ] = await createPromise()
  	    setResolver({ resolve })
  	    return promise;
  	}

  	const onClick = async(status) => {
  	    setOpen(false);
  	    resolver.resolve(status)
  	}

  	const Confirmation = () => (
		<AlertDialog
			motionPreset='slideInBottom'
			leastDestructiveRef={cancelRef}
			onClick={ () => onClick(false) }
			isOpen={open}
			isCentered
		>
			<AlertDialogOverlay />
			<AlertDialogContent>
				<AlertDialogHeader>{titel}</AlertDialogHeader>
				<AlertDialogCloseButton />
				<AlertDialogBody>
					{label}
				</AlertDialogBody>
				<AlertDialogFooter>
				  	<Button ref={cancelRef} onClick={ () => onClick(false) }>
						Nee
				  	</Button>
				  	<Button color="white" bg={mode("green.700", "green.800")} ml={3} onClick={ () => onClick(true) }>
						Ja
				  	</Button>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
  	)

  	return [ getConfirmation, Confirmation ]

}

export default useConfirm;
