import { useLocation, useHistory } from 'react-router-dom';

function __timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function __useQuery() {
    return new URLSearchParams(useLocation().search);
}

function __useHistory() {
    const history = useHistory()
    return history;
}

export { __timeout, __useQuery, __useHistory }