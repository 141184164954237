import React, { useState, useEffect } from 'react';
import Navbar from "./components/Navbar";
import OrderListItem from './components/OrderListItem'
import { Box, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, IconButton, Flex, Icon, Image, chakra, HStack, Input, useNumberInput, Divider, Text, Spacer, Badge,Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	TableCaption,
	TableContainer,
	VStack, Link } from '@chakra-ui/react';
import { MdEmail, MdHeadset, MdLocationOn, MdScale } from "react-icons/md";
import { FaBalanceScale, FaEuroSign, FaTrashAlt } from "react-icons/fa";
import { __boccaApi } from "@bocca-app-repo/app-components/components/api";
import { _data } from "@bocca-app-repo/app-components";
import { uniqBy, orderBy, filter, isEmpty, round, sumBy } from "lodash";
import { useCart } from "react-use-cart";
import moment from 'moment';

const statusRet = (e) => {
	switch (e) {
		case 0:
			return <Badge color={"white"} rounded="md" bg="orange.600">Verwerken</Badge>;
		case 1:
			return <Badge color={"white"} rounded="md" bg="blue.600">Ingepland</Badge>;
		case 2:
			return <Badge color={"white"} rounded="md" bg="green.600">Verzonden</Badge>;
		case 400:
			return <Badge color={"white"} rounded="md" bg="red.600">Geannuleerd</Badge>;
		default:
			break;
	}
};

const Page = (props) => {
	// const userItems = _data.useMainStore(state => state.userItems);
	const account = _data.useMainStore(state => state.account);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [getSearch, setSearch] = useState("");
	const [getEenheid, setEenheid] = useState([]);
	const [getCatSelected, setCatSelected] = useState("");

	const [isOpenOrder, setIsOpenOrder] = useState(false);
	const [getOpenOrderData, setOpenOrderData] = useState({});
	const onCloseProduct = useDisclosure().onClose;

	const [getOrders, setOrders] = useState([]);
	const [getState, setState] = useState(false);
	const [loading, setLoading] = useState(false);

	/* const catsList = React.useMemo(() => {
        let prods = userItems;
		return [{ItemGroupDescription: "Alles"}, ...orderBy(uniqBy(prods, "ItemGroupDescription"), ['sortNr'], ['asc'])]
	}, [userItems]);


	const baseList = React.useMemo(() => {
        let prods = userItems;
		if (getCatSelected) prods = filter(prods, {ItemGroupDescription: getCatSelected})
		console.log(orderBy(prods, ['sortNr'], ['asc']));
		return orderBy(prods, ['sortNr'], ['asc'])
	}, [userItems, getCatSelected]);

	const userItemsList = React.useMemo(() => {
        let prods = baseList
        if (getSearch) {
            let searcjQery = getSearch.toLowerCase(),
            searcjQerySplit = getSearch.toLowerCase().split(" "),
            displayedContacts = prods.filter((el) => {
                let searchValue = el.Naam?.toLowerCase()||el.ItemDescription.toLowerCase();
                let searchValueCode = el.ExtraInfo||"";
                const ress1 =
                    ((searchValue.indexOf(searcjQerySplit[0]) !== -1) ||
                    (searchValueCode.indexOf(searcjQerySplit[0]) !== -1))
                const ress2 =
                    ((searchValue.indexOf(searcjQerySplit[1]) !== -1) ||
                    (searchValueCode.indexOf(searcjQerySplit[1]) !== -1))

                return searcjQerySplit[1]?(ress1&&ress2):(ress1);
            })
            prods = displayedContacts;
            console.log(searcjQerySplit);
        };
        return prods
    }, [baseList, getSearch]);

	const taggerList = React.useMemo(() => {
		setEenheid([])
        let prods = userItemsList;
		return uniqBy(prods, "unitTagger")
	}, [userItemsList]); */

	const openOrder = (data) => {
		setOpenOrderData(data);
		setIsOpenOrder(true);
	}
	const CloseProduct = () => {
		setOpenOrderData({});
		setIsOpenOrder(false);
	}
	const runInit = async () => {
		try {
			const ords = await __boccaApi.get('/orders?order.InvoiceTo='+account.ID+'&sort=-orderDate')
			console.log(ords.data);
			setOrders(ords.data)
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		runInit()
    }, []);

	return (
		<>
			<Navbar data={{ isOpen, onOpen, onClose, getSearch, setSearch, getEenheid, setEenheid, getCatSelected, setCatSelected }}/>
			<Box pt={"80px"} pb={"80px"} px={{sm: "2vw", md: "5vw", lg: "7vw"}}>
				{getOrders.map(order => <OrderListItem data={{ order, openOrder }} key={order.OrderID} />)}
			</Box>
			{!isEmpty(getOpenOrderData)&&<ProductPopup data={{isOpenOrder, CloseProduct, getOpenOrderData}}/>}
		</>
	);
};
export default Page

function ProductPopup({data}) {
	const {isOpenOrder, CloseProduct, getOpenOrderData} = data;

    const {
		isEmpty,
		totalUniqueItems,
        totalItems,
		items,
        metadata,
        emptyCart,
		addItem,
		inCart,
		getItem,
		updateItemQuantity,
		removeItem,
        updateCartMetadata
	} = useCart();

	return (
		<>
			<Modal onClose={CloseProduct} isOpen={isOpenOrder} isCentered size={"full"} >
				<ModalOverlay
					bg='blackAlpha.300'
					backdropFilter='blur(10px)'
				/>
				<ModalContent h={"100%"} bg="white" _dark={{ bg: "gray.800" }}>
					{/* <ModalHeader>Modal Title</ModalHeader> */}
					<ModalBody h={"100%"} m={0} p={0} overflowY={"auto"}>
						<ModalCloseButton />
						<Box
							w="100%"
							h="100%"
							mx="auto"
						>
							{/* <Flex alignItems="center" px={2} py={3} bg="green.800">
								<chakra.h1 mx={3} color="white" fontWeight="bold" fontSize="lg">
									{getOpenOrderData.Naam||getOpenOrderData.ItemDescription}
								</chakra.h1>
							</Flex> */}

								<Box py={4} px={{base: 4, md: 4}}>
									<chakra.p py={1} color="gray.700" _dark={{ color: "gray.100" }}>
										<Text as={"span"} fontWeight={600} fontSize={{base: "sm", md: "md"}}>Bestel datum:</Text> <span className="text-primary">{moment(getOpenOrderData.orderDate).format("D MMM YY")}</span>
									</chakra.p>
									<chakra.p py={1} color="gray.700" _dark={{ color: "gray.100" }}>
										<Text as={"span"} fontWeight={600} fontSize={{base: "sm", md: "md"}}>Bestelling nummer:</Text> <span className="text-primary">#{getOpenOrderData.Number?getOpenOrderData.Number:" NVT"}</span>
									</chakra.p>
									<chakra.p py={1} color="gray.700" _dark={{ color: "gray.100" }}>
										<Text as={"span"} fontWeight={600} fontSize={{base: "sm", md: "md"}}>Bezorgd aan:</Text> <span>{getOpenOrderData.name}</span>
									</chakra.p>
									<chakra.p py={1} color="gray.700" _dark={{ color: "gray.100" }}>
										<Text as={"span"} fontWeight={600} fontSize={{base: "sm", md: "md"}}>Status:</Text> {statusRet(getOpenOrderData.status)}
									</chakra.p>
									{getOpenOrderData.link &&<chakra.p py={2} color="white">
										<Button as={Link} href={getOpenOrderData.link} target="_blank"  bg="gray.500" _dark={{ bg: "gray.700" }}>Track{"&"}Trace</Button>
									</chakra.p> }
								</Box>
								<Divider/>
								<Box>
									<Table>
										<Thead>
											<Tr>
												<Th scope="col">Product</Th>
												<Th scope="col" textAlign='right'>Prijs</Th>
											</Tr>
										</Thead>
										<Tbody>
											{getOpenOrderData.order.SalesOrderLines.filter(e=>e.ItemDescription).map((e, i)=> <Tr key={"ord_o"+i}>
												<Td>
													<div>
														<h5 className="text-truncate font-size-14">
															{e.ItemDescription}
														</h5>
														<p className="text-muted mb-0">{e.Quantity} x € {e.NetPrice}</p>
													</div>
												</Td>
												<Td textAlign='right'>€ {round(e.NetPrice * e.Quantity, 2)}</Td>
											</Tr>)}
										</Tbody>
									</Table>
									<Table w={"300px"} ms={"auto"}>
										<Tbody>
											<Tr>
												<Td colSpan="1">
													<h6 className="m-0 text-end">Sub Totaal:</h6>
												</Td>
												<Td textAlign='right'>€ {round(sumBy(getOpenOrderData.order.SalesOrderLines.filter(e=>e.ItemDescription),x=> x.NetPrice*x.Quantity), 2)}</Td>
											</Tr>
											<Tr>
												<Td colSpan="1">
													<h6 className="m-0 text-end">Shipping:</h6>
												</Td>
												<Td textAlign='right'>€ {round(sumBy(getOpenOrderData.order.SalesOrderLines.filter(e=>!e.ItemDescription),x=> x.NetPrice*x.Quantity), 2)}</Td>
											</Tr>
											<Tr>
												<Td colSpan="1">
													<h6 className="m-0 text-end">Totaal:</h6>
												</Td>
												<Td textAlign='right'>€ {round(sumBy(getOpenOrderData.order.SalesOrderLines,x=> x.NetPrice*x.Quantity), 2)}</Td>
											</Tr>
										</Tbody>
									</Table>
								</Box>
								{/* {getOpenOrderData.ExtraInfo&&<chakra.h1
									fontSize="xl"
									fontWeight="bold"
									color="gray.800"
									_dark={{ color: "white" }}
								>
									{getOpenOrderData.ExtraInfo}
								</chakra.h1>}

								{getOpenOrderData.productInfo&&<chakra.p py={2} color="gray.700" _dark={{ color: "gray.400" }}>
									{getOpenOrderData.productInfo}
								</chakra.p>}
								{!(getOpenOrderData.unitTagger==="GEEN")&&<Flex
									alignItems="center"
									mt={4}
									color="gray.700"
									_dark={{ color: "gray.200" }}
								>
									<Icon as={FaBalanceScale} h={6} w={6} mr={2} />

									<chakra.h1 px={2} fontSize="md" fontWeight={600}>
										{getOpenOrderData.unitTagger}
									</chakra.h1>
								</Flex>}
								<Flex
									alignItems="center"
									mt={4}
									color="gray.700"
									_dark={{ color: "gray.200" }}
								>
									<Icon as={FaEuroSign} h={6} w={6} mr={2} />

									<chakra.h1 px={2} fontSize="lg" fontWeight={600}>
										{getOpenOrderData.NewPrice}
									</chakra.h1>
								</Flex> */}
						</Box>
					</ModalBody>
					<Divider />
					<ModalFooter bg="white" _dark={{ bg: "gray.800" }} m={0} p={3}>
						<Button onClick={CloseProduct}>Sluit</Button>
						{(getOpenOrderData.status===0)&&<Button me={4} bg={"red.600"} color="white" _hover={{ bg: "red.700" }}>Bestelling annuleren </Button>}
						<Spacer/>
						{(getOpenOrderData.status===2)&&<Button bg={"blue.600"} color="white" _hover={{ bg: "blue.700" }}>Herhaal bestelling</Button>}
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}