import React, {
	useLayoutEffect,
	useCallback,
	useRef,
	useState,
	useEffect
  } from "react";
  import { animated as a, useSpring } from "@react-spring/web";
import useWindowDimensions from 'helpers/screensize';


  const ScrollContainer = ({ children, scrollIntertia }) => {
	const { height } = useWindowDimensions();
	const [{ y }, set] = useSpring(() => ({
	  y: [0],
	  config: {
		mass: 0.5,
		tension: 500,
		friction: 50,
		precision: 1,
		velocity: 0,
		clamp: true
	  }
	}));

	const viewportRef = useRef(null);

	useEffect(() => {
	  const handleScroll = () => set({ y: [-window.pageYOffset] });
	  window.addEventListener("scroll", handleScroll);
	  return () => window.removeEventListener("scroll", handleScroll);
	}, [set]);

	return (
	  <>
		<a.div
		  style={{ transform: y.to(y => `translate3d(0,${y}px,0)`), height }}
		  ref={viewportRef}
		  className="scroll-container"
		>
		  {children}
		</a.div>
	  </>
	);
  };

  export default ScrollContainer;
