import React, { useEffect, useState } from 'react'
import {
	Button,
	Checkbox,
	FormControl,
	FormLabel,
	Box,
	Heading,
	Input,
	Text,
	HStack,
	Divider,
	Spacer,
	Flex,
	Center,
	VStack,
	Textarea,
	InputGroup,
	InputRightElement,
	useColorModeValue as mode,
} from '@chakra-ui/react';
import SelectBox from 'devextreme-react/select-box';
import { filter } from 'lodash';
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import { Dutch } from "flatpickr/dist/l10n/nl.js"
import moment from 'moment-business-days';
import AddressAdd from "helpers/Address";
import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from 'lodash';
import useWindowDimensions from "helpers/screensize";
import { useHistory } from 'react-router-dom';
import { SelectElement } from "@bocca-app-repo/app-components";

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
//TODO Get cutoff time from db
const deliveryCalc = () => {
	if (moment().day()===0 || moment().day()===6 || moment().isAfter(moment("10:00", "HH:mm"))) {
		return moment().nextBusinessDay()
	};
	return moment();
};

const deliveryCalcx2 = () => {
	if (moment().day()===0 || moment().day()===6 || moment().isAfter(moment("10:00", "HH:mm"))) {
		return moment().nextBusinessDay().format("YYYY-MM-DD")
	};
	return moment().format("YYYY-MM-DD");
};

function FormEle({ adresList, user, getpoNumber, updatePo, updateEmail, getTntEmail, account, placeOrder }) {
	const history = useHistory()
	const [getShippingFast, setShippingFast] = useState(true)
	const [getPoSaved, setPoSaved] = useState(false)
	const [getEmailSaved, setEmailSaved] = useState(false)
	const [getSelectedAddress, setSelectedAddress] = useState({})
	const [getShippingDate, setShippingDate] = useState(deliveryCalc);

	const [getFormData, setFormData] = useState({})
	const [getSteps, setSteps] = useState(0)
	const { height } = useWindowDimensions();

	const onSubmit = (e) =>{
		e.preventDefault()
		console.log(user);
		console.log(getSelectedAddress);
		const { AddressLine1, AddressLine2, City, Country, CountryName, Postcode, Type, ID } = getSelectedAddress;
		const orderID = uuidv4();
		const overOrder = {
			"name": account.Name.trim(),
			"contactName": getFormData.contactName,
			"contactEmail": getFormData.contactEmail,
			"contactPhone": getFormData.contactPhone,
			"address": { AddressLine1, AddressLine2, City, Country, CountryName, Postcode, Type, ID },
			"status": 0,
			"OrderID": orderID,
			"user": user.uuid,
			"orderDate": moment().toISOString(),
			"orderDate_F": moment().format("LLLL"),
			"order": {
				"OrderedBy": account.ID,
				"OrderID": orderID,
				"DeliverTo": account.ID,
				"DeliveryAddress": ID,
				"DeliveryDate": moment(getShippingDate).format("YYYY-MM-DD"),
				"Description": "b2bApp",
				"InvoiceTo": account.ID,
				"Remarks": getFormData.Remarks,
				"YourRef": getFormData.YourRef,
				"SalesOrderLines": [],
				"SelectionCode" : "3254cc82-0758-44ce-a107-e970b227d943"
			},
			"deliveryOption": {}
		}
		placeOrder(overOrder);
	}

	return (
		<>
			<form onSubmit={(e)=>{
				e.preventDefault();
				if (getSteps===0) {
					setFormData(x=>{return {
						...x,
						"contactName": document.getElementById("nameId").value.trim(),
						"contactEmail": document.getElementById("emailId").value.trim(),
						"contactPhone": document.getElementById("telnrId").value.trim(),
						"Remarks": document.getElementById("noteId").value.trim(),
						"YourRef": document.getElementById("poId").value.trim()
					}})
					setSteps(x=>x+1)
				}
			}}
				style={{width: "100%", height: "0px", margin: 0, padding: 0}}
				id={"subber"}
			></form>
			<Heading pt={4} px={{sm: "2vw", md: 0}} size="md" color={"gray.600"} display={{ sm: 'block', md: 'none' }}>Bestellen</Heading>
			<Divider mt={4} display={{ sm: 'block', md: 'none' }}/>
			<Box bg={"white"} _dark={{bg: "gray.700"}} h={{ base: (height-(182))+"px", md: "100%" }} w="full" overflowY={"auto"} sx={{
				'&::-webkit-scrollbar': {
					width: '16px',
					backgroundColor: `rgba(0, 0, 0, 0.10)`,
				},
				'&::-webkit-scrollbar-thumb': {
					borderRadius: '8px',
					backgroundColor: `rgba(0, 0, 0, 0.10)`,
				},
			}} px={{sm: "4vw", md: 5}} py={4} rounded={{ sm: 'none', md: 'lg' }}>
				<VStack spacing={4}>
					{getSteps===0&&<><Heading mb={4} size="md">{account.Name}</Heading>
					<FormControl>
						<FormLabel fontWeight={"semibold"}>Contactpersoon naam <small>*</small></FormLabel>
						<Input required defaultValue={user.name} placeholder="naam..." id='nameId' />
					</FormControl>
					<FormControl>
						<FormLabel fontWeight={"semibold"}>Contactpersoon telefoon nr.</FormLabel>
						<Input defaultValue={user.phone} type='tel' placeholder="06123..." id='telnrId' />
					</FormControl>
					<FormControl>
						<FormLabel fontWeight={"semibold"}>Email <small>*</small> <small>(voor Track&Trace berichten)</small></FormLabel>
						<InputGroup size='md'>
							<Input
								id='emailId'
								pr='4.5rem'
								required
								type="email"
								defaultValue={getTntEmail||user.email}
								placeholder="jou@email.nl..."
							/>
							<InputRightElement width='4.5rem'>
								<Button bg={(getEmailSaved&&"green.500")||"gray.100"} _dark={{ bg: (getEmailSaved&&"green.600")||"gray.700" }} h='1.75rem' size='sm' borderRadius="md" onClick={async ()=>{
									const res = await updateEmail(document.getElementById("emailId").value)
									if (res) {
										setEmailSaved(true);
										await timeout(3000);
										setEmailSaved(false);
									}
								}}>
									{getEmailSaved&&<i className="fad fa-check"></i>}
									{!getEmailSaved&&<i className='fal fa-save'></i>}
								</Button>
							</InputRightElement>
						</InputGroup>
					</FormControl>
					<FormControl>
						<FormLabel fontWeight={"semibold"}>Referentie <small>(BV. PO nummer, komt op de factuur)</small></FormLabel>
						<InputGroup size='md'>
							<Input
								id='poId'
								pr='4.5rem'
								placeholder="PO12345..."
								defaultValue={getpoNumber}
							/>
							<InputRightElement width='4.5rem'>
								<Button bg={(getPoSaved&&"green.500")||"gray.100"} _dark={{ bg: (getPoSaved&&"green.600")||"gray.700" }} h='1.75rem' size='sm' borderRadius="md" onClick={async ()=>{
									const res = await updatePo(document.getElementById("poId").value)
									if (res) {
										setPoSaved(true);
										await timeout(3000);
										setPoSaved(false);
									}
								}}>
									{getPoSaved&&<i className="fad fa-check"></i>}
									{!getPoSaved&&<i className='fal fa-save'></i>}
								</Button>
							</InputRightElement>
						</InputGroup>
					</FormControl>
					<FormControl>
						<FormLabel fontWeight={"semibold"}>Opmerkingen</FormLabel>
						<Textarea placeholder='Opmerking...' id='noteId' />
					</FormControl>
					</>}

					{getSteps===1&&<><FormControl>
						<FormLabel fontWeight={"semibold"}>Bezorgadres <small>*</small> <AddressAdd /></FormLabel>
						<SelectElement
							/* defaultValue={user.standardAddress||""} */
							menuPortalTarget={document.body}
							menuPosition={'fixed'}
							menuPlacement="auto"
							options={
								filter(adresList, x=>{ return x.Type===4&&x.AddressLine1&&x.Postcode }).map(z=>{
									return {
										...z,
										value: z.ID,
										label: z.AddressLine1+ ", " +z.Postcode||""
									}
								})
							}
							onChange={e=>setSelectedAddress(e)}
						/>
						{/* <SelectBox
							dataSource={filter(adresList, { "Type": 4 })}
							displayExpr="AddressLine1"
							valueExpr="ID"
							searchEnabled
							showDataBeforeSearch
							defaultValue={user.standardAddress||""}
							itemRender={data=><div className="custom-item">
								<div className="product-name">{data.AddressLine1}</div>
								<div className="product-name">{data.Postcode}</div>
							</div>}
							onSelectionChanged={e=>{setSelectedAddress(e.selectedItem);}}
						/> */}
					</FormControl>
					<FormControl>
						{getSelectedAddress&&<VStack ms={6} align={"left"} spacing={0}>
							<Text >
								{getSelectedAddress.AddressLine1&&getSelectedAddress.AddressLine1}
							</Text>
							<Text>
								{getSelectedAddress.Postcode&&getSelectedAddress.Postcode} {getSelectedAddress.City&&getSelectedAddress.City}
							</Text>
							<Text>
								{getSelectedAddress.CountryName&&getSelectedAddress.CountryName}
							</Text>
						</VStack>}
					</FormControl>
					{!getShippingFast&&<FormControl>
						<FormLabel fontWeight={"semibold"}>Verzending</FormLabel>
						<Checkbox defaultChecked={getShippingFast} onChange={(e)=>{console.log(e); setShippingFast(e.target.checked); if (e.target.checked) setShippingDate(deliveryCalc)}}>Zo snel mogelijk</Checkbox>
					</FormControl>}
					<FormControl>
						<FormLabel mt={4} fontWeight={"semibold"}>Verzenddatum</FormLabel>

						<Flatpickr
							className='flatData'
							render={
							({defaultValue, value, ...props}, ref) => {
								return <CustomInput defaultValue={defaultValue} inputRef={ref} />
							}
							}
							options={{
								allowInput: true,
								defaultDate: getShippingDate.toISOString(),
								minDate: deliveryCalcx2(),
								altInput: true,
								altFormat: "F j, Y",
								dateFormat: "Y-m-d",
								"disable": [
									function(date) {
										// return true to disable
										return (date.getDay() === 0 || date.getDay() === 6);
									}
								],
								"locale": Dutch,
								onChange: (selectedDates, dateStr, instance) => {
									console.log(dateStr);
									setShippingDate(moment(dateStr, "YYYY-MM-DD"));
								}
							}}
						/>
					</FormControl></>}


				</VStack>
			</Box>
			<Divider display={{ sm: 'block', md: 'none' }}/>
			{getSteps===0&&<Flex>
				<Box p='3'><Button onClick={()=>history.goBack()}>Terug</Button></Box>
				<Spacer />
				<Box p='3'>
					<Button
						type='submit'
						size="md"
						width="full"
						variant="primary"

						color="white"
						bg={mode("green.700", "green.800")}
						form={"subber"}
					>
						Volgende
					</Button>
				</Box>
			</Flex>}
			{getSteps===1&&<Flex>
				<Box p='3'>
					<Button
						size="md"
						width="full"
						variant="primary"
						bg="blackAlpha.200"
						_dark={{
							bg: "whiteAlpha.200"
						}}
						form={"subber"}
						onClick={x=>{setSteps(0);setSelectedAddress({})}}
					>
						Terug
					</Button>
				</Box>
				<Spacer />
				<Box p='3'>
					<Button
						disabled={isEmpty(getSelectedAddress)}
						type='submit'
						size="md"
						width="full"
						variant="primary"
						color="white"
						bg={mode("green.700", "green.800")}
						onClick={x=>onSubmit(x)}
					>
						Bestelling plaatsen
					</Button>
				</Box>
			</Flex>}
		</>
	);
};

const CustomInput = ({ value, defaultValue, inputRef, ...props }) => {
    return <Input {...props} defaultValue={defaultValue} ref={inputRef} required />;
};

export default function Details({ adresList, user, getpoNumber, updatePo, updateEmail, getTntEmail, account, placeOrder }) {
	return (
		<Center h='100%' w='100%'>

			<Box mx={{sm: 0, md: 4}}
				width="full"
				spacing="2"
			>
				<FormEle adresList={adresList} user={user} getpoNumber={getpoNumber} updatePo={updatePo} getTntEmail={getTntEmail} updateEmail={updateEmail} account={account} placeOrder={placeOrder} />
		</Box>
	  </Center>
	);
};