import React from 'react';
import PropTypes from 'prop-types';
import StartOnDate from './OnDate';
import {
	Box, FormLabel
} from '@chakra-ui/react';

import translateLabel from '../../utils/translateLabel';

const Start = ({
	id,
	start: {
		onDate,
	},
	handleChange,
	translations
}) => (
	<Box py={3}>
		<FormLabel fontWeight={600}>
			{translateLabel(translations, 'start.label')}
		</FormLabel>
		<StartOnDate id={id} onDate={onDate} handleChange={handleChange}translations={translations} />
	</Box>
);

Start.propTypes = {
	id: PropTypes.string.isRequired,
	start: PropTypes.shape({
		onDate: PropTypes.object.isRequired,
	}).isRequired,
	handleChange: PropTypes.func.isRequired,
	translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Start;
