
import {
	Avatar, Box, Button, Center, Flex, HStack, IconButton, Link, Menu, chakra, Icon,
	MenuButton, MenuDivider, MenuItem, MenuList, Stack, Text, useColorMode as mode, useColorModeValue, useDisclosure, VStack, Drawer ,DrawerOverlay, DrawerCloseButton, DrawerHeader, DrawerContent, DrawerBody, DrawerFooter, Input, Divider, Spacer
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { find, sumBy, round } from 'lodash';
import { useCart } from "react-use-cart";
import CartListItem from "./components/CartListItem";
import { simpShippingCheck } from "./components/cartCalc";
import { useHistory } from 'react-router-dom';
import { _auth, _data } from "@bocca-app-repo/app-components";

const Home = () => {
	const [getPosts, setPosts] = useState([]);

	return (
		<Box bg={mode('gray.50', 'inherit')} as="section" py="24" px={{sm: "2vw", md: "5vw", lg: "7vw"}}>

		</Box>
  	)
}

function DrawerExample() {
	const history = useHistory();
	const { isOpen, onOpen, onClose } = useDisclosure()
	const btnRef = React.useRef();
	const { userItems } = _data.useMainStore(state => state.account);
	const appSettings = _data.useMainStore(state => state.appSettings);
	const linkColor = useColorModeValue('gray.900', 'gray.100');
	const buttonbg = useColorModeValue('greenA.700', 'greenA.800');
	const buttonbgH = useColorModeValue('greenA.800', 'greenA.900');

    const {
		isEmpty,
		cartTotal,
		totalUniqueItems,
        totalItems,
		items,
        metadata,
        emptyCart,
		addItem,
		inCart,
		getItem,
		updateItemQuantity,
		removeItem,
        updateCartMetadata
	} = useCart();

	const cartList = React.useMemo(() => {
		const holder = []
		const holderRemoved = []
		console.log(items);
		console.log(userItems);
		for (let index = 0; index < items.length; index++) {
			const element = items[index];
			const found = find(userItems, {Item: element.Item})
			const tott = round(element.quantity*Number(found.NewPrice), 2)
			if (found) holder.push({...element, ...found, itemTotal: tott, itemTotalV: tott.toFixed(2)})
			if (!found) holderRemoved.push(element)
		}
		const total = sumBy(items, e=>{
			const found = find(userItems, {Item: e.Item})
			if (found) return e.quantity*Number(found.NewPrice)//NewPrice
			if (!found) return e.quantity*Number(e.NewPrice)
		})

		return {
			items: holder,
			shipping: simpShippingCheck(round(total, 2)),
			total: round(total, 2).toFixed(2),
			totalT: round(simpShippingCheck(round(total, 2))+total, 2).toFixed(2)
		}
	}, [items, userItems]);

	return (
	  <>
		<Button
			onClick={onOpen}
			color={linkColor}
			bg={buttonbg}
			_hover={{
				textDecoration: 'none',
				bg: buttonbgH,
			}}
		>
			<chakra.span pos="relative" display="inline-block">
				<Text color={linkColor}><i className="fad fa-shopping-cart"></i></Text>
				<chakra.span
				pos="absolute"
				top="-5px"
				right="30px"
				px={2}
				py={1}
				fontSize="xs"
				fontWeight="bold"
				lineHeight="none"
				color="red.50"
				transform="translate(50%,-50%)"
				bg="red.500"
				rounded="full"
				>
				{totalItems}
				</chakra.span>
			</chakra.span>
		</Button>
		<Drawer
			isOpen={isOpen}
			placement='right'
			onClose={onClose}
			finalFocusRef={btnRef}
			size="md"
		>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton />

				<DrawerHeader _dark={{bg:"gray.800"}}>Winkelwagen</DrawerHeader>

				<DrawerBody px={0} py={0} sx={{
					'&::-webkit-scrollbar': {
					width: '12px',
					borderRadius: '8px',
					backgroundColor: `rgba(0, 0, 0, 0.20)`,
					},
					'&::-webkit-scrollbar-thumb': {
					backgroundColor: `rgba(0, 0, 0, 0.20)`,
					},
				}}>
					{cartList.items.map(product => <CartListItem data={{ product }} key={product.Item} />)}
					{isEmpty&&<><Divider />
					<Flex
						bg="gray.50"
						_dark={{
							bg: "gray.700",
						}}
					>
						<Center
							p={0}
							w={"100%"}
							h={"100px"}
						>
							Winkelwagen is leeg.
						</Center>
					</Flex></>}
				</DrawerBody>

				<DrawerFooter p={0} _dark={{bg:"gray.800"}}>
					<VStack w={"100%"}>
						<Divider mt={0} pt={0} />
						<Box w={"100%"} pt={1} px={4} >
							{/* <HStack mb={1}>
								<Text fontSize={"sm"} fontWeight={600}>
									Punten:
								</Text>
								<Spacer />
								<Text fontSize={"xs"}>{round(appSettings.pointsPerEuro*(cartList.total), 0)}</Text>
							</HStack>
							<Divider /> */}
							{(Number(cartList.total)<215&&Number(cartList.total)>200)&&<><HStack mb={1}>
								<Text>
									Geef nog €{round(215-Number(cartList.total), 2).toFixed(2)} uit en bespaar €6 verzendkosten
								</Text>
							</HStack>
							<Divider /></>}
							<HStack>
								<Text fontWeight={600}>
									Verzendkosten:
								</Text>
								<Spacer />
								<Text align={"right"}>
									€ {cartList.shipping}
								</Text>
							</HStack>
							<Divider />
							<HStack>
								<Text fontWeight={600}>
									Totaal:
								</Text>
								<Spacer />
								<Text align={"right"}>
									€ {cartList.totalT}
								</Text>
							</HStack>
						</Box>
						<Divider />
						<Flex px={4} w={"100%"} pt={0} pb={2}>
							<Box my={"auto"}>
								<Button variant='outline' onClick={onClose}>
									Sluit
								</Button>
							</Box>
							<Spacer />
							<Box my={"auto"}>
								<Button color={"white"} bg={"green.700"} _dark={{bg: "green.800"}} onClick={()=>{onClose(); history.push('/checkout')}} disabled={isEmpty}>Afrekenen</Button>
							</Box>
						</Flex>
					</VStack>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	  </>
	)
}
export default DrawerExample;