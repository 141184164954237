export default {
  locale: 'nl-nl',
  invalid_rrule: "You provided an invalid RRule value to component. '%{value}' is not a correct RRule string.",
  months: {
    jan: 'Jan',
    feb: 'Feb',
    mar: 'Mar',
    apr: 'Apr',
    may: 'Mei',
    jun: 'Jun',
    jul: 'Jul',
    aug: 'Aug',
    sep: 'Sep',
    oct: 'Oct',
    nov: 'Nov',
    dec: 'Dec',
  },
  days_short: {
    mon: 'Ma',
    tue: 'Di',
    wed: 'Wo',
    thu: 'Do',
    fri: 'Vr',
    sat: 'Za',
    sun: 'Zo',
  },
  days: {
    monday: 'Maandag',
    tuesday: 'Dinsdag',
    wednesday: 'Woensdag',
    thursday: 'Donderdag',
    friday: 'Vrijdag',
    saturday: 'Zaterdag',
    sunday: 'Zondag',
    day: 'Dag',
    weekday: 'Weekdag',
    'weekend day': 'Weekend dag',
  },
  numerals: {
    first: '1st',
    second: '2de',
    third: '3de',
    fourth: '4de',
    last: 'Laatste',
  },
  start: {
    label: 'Begindatum',
    tooltip: 'Datetime picker for start on date',
  },
  repeat: {
    label: 'Frequentie',
    yearly: {
      label: 'Yearly',
      on: 'on',
      on_the: 'on the',
      of: 'of',
    },
    monthly: {
      label: 'Maandelijks',
      every: 'elke',
      months: 'maand(en)',
      on_day: 'Op dag',
      on_the: 'Op de',
    },
    weekly: {
      label: 'Weekijks',
      every: 'elke',
      weeks: 'week(en)',
    },
    daily: {
      label: 'Daily',
      every: 'elke',
      days: 'day(s)',
    },
    hourly: {
      label: 'Hourly',
      every: 'elke',
      hours: 'hour(s)',
    },
  },
  end: {
    label: 'Einddatum',
    tooltip: 'Datetime picker for end on date',
    never: 'Geen',
    after: 'Na',
    on_date: 'Op datum',
    executions: 'keer',
  },
}
