import React, { useState, useRef } from 'react';
import {
	Box,
	Flex,
	Avatar,
	HStack,
	Link,
	IconButton,
	Button,
	Text,
	MenuButton,
	MenuList,
	MenuItem,
	MenuDivider,
	useDisclosure,
	useColorModeValue,
	useColorMode,
	Stack, Divider, CheckboxGroup, Checkbox, chakra,
	Badge, Icon, InputGroup, InputLeftElement, Input, Spacer, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, FormLabel, InputLeftAddon, Select, DrawerFooter, TagLabel, Tag, TagCloseButton, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody
} from '@chakra-ui/react';

import { NavLink } from './NavLink'
import { HamburgerIcon, CloseIcon, AddIcon, MoonIcon, ChevronRightIcon, SearchIcon } from '@chakra-ui/icons';
import { FaFilter } from "react-icons/fa"
import { find, findIndex, isEmpty } from 'lodash'
import { NavLink as RNavLink, useHistory } from 'react-router-dom';


/* const NavLink = ({ children, to }) => (
	<Link
		as={RNavLink}
		px={2}
		py={1}
		rounded={'md'}
		_hover={{
			textDecoration: 'none',
			bg: useColorModeValue('gray.200', 'gray.700'),
		}}
		to={to}>
		{children}
	</Link>
); */

export default function WithAction(props) {
	const history = useHistory();
	const { isOpen, onOpen, onClose, getSearch, setSearch, getEenheid, setEenheid, catsList, getCatSelected, setCatSelected } = props.data;
	const { colorMode, toggleColorMode } = useColorMode();

	const checkboxP = (value, state) => {
		const found = getEenheid.findIndex((element) => element === value);
		console.log(value, state, found);
		if (!state && found > -1) setEenheid(e=>{const ee = [...e]; ee.splice(found, 1); return [...ee]});
		if (state && found > -1) return;
		if (state && found < 0) { setEenheid(e=>{const ee = [...e]; return [...ee, value]})};
	}

	const FilterButton = (props) => {
		return (
			<Button {...props}>
				<HStack>
					<Icon as={FaFilter} color={useColorModeValue('gray.700', 'gray.200')}/>
					{(getCatSelected||!isEmpty(getEenheid))&&<chakra.span
						pos="absolute"
						top="10px"
						right="10px"
						p="4px"
						fontSize="xs"
						fontWeight="bold"
						lineHeight="none"
						color="red.100"
						transform="translate(50%,-50%)"
						bg="red.600"
						rounded="full"
					/>}
					<Text>Filters</Text>
				</HStack>
			</Button>
		)
	}

	let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);

  //Slide click
  const slide = (shift) => {
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift);

    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };
  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };/* ${(document.getElementById("searchfilli").value&&`${e&&"&"}find=${document.getElementById("searchfilli").value}`)||""} */
	return (
		<HStack spacing={0} bg={useColorModeValue('gray.300', 'gray.750')} className="sub-header" as="header" position="fixed" w="100%" px={{sm: "2vw", md: "5vw", lg: "7vw"}} >
			<BasicUsage data={{getSearch, setSearch}}/>
			{scrollX !== 0 && (
				<Box variant={"gost"} p={2} m={0}
				onClick={() => slide(-100)}
				>
				<i className="fad fa-angle-left"></i>
				</Box>
			)};
			<HStack w="100%" overflowX={"auto"} ref={scrl} h={16} onScroll={scrollCheck}>
				{/* <FilterButton onClick={onOpen} bg={useColorModeValue('gray.200', 'gray.600')} display={{ sm: 'none', md: 'block' }} /> */}
				{catsList.map((e, i)=>
					<Box key={"zs"+i}><NavLink
						label={e.productCatagory}
						icon={ChevronRightIcon}
						isActive={(!getCatSelected&&i===0&&'solid')||(getCatSelected===e.productCatagory)}
						onClick={()=>{
							setCatSelected(e.productCatagory==="Alles"?"":e.productCatagory);
						}}
					/></Box>
				)}
				{/* <Box w="100%">
					<Flex h={16} alignItems={'center'} justifyContent={'space-between'} direction>
						<Text fontSize={"lg"} fontWeight={600} display={{ sm: 'block', md: 'none' }}>Shop</Text>
						<HStack spacing={4} alignItems={'center'} overflowX={"auto"}  ref={scrl} onScroll={scrollCheck} w="100%">
							{scrollX !== 0 && (
								<button
								className="prev"
								onClick={() => slide(-50)}
								>
								<i className="fa fa-angle-left"></i>
								</button>
							)}
							<HStack ref={scrl} onScroll={scrollCheck} w="100%">
								{catsList.map((e, i) => (
								<NavLink
								key={"zs"+i}
								label={e.productCatagory}
								icon={ChevronRightIcon}
								isActive={(!getCatSelected&&i===0&&'solid')||(getCatSelected===e.productCatagory)}
								onClick={()=>{
									setCatSelected(e.productCatagory==="Alles"?"":e.productCatagory);
								}}
							/>
								))}
							{!scrolEnd && (
								<button
								className="next"
								onClick={() => slide(+50)}
								>
								<i className="fa fa-angle-right"></i>
								</button>
							)}</HStack>
							<HStack
								as={'nav'}
								spacing={4}
								//display={{ base: 'none', md: 'flex' }}
							>
							</HStack>
							<HStack spacing={4}>
								{getCatSelected&&<Tag
									size="md"
									borderRadius='full'
									variant='solid'
									colorScheme='green'
									onClick={()=>setCatSelected("")}
								>
									<TagLabel>{getCatSelected}</TagLabel>
									<TagCloseButton />
								</Tag>}
								{getEenheid.length>0&&getEenheid.map(x=><Tag
									key={"x"}
									size="md"
									borderRadius='full'
									variant='solid'
									bg='green.800'
									onClick={()=>checkboxP(x, !getEenheid.includes(x))}
								>
									<TagLabel>{x}</TagLabel>
									<TagCloseButton />
								</Tag>)}
							</HStack>
						</HStack>
						<Flex alignItems={'center'}>
							<Stack direction={'row'} spacing={7}>
								<HStack spacing={3} alignItems="center">
									<InputGroup
										display={{ sm: 'none', md: 'block' }}
										ml="auto"
										backgroundColor={useColorModeValue('gray.200', 'gray.600')}
										borderRadius={"md"}
									>
										<InputLeftElement pointerEvents="none" >
											<SearchIcon />
										</InputLeftElement>
										<Input type="text" placeholder="Zoeken..." _placeholder={{color: useColorModeValue('gray.600', 'gray.400')}} value={getSearch} onChange={e=>{setSearch(e.target.value);}}/>
									</InputGroup>
									<FilterButton onClick={onOpen} bg={useColorModeValue('gray.200', 'gray.600')} display={{ sm: 'block', md: 'none' }} />
								</HStack>
							</Stack>
						</Flex>
					</Flex>

					{isOpen ? (
						<Box pb={4} display={{ md: 'none' }}>
							<Stack as={'nav'} spacing={4}>
								{Links.map(({name, href}) => (
									<NavLink key={name} to={href}>{name}</NavLink>
								))}
							</Stack>
						</Box>
					) : null}
				</Box> */}
				<DrawerExample data={{ isOpen, onOpen, onClose, getSearch, setSearch, getEenheid, setEenheid, catsList, getCatSelected, setCatSelected }} />
			</HStack>
			<Spacer/>
			{!scrolEnd && (
				<Box p={2} m={0}
				onClick={() => slide(+50)}
				>
				<i className="fad fa-angle-right"></i>
				</Box>
			)};
		</HStack>
	);
}

function DrawerExample({data}) {
	const { isOpen, onOpen, onClose, getSearch, setSearch, getEenheid, setEenheid, catsList, getCatSelected, setCatSelected } = data

	const checkboxP = (value, state) => {
		const found = getEenheid.findIndex((element) => element === value);
		console.log(value, state, found);
		if (!state && found > -1) setEenheid(e=>{const ee = [...e]; ee.splice(found, 1); return [...ee]});
		if (state && found > -1) return;
		if (state && found < 0) { setEenheid(e=>{const ee = [...e]; return [...ee, value]})};
	}
	const inEditCheck = (field) => {
        return find(getEenheid, field)?true:false
    }
	return (
	  <>
		<Drawer
		  isOpen={isOpen}
		  placement='left'
		  onClose={onClose}
		>
		  <DrawerOverlay />
		  <DrawerContent>
			<DrawerCloseButton />
			<DrawerHeader borderBottomWidth='1px'>
			  Filters
			</DrawerHeader>

			<DrawerBody>
			  <Stack spacing='24px'>
				<Box>
				  	<InputGroup
				 		mt={4}
						ml="auto"
						borderRadius={"md"}
					>
						<InputLeftElement pointerEvents="none" >
							<SearchIcon />
						</InputLeftElement>
				  <Input
					id='username'
					placeholder='Zoeken...'
					value={getSearch} onChange={e=>{setSearch(e.target.value);}}
				  />
				  </InputGroup>
				</Box>
				<Divider />
				<Box>
				  	<FormLabel fontWeight={"semibold"}>Categorieën</FormLabel>
					<Stack spacing={0}>
          				{catsList.map((e, i)=>
						  	<NavLink
							  	key={"zs"+i}
								label={e.productCatagory}
								icon={ChevronRightIcon}
								isActive={(!getCatSelected&&i===0&&'solid')||(getCatSelected===e.productCatagory)}
								onClick={()=>{setCatSelected(e.productCatagory==="Alles"?"":e.productCatagory);}}
							/>
						)}
        			</Stack>
					{/* <VStack spacing={2} align='start'>
						{catsList.map((e, i)=>
							<Button w={"100%"}  colorScheme='green' variant={(!getCatSelected&&i===0&&'solid')||(getCatSelected===e.productCatagory?'solid':"outline")} borderRadius={"lg"} onClick={()=>{setCatSelected(e.productCatagory==="Alles"?"":e.productCatagory);}}>
								{e.productCatagory}
							</Button>
						)}
					</VStack> */}
				</Box>
				{/* <Divider />
				<Box>
				  <FormLabel fontWeight={"semibold"}>Verpakking eenheid</FormLabel>
				  <InputGroup>
					<VStack spacing={2}>
						<Box>
							{taggerList.map(e=>
								<Flex alignItems="start" mb={4} key={e.productUnit}>
									<Flex alignItems="center">
										<Badge
											cursor={"pointer"}
											w={"100%"}
											py={1} px={2}
											bg={getEenheid.includes(e.productUnit)?"green.400":"gray.100"}
											shadow={getEenheid.includes(e.productUnit)?"inner":"md"}
											onClick={x=>{
												console.log(getEenheid.includes(e.productUnit));
												checkboxP(e.productUnit, !getEenheid.includes(e.productUnit))
											}}
										>
											<Text as={"span"} color={getEenheid.includes(e.productUnit)?"green.900":"gray.300"}><i className={`fad fa-${getEenheid.includes(e.productUnit)?"check-square":"square"}`} /></Text> <Text as={"span"} color={getEenheid.includes(e.productUnit)?"white":"gray.800"}>{e.productUnit}</Text></Badge>
									</Flex>

								</Flex>
							)}
						</Box>
					</VStack>
				  </InputGroup>
				</Box> */}

				{/* <Box>
				  <FormLabel htmlFor='owner'>Select Owner</FormLabel>
				  <Select id='owner' defaultValue='segun'>
					<option value='segun'>Segun Adebayo</option>
					<option value='kola'>Kola Tioluwani</option>
				  </Select>
				</Box> */}
			  </Stack>
			</DrawerBody>

			<DrawerFooter borderTopWidth='1px'>
				<Button variant='outline' mr={3} onClick={onClose}>
					Sluit
				</Button>
			</DrawerFooter>
		  </DrawerContent>
		</Drawer>
	  </>
	)
}

function BasicUsage({data}) {
	const { getSearch, setSearch } = data;
	const { isOpen, onOpen, onClose } = useDisclosure()
	return (
	  <>
		<Button variant={"gost"}><SearchIcon onClick={onOpen} me={2} /> {getSearch}{getSearch&&<Button onClick={()=>setSearch("")} variant={"gost"}><Text me={2} color={"red.500"} ><i className='fad fa-times'/></Text></Button>}</Button>

		<Modal isOpen={isOpen} onClose={onClose}>
		  <ModalOverlay />
		  <ModalContent mx={5} maxW={{base: "auto", md: "sm"}}>
			<ModalBody m={0} p={0}>
				<InputGroup
					w={"full"}
					ml="auto"
					backgroundColor={useColorModeValue('gray.50', 'gray.600')}
					borderRadius={"md"}
				>
					<InputLeftElement pointerEvents="none" >
						<SearchIcon />
					</InputLeftElement>
					<Input type="text" placeholder="Zoeken..." _placeholder={{color: useColorModeValue('gray.600', 'gray.400')}} value={getSearch} onChange={e=>{setSearch(e.target.value);}}/>
				</InputGroup>
			</ModalBody>
		  </ModalContent>
		</Modal>
	  </>
	)
}