import React, { useState, useEffect } from 'react';
import { CloseIcon, HamburgerIcon, MoonIcon, StarIcon } from '@chakra-ui/icons';
import {
	Avatar, Box, Button, Center, Flex, HStack, chakra, Link, Menu, Spacer, Image,
	MenuButton, Divider, MenuItem, MenuList, Stack, Text, useColorMode, useColorModeValue as mode, useDisclosure, VStack, useNumberInput, Input, IconButton,
	Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverArrow, PopoverCloseButton, PopoverBody, PopoverFooter, ButtonGroup
} from '@chakra-ui/react';
import { FaTrashAlt } from "react-icons/fa";
import { useCart } from "react-use-cart";

const Page = (props) => {
	const { product } = props.data
	const [getData, setData] = useState({});
	const [loading, setLoading] = useState(false);
	const { isOpen, onOpen, onClose } = useDisclosure()

    const {
		isEmpty,
		totalUniqueItems,
        totalItems,
		items,
        metadata,
        emptyCart,
		addItem,
		inCart,
		getItem,
		updateItemQuantity,
		removeItem,
        updateCartMetadata
	} = useCart();

	useEffect(() => {
		setData({...product, quantity: 0, id:product.Item, price: product.NewPrice.toFixed(2)})
		return () => {};
	}, [product]);

	return (
		<>
			<Divider />
			<Flex
				bg="gray.25"
				_dark={{
					bg: "gray.700",
				}}>
				<Center
					p={0}
					w={{sm: "130px", md: "155px", xl: "155px"}}
					h={{sm: "130px", md: "155px", xl: "155px"}}>
					<Image
						maxW={{sm: "105px", md: "115px", xl: "115px"}}
						maxH={{sm: "105px", md: "115px", xl: "115px"}}
						src={`https://apiv2.beantrack.app/images/${product.productImage||"placeholder-image.png"}`} alt={product.ItemDescription}
					/>
				</Center>
				<Box flex='1'>
					<VStack align={"left"} p={2}>
						<Box>
							<chakra.h1
								fontSize={{sm: "sm", md: "md", xl: "md"}}
								fontWeight="bold"
								color={mode("gray.800", "white")}
							>
								{(product.productParent&&product.productParent)||product.productName}
							</chakra.h1>

							<chakra.p
								mt={1}
								fontSize="sm"
								color={mode("gray.600", "gray.200")}
							>
								{product.productUnit&&product.productUnit}{product.productUnit&&product.productVariant&&", "} {product.productVariant&&product.productVariant}
							</chakra.p>
							<chakra.h1 color={mode("gray.600", "gray.200")} fontWeight="bold" fontSize="md" display={{ sm: 'block', md: 'none' }}>
								€ {product.itemTotalV}
							</chakra.h1>
						</Box>
						<HStack pt={{sm: 0, md: 9}}>
							<chakra.h1 color={mode("gray.600", "gray.200")} fontWeight="bold" fontSize="lg" display={{ sm: 'none', md: 'block' }}>
								€ {product.itemTotalV}
							</chakra.h1>
							<Spacer />
							<HookUsage />
						</HStack>
					</VStack>
				</Box>
			</Flex>

		</>
	);

	function HookUsage() {
		const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
		  useNumberInput({
			step: 1,
			defaultValue: "",
			min: 0,
			max: 10000,
			precision: 0,
		  })
		  const pq = (inCart(product.Item)&&getItem(product.Item).quantity)||0

		const inc = getIncrementButtonProps()
		const dec = getDecrementButtonProps()
		const input = getInputProps()
		const handlePlus = () => {
			if (pq===0) { addItem(getData, 1); return; }
			updateItemQuantity(product.Item, pq+1);
		};
		const handleMinus = () => {
				if (pq-1===0) { removeItem(product.Item); return; }
				updateItemQuantity(product.Item, pq-1)
		};
		function debounce(func, timeout = 1500){
			let timer;
			return (...args) => {
			  clearTimeout(timer);
			  timer = setTimeout(() => { func.apply(this, args); }, timeout);
			};
		}

		const debounceInput = debounce((x) => handleInput(x));
		const handleInput = (x) => {
			if (x.target.value===0||x.target.value==="") { removeItem(product.Item); return; }
			if (pq===0) { addItem(getData, Number(x.target.value)); return; }
			updateItemQuantity(product.Item, Number(x.target.value));
		};
		return (
		  <HStack maxW='186px'>

			<DelP><Button me={2} borderRadius={"md"} size={"sm"} disabled={!inCart(product.Item)} onClick={onOpen}><Text color={mode("red.500", "red.200")}><i className='fad fa-times' /></Text></Button></DelP >
			<Button borderRadius={"md"} size={"sm"} disabled={!inCart(product.Item)} onClick={handleMinus}><i className='fad fa-minus' /></Button>
			<Input textAlign={"center"} borderRadius={"lg"} size={"sm"} type={"number"} defaultValue={pq||""} onChange={debounceInput} bg={"white"} _dark={{bg: "gray.700"}}/>
			<Button borderRadius={"md"} size={"sm"} onClick={handlePlus}><i className='fad fa-plus' /></Button>
		  </HStack>
		)
	}
	function DelP({children}) {
		const cancelRef = React.useRef()

		return (
			<>
			<Popover
				placement='auto'
				closeOnBlur={true}

				onClose={onClose}
				isOpen={isOpen}
			>
				<PopoverTrigger>
					{children}
				</PopoverTrigger>
				<PopoverContent borderColor='red.800'>
					<PopoverHeader pt={4} fontWeight='bold' border='0'>
						Uit winkelwagen halen?
					</PopoverHeader>
					<PopoverArrow />
					<PopoverCloseButton />
					<PopoverBody>
						<chakra.h1
							fontSize={{sm: "sm", md: "md", xl: "md"}}
							fontWeight="bold"
							color={mode("gray.800", "white")}
						>
							{(product.productParent&&product.productParent)||product.productName}
						</chakra.h1>

						<chakra.p
							mt={1}
							fontSize="sm"
							color={mode("gray.600", "gray.200")}
						>
							{product.productUnit&&product.productUnit}{product.productUnit&&product.productVariant&&", "} {product.productVariant&&product.productVariant}
						</chakra.p>
					</PopoverBody>
					<PopoverFooter
						border='0'
						display='flex'
						alignItems='center'
						justifyContent='space-between'
						pb={4}
					>
						<Box fontSize='sm'></Box>
						<ButtonGroup size='sm'>
							<Button ref={cancelRef} onClick={onClose}>
								Nee
							</Button>
							<Button colorScheme='red' ml={3} onClick={()=>removeItem(product.Item)}>
								Ja
							</Button>
						</ButtonGroup>
					</PopoverFooter>
				</PopoverContent>
			</Popover>
			</>
		)
	}
};


export default Page