import { __B2BAPI } from '../../api';
import { isEmpty } from "lodash";

export const getUser = async (ID) => {
    const errnf = "Gebruiker niet gevonden";
    const res = await __B2BAPI.get(`/auth?uuid=${ID}`);
    if (isEmpty(res)) throw errnf;
    return res.data[0]
}

export const updateUser = async (ID, data) => {
    const res = await __B2BAPI.post('/auth/update', [{
        "updateOne": {
            "filter": {"uuid": ID},
            "update": data
        }
    }])
    return res.data;
}

export const upsertUser = async (ID, data) => {
    const res = await __B2BAPI.post('/auth/update', [{
        "updateOne": {
            "filter": {"uuid": ID},
            "update": data,
            "upsert": true
        }
    }])
    return res.data;
}

export const removeUser = async (ID) => {
    const res = await __B2BAPI.post('/auth/update', [{
        "deleteOne": {
            "filter": {"uuid": ID}
        }
    }])
    return res.data;
}

export const getUsers = async (from) => {
    const res = await __B2BAPI.get(`/auth?from=${from}&fields=-password`);
    return res.data;
}