import React from 'react';
import {
	Badge, Box, Button, Checkbox, Divider, Flex, FormControl, FormLabel, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spacer, Text, VStack, useColorModeValue as mode, Select, InputGroup, InputLeftElement, InputRightElement
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { toPairs } from 'lodash';
import numericalFieldHandler from '../../../utils/numericalFieldHandler';
import translateLabel from '../../../utils/translateLabel';

const RepeatWeekly = ({
	id,
	weekly: {
		interval,
		days,
		options,
	},
	handleChange,
	translations
}) => {
	let daysArray = toPairs(days);
	if (options.weekStartsOnSunday) {
		daysArray = daysArray.slice(-1).concat(daysArray.slice(0, -1));
	}

	return (
		<Box mt={4}>
			<HStack>
				<Text>{translateLabel(translations, 'repeat.weekly.every')}</Text>
					<Input
						id={`${id}-interval`}
						name="repeat.weekly.interval"
						aria-label="Repeat weekly interval"
						className="form-control"
						value={interval}
						onChange={numericalFieldHandler(handleChange)}
					/>
					<Text>{translateLabel(translations, 'repeat.weekly.weeks')}</Text>
			</HStack>

			<Box mt={4}>
				{daysArray.map(([dayName, isDayActive]) => (
						<Checkbox
							ms={4}
							key={dayName}
							type="checkbox"
							id={`${id}-${dayName}`}
							name={`repeat.weekly.days[${dayName}]`}
							className="form-control"
							isChecked={isDayActive}
							onChange={(event) => {
								const editedEvent = {
									...event,
									target: {
										...event.target,
										value: !isDayActive,
										name: event.target.name,
									},
								};

								handleChange(editedEvent);
							}}
						>
							{translateLabel(translations, `days_short.${dayName.toLowerCase()}`)}
						</Checkbox>
					))
				}
			</Box>
		</Box>
	);
};

RepeatWeekly.propTypes = {
	id: PropTypes.string.isRequired,
	weekly: PropTypes.shape({
		interval: PropTypes.number.isRequired,
		days: PropTypes.shape({
			mon: PropTypes.bool.isRequired,
			tue: PropTypes.bool.isRequired,
			wed: PropTypes.bool.isRequired,
			thu: PropTypes.bool.isRequired,
			fri: PropTypes.bool.isRequired,
			sat: PropTypes.bool.isRequired,
			sun: PropTypes.bool.isRequired,
		}).isRequired,
		options: PropTypes.shape({
			weekStartsOnSunday: PropTypes.bool,
		}).isRequired,
	}).isRequired,
	handleChange: PropTypes.func.isRequired,
	translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default RepeatWeekly;
