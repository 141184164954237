import { useColorModeValue as mode } from '@chakra-ui/react';
import React from 'react';
import Select from 'react-select';

const Page = (props) => {
	const selectBg = mode("white",'#505251')
	const selectBg22 = mode("#505251",'white')
	const optCol = mode("#505251",'white')
	const optBg = mode("#d8d9d9",'#333434')
	const optBorder = mode("1px dotted #d8d9d9",'1px dotted white')

	const customStyles = {
		option: (provided, state) => ({
		  ...provided,
		  borderBottom: optBorder,
		  color: state.isSelected ? 'white' : optCol,
		}),
		singleValue: (provided, state) => {
		  const opacity = state.isDisabled ? 0.5 : 1;
		  const transition = 'opacity 300ms';

		  return { ...provided, opacity, transition,
			color: selectBg22 };
		}
	};


	const theme = (theme) => ({
		...theme,
		borderRadius: 5,
		colors: {
			...theme.colors,
			primary25: optBg,
			primary50: '#505251',
			primary: '#505251',
			neutral0: selectBg,
			neutral180: '#d9682c',
		},
	});

	return (
		<Select {...props} theme={theme}
			styles={customStyles}
		/>
	)
};

export default Page;