import { Box, Center, chakra, Divider, Flex, Image, Spacer, useColorModeValue as mode } from '@chakra-ui/react';

const Page = (props) => {
	const { product } = props.data;

	return (
		<>
			<Divider />
			<Flex w={"100%"} p={2}
				bg="gray.50"
				_dark={{
					bg: "gray.700",
				}}>
				<Center
					p={0}
					w={{base: "55px", md: "65px"}}
					h={{base: "55px", md: "65px"}}>
					<Image
						maxW={{base: "50px", md: "60px"}}
						maxH={{base: "50px", md: "60px"}}
						src={`https://apiv2.beantrack.app/images/${product.productImage||"placeholder-image.png"}`} alt={product.ItemDescription}
					/>
				</Center>
				<Box flex='1'>
					<Flex>
						<Box>
							<chakra.h1
								fontSize={{sm: "sm", md: "md", xl: "md"}}
								fontWeight="bold"
								color={mode("gray.800", "white")}
							>
								{(product.productParent&&product.productParent)||product.productName}
							</chakra.h1>

							<chakra.p
								mt={1}
								fontSize="sm"
								color={mode("gray.600", "gray.200")}
							>
								{product.productUnit&&product.productUnit}{product.productUnit&&product.productVariant&&", "} {product.productVariant&&product.productVariant}
							</chakra.p>
						</Box>
						<Spacer />
						<Box minW={"65px"} textAlign={"right"}>
							<chakra.h1 color={mode("gray.600", "gray.200")} fontWeight="bold" fontSize={{sm: "md", md: "lg"}}>
								{product.quantity} st. <br />
								€ {product.itemTotalV}
							</chakra.h1>
						</Box>
					</Flex>
				</Box>
			</Flex>
		</>
	);
};
export default Page