import React, { useState, useEffect } from 'react';
import { CloseIcon, HamburgerIcon, MoonIcon, StarIcon } from '@chakra-ui/icons';
import {
	Avatar, Box, Button, Center, Flex, HStack, chakra, Link, Menu, Spacer, Image, IconButton,
	MenuButton, MenuDivider, MenuItem, MenuList, Stack, Text, useColorMode, useColorModeValue as mode, useDisclosure, VStack, useNumberInput, Input,
	LinkBox, LinkOverlay, Collapse
} from '@chakra-ui/react';
import { useCart } from "react-use-cart";
import { FaTrashAlt } from "react-icons/fa";
import { SelectElement, _data } from "@bocca-app-repo/app-components";
import { uniqBy, round } from 'lodash';

const Page = (props) => {
	const appSettings = _data.useMainStore(state => state.appSettings);
	const { product, openProduct, CloseProduct, isOpenProduct } = props.data
	const [getData, setData] = useState(product?.items[0]||product);
	const [loading, setLoading] = useState(false);
	const productUnitK = uniqBy(product.items.filter(x=>x.productUnit!==""), 'productUnit').map(x=>{return x.productUnit}).toString();
	const productVariantK = uniqBy(product.items.filter(x=>x.productVariant!==""), 'productVariant').map(x=>{return x.productVariant}).toString();


    const {
		isEmpty,
		totalUniqueItems,
        totalItems,
		items,
        metadata,
        emptyCart,
		addItem,
		inCart,
		getItem,
		updateItemQuantity,
		removeItem,
        updateCartMetadata
	} = useCart();

	/* useEffect(() => {
		setData({...product, quantity: 0, id:product.Item, price: product.NewPrice.toFixed(2)})
		return () => {};
	}, [product]); */
	const arrayStringer = (val1,val2)=>{
		const arr = [
			...(val1 ? [val1] : []),
			...(val2 ? [val2] : []),
		];
		let str = "";
		for (let i = 0; i < arr.length; i++) {
			const x = arr[i];
			str = str+x+(!(i+1===arr.length)?", ":"");
		}
		return str
	}

	return (
		<Box h={"100%"} mb={4} id={product.keyer}>
			{/* <Flex
				bg="#edf3f8"
				_dark={{
					bg: "#3e3e3e",
				}}
				p={50}
				w="full"
				alignItems="center"
				justifyContent="center"
			> */}
				<LinkBox
					as={Flex}
					w="100%"
					bg="white"
					_dark={{
						bg: "gray.700",
					}}
					shadow="md"
					_hover={{shadow:"lg"}}
					//rounded="md"
					overflow="hidden"
					//mb={4}
					cursor={"pointer"}

				>
					<Center
						display={{ base: 'none', md: 'block' }}
						cursor={"pointer"} px={{base:4, md:4}} py={{base:3, md:4}}
						onClick={e=>{console.log(e);openProduct(product)}}
						mx="auto"
						my="auto"
						w={{base: "135px", md: "175px", xl: "215px"}}
						h={{base: "135px", md: "175px", xl: "215px"}}
					>
						<Image
							maxW={{base: "120px", md: "145px", xl: "185px"}}
							maxH={{base: "120px", md: "145px", xl: "185px"}}
							mx="auto"
							my="auto"
							src={`https://apiv2.beantrack.app/images/${getData.productImage||"placeholder-image.png"}`} alt={getData.productName}
						/>
					</Center>
					<Box flex='1'>
						<Box align={"left"} px={{base:4, md:4}} py={{base:3, md:4}} h={"90%"}>
							<Box h={"90%"}
							>
								<chakra.h1
									fontSize={{base: "md", md: "md", xl: "md"}}
									fontWeight="bold"
									color="gray.800"
									_dark={{
										color: "white",
									}}
								>
									<LinkOverlay href='#' as='a' onClick={e=>{e.preventDefault();openProduct(product.keyer)}}>{product.productName}</LinkOverlay>
									<chakra.span href='#' as='a' onClick={e=>{e.preventDefault();console.log(product);}} ms={2} fontSize={"lg"}><i className="fad fa-info-circle"></i></chakra.span>
								</chakra.h1>

								{product.productExtraInfo&&<chakra.p
									mt={2}
									fontSize="sm"
									color="gray.600"
									_dark={{
										color: "gray.200",
									}}
								>
									{product.productExtraInfo}
								</chakra.p>}
								{(productUnitK||productVariantK)&&<chakra.p
									mt={2}
									fontSize="sm"
									color="gray.600"
									_dark={{
										color: "gray.200",
									}}
								>
									Keuze uit: {productUnitK} {productUnitK&&productVariantK&&", "}{productVariantK}
								</chakra.p>}
								<chakra.h1
									mt={2} color={mode("gray.600", "gray.200")} fontWeight="bold" fontSize="md" display={{ base: 'block', md: 'none' }}>
									{(product.grouped&&<>va. € {product.minPrice.toFixed(2)}</>)||<>€ {product.NewPrice.toFixed(2)}</>}
								</chakra.h1>
							</Box>
							<Box mt="auto">
								<chakra.h1 color={mode("gray.600", "gray.200")} fontWeight="bold" fontSize="lg" display={{ base: 'none', md: 'block' }}>
									{(product.grouped&&<>va. € {product.minPrice.toFixed(2)}</>)||<>€ {product.NewPrice.toFixed(2)}</>}
								</chakra.h1>
								<Spacer />
							</Box>
						</Box>
					</Box>
					<Box
						display={{ base: 'block', md: 'none' }}
						cursor={"pointer"}
						onClick={e=>{console.log(product.keyer);openProduct(product.keyer)}}
						mt="auto"
						mb={3}
						pt={4}
						ps={1}
						pe={3}
						ms={-9}
						w={{base: "95px"}}
						h={{base: "95px"}}
						fontSize={"xl"}
					>
						<Image
						mx="auto"
						my="auto"
							maxW={{base: "80px"}}
							maxH={{base: "80px"}}
							src={`https://apiv2.beantrack.app/images/${getData.productImage||"placeholder-image.png"}`} alt={getData.productName}
						/>
					</Box>
					<Box
						cursor={"pointer"}
						onClick={e=>{console.log(e);openProduct(product)}}
						mx="auto"
						pt={3}
						pe={4}
						ms={-9}
						fontSize={"xl"}
					>
						<i className="fad fa-plus"></i>
					</Box>
				</LinkBox>
				<Collapse animateOpacity in={isOpenProduct===product.keyer}><Box
					bg="gray.25"
					_dark={{
						bg: "gray.600",
						borderColor: "gray.500"
					}}
					border={"1px"}
					borderColor={"gray.100"}
					p={3}
				>
					{product.grouped&&<Box w="100%" mb={3}>
						<Text fontSize={"md"} fontWeight={600}>Item</Text>
						<SelectElement
							menuPortalTarget={document.body}
							defaultValue={{...getData, value: getData.Item, label: arrayStringer(getData.productUnit, getData.productVariant)}}
							menuPosition={'fixed'}
							menuPlacement="auto"
							isSearchable={false}
							options={
								product.items.map(z=>{
									return {
										...z,
										value: z.Item,
										label: arrayStringer(z.productUnit, z.productVariant)
									}
								})
							}
							onChange={e=>{setData(e)}}
						/>
					</Box>}
					<HStack>
						<Box>
							<Text fontWeight={600}>€ {getData.NewPrice.toFixed(2)}</Text>
							{/* <Text fontSize={"xs"}>spaar {round(appSettings.pointsPerEuro*getData.NewPrice, 0)} punten p/s.</Text> */}
						</Box>
						<Spacer/>
						<Box>
							<HookUsage />
							{inCart(getData.Item)&&<><HStack>
								<Spacer/>
								<Text fontWeight={600} pt={2}>€ {(getItem(getData.Item).quantity*getData.NewPrice).toFixed(2)}</Text>
							</HStack>
							{/* <HStack>
								<Spacer/>
								<Text fontSize={"xs"}>({round(appSettings.pointsPerEuro*(getItem(getData.Item).quantity*getData.NewPrice), 0)} punten)</Text>
							</HStack> */}
							</>}
						</Box>
					</HStack>
				</Box></Collapse>
		</Box>
	);
	function HookUsage() {
		const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
		  useNumberInput({
			step: 1,
			defaultValue: "",
			min: 0,
			max: 10000,
			precision: 0,
		  })
		  const pq = (inCart(getData.Item)&&getItem(getData.Item).quantity)||0

		const inc = getIncrementButtonProps()
		const dec = getDecrementButtonProps()
		const input = getInputProps()
		const handlePlus = () => {
			if (pq===0) { addItem(getData, 1); return; }
			updateItemQuantity(getData.Item, pq+1);
		};
		const handleMinus = () => {
				if (pq-1===0) { removeItem(getData.Item); return; }
				updateItemQuantity(getData.Item, pq-1)
		};
		function debounce(func, timeout = 1500){
			let timer;
			return (...args) => {
			  clearTimeout(timer);
			  timer = setTimeout(() => { func.apply(this, args); }, timeout);
			};
		}

		const debounceInput = debounce((x) => handleInput(x));
		const handleInput = (x) => {
			if (x.target.value===0||x.target.value==="") { removeItem(getData.Item); return; }
			if (pq===0) { addItem(getData, Number(x.target.value)); return; }
			updateItemQuantity(getData.Item, Number(x.target.value));
		};
		return (
		  <HStack maxW='186px'>
			<IconButton borderRadius={"md"} color="red.400"
				size={'sm'}
				me={2}
				icon={<FaTrashAlt />}
				aria-label={'Uit winkelwagen'}
				disabled={!inCart(getData.Item)}
				onClick={()=>removeItem(getData.Item)}
			/>
			<Button borderRadius={"md"} size={"sm"} disabled={!inCart(getData.Item)} onClick={handleMinus}>-</Button>
			<Input textAlign={"center"} borderRadius={"lg"} size={"sm"} type={"number"} defaultValue={pq||""} onChange={debounceInput} bg={"white"} _dark={{bg: "gray.700", borderColor: "gray.100"}}/>
			<Button borderRadius={"md"} size={"sm"} onClick={handlePlus}>+</Button>
		  </HStack>
		)
	}
};
export default Page

