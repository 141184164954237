import create from 'zustand'
import * as _items from "./helpers/items";
import * as _accounts from "./helpers/accounts";
import * as _user from "./helpers/user";
//import { insert,  } from "./helpers/crud.ts";

import { __B2BAPI_V3 } from '../api';
export const useMainStore = create( (set, get) => ({

// setting
    appSettings: {},
    initAppSettings: async () => {
        try {
            const response = await __B2BAPI_V3.get(`/d/b2bSettings`);
            console.log(response);
            set({ appSettings: response.data[0] });
            return response;
        } catch (error) {
            set({ appSettings: {} });
        }
    },

// setting

// items store START
    appItems: [],
    btProducts: [],
    userItems: [],
    userWorkshops: [],
    initUserItems: async (ID) => {
        let lID = ID;
        if (!ID) lID = get().user.from;
        try {
            const response = await _items.getUserItems(lID);
            console.log(response);
            set({ userItems: response.itemsa, userWorkshops: response.trainItemsa });
            return response;
        } catch (error) {
            set({ userItems: [] });
        }
    },
    initAppItems: async () => {
        try {
            const response = await _items.getAppItems();
            console.log(response);
            set({ appItems: response });
            return response;
        } catch (error) {
            console.log(error);
            set({ appItems: [] });
        }
    },
    updatelocalItems: (newItem) => {
        try {
            const response = get().appItems;
            const objIndex = response.findIndex((obj => obj.Item === newItem.Item));
            response[objIndex] = newItem;
            console.log(response);
            set({ appItems: response });
            return response;
        } catch (error) {
            console.log(error);
            set({ appItems: [] });
        }
    },
// items store END

// accounts store START
    account: [],
    accounts: [],
    userAddresses: [],
    initAccount: async (ID) => {
        let lID = ID;
        if (!ID) lID = get().user.from;
        try {
            const response = await _accounts.getAccount(lID);
            set({ account: response });
            return response;
        } catch (error) {
            set({ account: [] });
        }
    },
    initAddresses: async (ID) => {
        let lID = ID;
        if (!ID) lID = get().user.from;
        try {
            const response = await _accounts.getAddresses(lID);
            set({ userAddresses: response });
            return response;
        } catch (error) {
            set({ userAddresses: [] });
        }
    },
    initAccounts: async () => {
        try {
            const response = await _accounts.getAccounts();
            set({ accounts: response });
            return response;
        } catch (error) {
            set({ accounts: [] });
        }
    },
    makeAppAccount: async (ID) => {
        await __B2BAPI_V3.get(`/app/accounts/makeAccount/${ID}`);
        await get().initAccounts();
    },
    deleteAppAccount: async (ID) => {
        await _accounts.deleteAppAccount(ID);
        await get().initAccounts();
    },
// accounts store END

// user store START
    user: {},
    setUser: async (user) => {
        set({ user: user });
    },
    getUser: async (ID) => {
        try {
            const response = await _user.getUser(ID);
            set({ user: response });
            return response;
        } catch (error) {
            set({ user: [] });
        }
    },
// user store END

}))