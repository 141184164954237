import pennyBoard from './penny-board.png';
import {
	Button,
	Divider,
	Heading,
	HStack,
	chakra,
	Text,
	useColorMode,
	useColorModeValue,
	VStack, Box
} from '@chakra-ui/react';
import CartListItem from './CartListItem'
import useWindowDimensions from "helpers/screensize";


export default function Cart({cartList}) {
	const { height } = useWindowDimensions();
	const backgroundColor = useColorModeValue('white', 'gray.700');
	const secodaryTextColor = useColorModeValue('gray.600', 'gray.400');

	return (
		<VStack
			width="full"
			height="full"
			padding={{sm: 3, md: 4}}
			spacing="0"
			alignItems="flex-start"
			backgroundColor={backgroundColor}
		>
			<chakra.h1
				fontSize={{sm: "sm", md: "lg"}}
				mb={2}
				fontWeight="bold"
				color="gray.800"
				_dark={{
					color: "white",
				}}
			>
				Bestelling
			</chakra.h1>
			<Box h={{ base: '60vh', md: "100%" }} w="full" overflowY={"auto"} sx={{
				'&::-webkit-scrollbar': {
					width: '16px',
					backgroundColor: `rgba(0, 0, 0, 0.10)`,
				},
				'&::-webkit-scrollbar-thumb': {
					borderRadius: '8px',
					backgroundColor: `rgba(0, 0, 0, 0.10)`,
				},
			}}>
				{cartList.items.map(product => <CartListItem data={{ product }} key={product.Item} />)}
			</Box>

			<Divider/>
			<Box w={"full"}>
				<HStack
					justifyContent="space-between"
					pt={4}
					width="full">
					<Text fontSize={"md"}>Verzendkosten</Text>
					<Heading size="md">€ {cartList.shipping}</Heading>
				</HStack>
				<HStack
					justifyContent="space-between"
					width="full">
					<Text fontSize={"lg"}>Totaal</Text>
					<Heading size="lg">€ {cartList.total}</Heading>
				</HStack>
			</Box>
		</VStack>
	);
}