import React, {useEffect, useState} from "react";
import { CloseIcon, HamburgerIcon, MoonIcon, SunIcon, SettingsIcon } from '@chakra-ui/icons';
import {
	Avatar, Box, Button, Center, Flex, HStack, IconButton, Link, Menu, chakra, Icon,
	MenuButton, MenuDivider, MenuItem, MenuList, Stack, Text, useColorMode, useColorModeValue, useDisclosure, VStack, useOutsideClick  ,DrawerOverlay, DrawerCloseButton, DrawerHeader, DrawerContent, DrawerBody, DrawerFooter, Input
} from '@chakra-ui/react';
import { ReactComponent as Logo } from './Bocca.svg';
import { useSignOut, useAuthUser } from 'react-auth-kit';
import { NavLink as RNavLink, useHistory, useLocation } from 'react-router-dom';


const NavLink = ({ children, to, onClick }) => {
	const { pathname } = useLocation();
	const bgc = useColorModeValue('greenA.700', 'greenA.800');
	return (

	<Link
		color={useColorModeValue('gray.100', 'gray.200')}
		as={RNavLink}
		px={2}
		py={1}
		rounded={'md'}
		_hover={{
			textDecoration: 'none',
			bg: bgc,
		}}
		bg={("/"+pathname.split("/")[1]===to)&&bgc}
		to={to}
		onClick={onClick}
	>
		{children}
	</Link>
)};

export default function WithAction({ Cart, Links }) {
	const signOut = useSignOut()
	const auth = useAuthUser();
	const user = auth()
	const history = useHistory();
	const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
	const { colorMode, toggleColorMode } = useColorMode();
	const mainbg = useColorModeValue('green.600', 'green.900');
	const linkColor = useColorModeValue('gray.900', 'gray.100');
	const logobg = useColorModeValue('white', 'gray.100');
	const buttonbg = useColorModeValue('greenA.700', 'greenA.800');
	const buttonbgH = useColorModeValue('greenA.800', 'greenA.900');
	const ref = React.useRef()

	useOutsideClick({
	  ref: ref,
	  handler: () => onClose(),
	})

	return (
		<>
			<Box bg={mainbg} px={{sm: "2vw", md: "5vw", lg: "7vw"}} w="100%"ref={ref}>
				<Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
					<Button
						size={'md'}
						aria-label={'Open Menu'}
						display={{ md: 'none' }}
						onClick={onToggle}
						bg={buttonbg}
						_active={{bg: useColorModeValue('green.700', 'green.800')}}
						color={linkColor}
					>
						{!isOpen&&<Text color={linkColor}><i className="fad fa-bars"></i></Text>}
						{isOpen&&<Text color={linkColor}><i className="fad fa-times"></i></Text>}
					</Button>
					<HStack spacing={8} alignItems={'center'}>
						<Box color={logobg}>
							<HStack spacing={0}>
								<Logo
									style={{
										height:'22px',
										marginRight: '5px'
									}}
								></Logo>
								{/* <VStack spacing={0} align={"left"}>
									<Text fontSize={"lg"} fontWeight={"extrabold"} color={linkColor}>BOCCA</Text>
									<Text fontSize={"lg"} fontWeight={"extrabold"} display={{ sm: 'block', md: 'none' }}>BOCCA</Text>
								</VStack> */}
							</HStack>
						</Box>
						<HStack
							as={'nav'}
							spacing={4}
							display={{ base: 'none', md: 'flex' }}>
							{Links.map(({name, href}) => (
								<NavLink key={name} to={href}><Text fontSize={"medium"} fontWeight={"bold"} color={linkColor}>{name}</Text></NavLink>
							))}
						</HStack>
					</HStack>
					<Flex alignItems={'center'}>
						<Stack direction={'row'} spacing={4}>
							{Cart&&<Cart />}
							{/* <Button onClick={toggleColorMode} bg={buttonbg} display={{ sm: 'none', md: 'block' }}>
								{colorMode === 'light' ? <MoonIcon color={"white"} /> : <SunIcon />}
							</Button> */}

							<Menu>
								<MenuButton
									as={Button}
									rounded={'xl'}
									variant={'link'}
									cursor={'pointer'}
									py={2}
									bg={buttonbg}
									_hover={{
										textDecoration: 'none',
										bg: buttonbgH,
									}}
								>
										<Text color={linkColor}><i className="fad fa-user-cog"></i></Text>
									{/* <Avatar
										size={'sm'}
										src={'https://avatars.dicebear.com/api/male/username.svg'}
									/> */}
								</MenuButton>
								<MenuList alignItems={'center'}>
									{/* <br />
									<Center>
										<Avatar
											size={'2xl'}
											src={'https://avatars.dicebear.com/api/male/username.svg'}
										/>
									</Center> */}
									<br />
									<Center>
										<b>{user?.name}</b>
									</Center>
									<Center>
										<p>{user?.email}</p>
									</Center>
									<br />
									<MenuDivider />
									<MenuItem onClick={toggleColorMode} >{colorMode === 'light' ? <MoonIcon me={2} /> : <SunIcon me={2} />} {colorMode === 'light' ? "Dark mode" : "Light mode"}</MenuItem>
									<MenuDivider />
									<MenuItem onClick={()=>history.push("/fnbbdfyjr546456efdfg657")} >Switch van account</MenuItem>
									<MenuItem onClick={()=>history.push("/mijn-account")} >Mijn account</MenuItem>
									<MenuDivider />
									<MenuItem onClick={async e=>{
										signOut();
										history.push("/");
									}}>Logout</MenuItem>
								</MenuList>
							</Menu>
						</Stack>
					</Flex>
				</Flex>

				{isOpen ? (
					<Box pb={4} display={{ md: 'none' }}>
						<Stack as={'nav'} spacing={4}>
							{Links.map(({name, href}) => (
								<NavLink key={name} to={href} onClick={onClose}><Text fontSize={"medium"} fontWeight={"bold"} color={linkColor}>{name}</Text></NavLink>
							))}
						</Stack>
					</Box>
				) : null}
			</Box>
		</>
	);
}