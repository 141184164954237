import { ColorModeScript } from '@chakra-ui/react';
import { AuthProvider } from 'react-auth-kit'
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import "assets/fontawesome/css/all.css";
import { locale, loadMessages } from 'devextreme/localization';
import nlMessages from 'devextreme/localization/messages/nl.json';
import moment from 'moment';
import localization from 'moment/locale/nl';
import { CartProvider } from "react-use-cart";

loadMessages(nlMessages);
locale("nl");
moment.locale('nl', localization);

const app = (
  <CartProvider>
  <AuthProvider authType = {'localStorage'} authName={'_auth'} >
    <BrowserRouter>
      <ColorModeScript />
      <App />
    </BrowserRouter>
  </AuthProvider>
  </CartProvider>
);

ReactDOM.render(app, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
