import { chakra, useToken } from '@chakra-ui/react'
import React from 'react'

export const Logo = (props) => {
  const { iconColor = 'currentColor', ...rest } = props
  const color = useToken('colors', iconColor)
  return (
    <chakra.svg viewBox="0 0 167.58 34.9" fill={{base: "black", md: "white"}} {...rest}>
      <path d="M22.47,17.14V17c3.45-.79,5.9-3.41,5.9-7.74C28.37,3.66,24.46,0,17.5,0H0V34.9H17.56c8.41,0,12.28-4.17,12.28-9.66C29.84,21,27.12,17.77,22.47,17.14ZM10.35,7.84h4.07c2.35,0,3.5,1.15,3.5,3.34s-1.25,3.19-3.6,3.19h-4Zm4.81,19.28H10.35V20.59h4.81c2.51,0,3.76,1.2,3.76,3.29S17.77,27.12,15.16,27.12Z"/><path d="M48.3,0C38.12,0,31.66,6.71,31.66,17.45S38.12,34.9,48.3,34.9s16.6-6.71,16.6-17.45S58.49,0,48.3,0Zm0,26.28c-3.83,0-5.95-3.33-5.95-8.83s2.12-8.83,5.95-8.83,6,3.34,6,8.83S52.14,26.28,48.3,26.28Z"/><path d="M84,26.28c-3.38,0-5.85-2.78-5.85-8.83S80.65,8.68,84,8.68c2.92,0,4.34,2.06,4.79,4.68l9.33-3.93C96.53,3.68,91.24,0,84.08,0c-10.24,0-16.6,6.71-16.6,17.45S73.84,34.9,84.08,34.9c7.16,0,12.45-3.68,14.07-9.48l-9.33-3.94C88.37,24.11,87,26.28,84,26.28Z"/><path d="M119.42,25.1a4.27,4.27,0,0,1-3.09,1.18c-3.38,0-5.85-2.78-5.85-8.83s2.47-8.77,5.85-8.77c2.92,0,4.34,2.06,4.79,4.68l9.33-3.93C128.83,3.68,123.54,0,116.38,0c-10.24,0-16.6,6.71-16.6,17.45s6.36,17.45,16.6,17.45c7.16,0,12.45-3.68,14.07-9.48l-9.33-3.94A6.53,6.53,0,0,1,119.42,25.1Z"/><path d="M154.93,0H142.71l-12.6,34.9h10.51l2-6.16.54-1.62h10.88l.54,1.62h0l2,6.16h10.93Zm-9.18,19.38,2.8-8.41h.11l2.81,8.41Z"/>
    </chakra.svg>
  )
}
