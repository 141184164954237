import {
	Alert,
	AlertIcon,
	Box,
	Button,
	Flex,
	LightMode,
	Stack,
	Text,
	useColorModeValue as mode,
} from '@chakra-ui/react'
import React, { useState } from "react"
import { InputField } from './InputField'
import { Redirect, useHistory } from "react-router-dom"
import { useSignIn } from 'react-auth-kit';

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

export const SigninForm = () => {
	const [getError, setError] = useState(false);
	const signIn = useSignIn();
	const history = useHistory();

	const login = async (email, password) => {
		try {
			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ email:email.trim().toLowerCase(), password:password.trim() })
			};
			const responser = await fetch(`https://api.bocca.app/api/v1/auth/login`, requestOptions);
			const user = await handleResponse(responser);
			let tmpUser = {...user.user};
			delete tmpUser.password;
			console.log(tmpUser);
			if (!tmpUser.status) {
				setError("Gebruiker inactief.");
				return;
			}
			if (signIn({token: user.access_token, expiresIn:43200, tokenType: "Bearer", authState: tmpUser})) {
				//await dataLoader(tmpUser)
				setTimeout(() => {
					history.push("/");
				}, 500);

			} else {
				setError("Je email en/of wachtwoord klopt niet.");
			}
			//setLogin(true)
		} catch (error) {
			//const errorCode = error.code;
			//const errorMessage = error.message;
			setError("Je email en/of wachtwoord klopt niet.");
		};
	}

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault()
				login(e.target[0].value, e.target[1].value)
			}}
		>
			<Stack spacing="8">
				{getError ? <Alert status='error' rounded={"lg"}>
					<AlertIcon />
					{getError}
				</Alert> : null}
				<InputField label="Email" type="email" required />
				<Box>
					<InputField label="Wachtwoord" type="password" required />
					<Box
						display="inline-block"
						as="a"
						href="/forget-password"
						color={mode('green.600', 'green.300')}
						fontWeight="semibold"
						fontSize="sm"
						mt="3"
					>
						Wachtwoord vergeten?
					</Box>
				</Box>
			</Stack>
			<Flex
				spacing="4"
				direction={{
					base: 'column-reverse',
					md: 'row',
				}}
				mt="6"
				align="center"
				justify="space-between"
			>
				<Text color={mode('gray.600', 'gray.400')} fontSize="sm" fontWeight="semibold">
					{/* New user?{' '}
					<Box as="a" href="#" color={mode('green.600', 'green.300')}>
						Create account
					</Box> */}
				</Text>
				<LightMode>
					<Button
						mb={{
							base: '4',
							md: '0',
						}}
						w={{
							base: 'full',
							md: 'auto',
						}}
						type="submit"
						colorScheme="green"
						size="lg"
						fontSize="md"
						fontWeight="bold"
					>
						Login
					</Button>
				</LightMode>
			</Flex>
		</form>
	)
}
