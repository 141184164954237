import React, { useState, useEffect } from 'react';
import Navbar from "./components/Navbar";
import ProductListItem from './components/ProductListItem'
import { Box, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, IconButton, Flex, Icon, Image, chakra, HStack, Input, useNumberInput, Divider, Text, Spacer, Progress } from '@chakra-ui/react';
import { MdEmail, MdHeadset, MdLocationOn, MdScale } from "react-icons/md";
import { FaBalanceScale, FaEuroSign, FaTrashAlt } from "react-icons/fa";
import { uniqBy, orderBy, filter, debounce, groupBy, toArray, minBy } from "lodash";
import { useCart } from "react-use-cart";
import { _data, _utils } from "@bocca-app-repo/app-components";
import InfiniteScroll from "react-infinite-scroll-component";
import { customAlphabet } from 'nanoid/non-secure'
import { Waypoint } from 'react-waypoint';
import Sscroll from './components/Sscroll';
import useWindowDimensions from "helpers/screensize";
import { useHistory } from 'react-router-dom';
import { useSpring, config } from "@react-spring/web";
import useScrollTo from 'react-spring-scroll-to-hook'


const nanoid = customAlphabet('1234567890abcdef', 5)

const Page = (props) => {
	//console.log(_utils.__useQuery().get("cat"));
	const {scrollTo} = useScrollTo({ mass: 1, tension: 180, friction: 12 })
	const catQ = _utils.__useQuery().get("cat");
	const [, setY] = useSpring(() => ({ y: 0 }))
	const history = useHistory();
	const { height } = useWindowDimensions();
	const { userItems } = _data.useMainStore(state => state.account);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [getSearch, setSearch] = useState("");
	const [getEenheid, setEenheid] = useState([]);
	const [getCatSelected, setCatSelected] = useState(catQ||"");

	const [isOpenProduct, setIsOpenProduct] = useState("");
	const [getOpenProductData, setOpenProductData] = useState({});
	const [getPager, setPager] = useState(15);
	const onCloseProduct = useDisclosure().onClose;

	const [getState, setState] = useState(false);
	const [loading, setLoading] = useState(false);

	const baseListz = React.useMemo(() => {
		console.log(userItems);
        let prodsb = userItems.map(z=>{return {...z, id: z.Item, price: z.NewPrice }});
        let prods1 = toArray(groupBy(prodsb.filter(o=>o.productParent), "productParent")).map(e=>{
			const iid=nanoid();
			return {
				keyer:iid,
				productName: e[0].productParent,
				productImage: e[0].productImage,
				items:orderBy(e, ['NewPrice'], ['asc']),
				minPrice: minBy(e, "NewPrice").NewPrice,
				grouped: true,
				productCatagory: e[0].productCatagory
			}});
        let prods2 = prodsb.filter(o=>!o.productParent).map(ee=>{
			const iid=nanoid();
			const e = [ee]
			return {
				keyer:iid,
				...ee,
				items:e,
				grouped: false,
			}
		})
		const prods = [...prods1, ...prods2];

		return prods
	}, [userItems]);

	const userItemsList0 = React.useMemo(() => {
        let prods = baseListz
        if (getSearch) {
            let searcjQery = getSearch.toLowerCase(),
            searcjQerySplit = getSearch.toLowerCase().split(" "),
            displayedContacts = prods.filter((el) => {
                let searchValue = el.productName.toLowerCase();
                const ress1 =(searchValue.indexOf(searcjQerySplit[0]) !== -1)
                const ress2 = (searchValue.indexOf(searcjQerySplit[1]) !== -1)

                return searcjQerySplit[1]?(ress1&&ress2):(ress1);
            })
            prods = displayedContacts;
            console.log(searcjQerySplit);
        };
        return prods
    }, [baseListz, getSearch]);

	const userItemsList = React.useMemo(() => {
		setPager(15)
        let prods = userItemsList0
        return orderBy(prods, ['productCatagory'], ['asc'])
    }, [userItemsList0]);

	const catsList = React.useMemo(() => {
        let prods = orderBy(uniqBy(userItemsList0, "productCatagory"), ['productCatagory'], ['asc']);
		//return [{productCatagory: "Alles"}, ...orderBy(uniqBy(prods, "productCatagory"), ['productCatagory'], ['asc'])]
		setCatSelected(x=>x||prods[0].productCatagory);
		return prods;
	}, [userItemsList0]);

	const openProduct = (data) => {
		//setOpenProductData(data);
		console.log(isOpenProduct===data?"":data);
		setIsOpenProduct(x=>x===data?"":data);
		setTimeout(() => {
			if (isOpenProduct!==data) {const element = document.getElementById(data);
			const yOffset = -130;
			const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
			window.scrollTo({top: y, behavior: 'auto'});}
		}, 400);
	}
	const CloseProduct = () => {
		//setOpenProductData({});
		setIsOpenProduct("");
	}
	useEffect(() => {
		console.log(catQ);
		return () => {};
	}, [catQ]);
	useEffect(() => {
		if (getCatSelected) {
			const element = document.getElementById(getCatSelected);
			const yOffset = -130;
			const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
			setTimeout(() => {
				window.scrollTo({top: y, behavior: 'auto'});
			}, 200);

			//scrollTo(element)
			/* setY({
				y: element.getBoundingClientRect().top,
				from: { y: window.scrollY },
				onFrame: props => window.scroll(0, props.y)
			  }) */
			//setY({ y: element.getBoundingClientRect().top });
			//element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
			//const element1 = document.getElementById(getCatSelected+"btn");
			//element1.scrollIntoView({behavior: "smooth", block: "end", inline: "start"});
		}
	}, [getCatSelected]);

	return (
		<>
			<Navbar data={{ isOpen, onOpen, onClose, getSearch, setSearch, getEenheid, setEenheid, catsList, getCatSelected: catQ, setCatSelected }}/>
			<Box pt={"80px"} pb={"80px"} px={{base: "2vw", md: "5vw", lg: "7vw"}}>
				{/* <InfiniteScroll
					dataLength={getPager}
					next={()=>setTimeout(() => {setPager(z=>{
						console.log(z+z);

							if ((z+z)>=userItemsList.length) return userItemsList.length;
							return z+z


					})}, 1500)}
					hasMore={!(getPager===userItemsList.length)}
					loader={<Progress colorScheme={"green"} my={6} size='xs' isIndeterminate />}
					<Waypoint
						onEnter={this._handleWaypointEnter}
						onLeave={this._handleWaypointLeave}
					/>
				> */}
					{toArray(groupBy(userItemsList, "productCatagory")).map((cat) =><Box id={cat[0].productCatagory} key={cat[0].productCatagory+"kk"}>
					<Waypoint onEnter={()=>{
							history.push({
								search: `?${(cat[0].productCatagory&&`cat=${cat[0].productCatagory}`)||""}`
							});
							//setSearch(document.getElementById("searchfilli").value)setCatSelected(cat[0].productCatagory)
					}} topOffset={"40%"} bottomOffset={"40%"}><Box>
						<Text fontWeight={600} mb={2}>{cat[0].productCatagory}</Text>
					{cat.map(product => {return <ProductListItem key={product.keyer} data={{ product, openProduct, CloseProduct, isOpenProduct }}/>})}
					</Box></Waypoint></Box>)}
				{/* </InfiniteScroll> */}
			</Box>
			{/* <ProductPopup data={{isOpenProduct, CloseProduct, getOpenProductData}}/> */}
		</>
	);
};
export default Page

function ProductPopup({data}) {
	const {isOpenProduct, CloseProduct, getOpenProductData} = data;

    const {
		isEmpty,
		totalUniqueItems,
        totalItems,
		items,
        metadata,
        emptyCart,
		addItem,
		inCart,
		getItem,
		updateItemQuantity,
		removeItem,
        updateCartMetadata
	} = useCart();

	return (
		<>
			<Modal onClose={CloseProduct} isOpen={isOpenProduct} isCentered>
				<ModalOverlay
					bg='blackAlpha.300'
					backdropFilter='blur(10px)'
				/>
				<ModalContent borderRadius={"lg"}>
					{/* <ModalHeader>Modal Title</ModalHeader>
					<ModalBody> */}
					<ModalCloseButton />
					<Box
						w="100%"
						mx="auto"
						bg="white"
						_dark={{ bg: "gray.800" }}
						//shadow="lg"
						rounded="lg"
						overflow="hidden"
					>
						<Image
							mx="auto"
							maxW={60}
							maxH={60}
							my={6}
							fit="cover"
							objectPosition="center"
							src={`https://apiv2.beantrack.app/images/${getOpenProductData.cover||"placeholder-image.png"}`}
							alt="avatar"
							_hover={{
							transform: 'scale(1.05)',
							}}
						/>

						<Flex alignItems="center" px={2} py={3} bg="green.800">
							<chakra.h1 mx={3} color="white" fontWeight="bold" fontSize="lg">
								{getOpenProductData.Naam||getOpenProductData.ItemDescription}
							</chakra.h1>
						</Flex>

						<Box py={4} px={6}>
							{getOpenProductData.ExtraInfo&&<chakra.h1
							fontSize="xl"
							fontWeight="bold"
							color="gray.800"
							_dark={{ color: "white" }}
						>
								{getOpenProductData.ExtraInfo}
							</chakra.h1>}

							{getOpenProductData.productInfo&&<chakra.p py={2} color="gray.700" _dark={{ color: "gray.400" }}>
								{getOpenProductData.productInfo}
							</chakra.p>}

							{/* <Flex
								alignItems="center"
								mt={4}
								color="gray.700"
								_dark={{ color: "gray.200" }}
							>
								<Icon as={BsFillBriefcaseFill} h={6} w={6} mr={2} />

								<chakra.h1 px={2} fontSize="sm">
									{getOpenProductData.unitTagger}
								</chakra.h1>
							</Flex>

							<Flex
								alignItems="center"
								mt={4}
								color="gray.700"
								_dark={{ color: "gray.200" }}
							>
								<Icon as={MdLocationOn} h={6} w={6} mr={2} />

								<chakra.h1 px={2} fontSize="sm">
								California
								</chakra.h1>
							</Flex> */}
							{!(getOpenProductData.unitTagger==="GEEN")&&<Flex
								alignItems="center"
								mt={4}
								color="gray.700"
								_dark={{ color: "gray.200" }}
							>
								<Icon as={FaBalanceScale} h={6} w={6} mr={2} />

								<chakra.h1 px={2} fontSize="md" fontWeight={600}>
									{getOpenProductData.unitTagger}
								</chakra.h1>
							</Flex>}
							<Flex
								alignItems="center"
								mt={4}
								color="gray.700"
								_dark={{ color: "gray.200" }}
							>
								<Icon as={FaEuroSign} h={6} w={6} mr={2} />

								<chakra.h1 px={2} fontSize="lg" fontWeight={600}>
									{getOpenProductData.NewPrice}
								</chakra.h1>
							</Flex>
						</Box>
						<Box py={4} px={6}>
							<HookUsage />
						</Box>
					</Box>
					<Divider />
					{/* </ModalBody> */}
					<ModalFooter>
						<Text fontWeight={600} fontSize={"lg"}>€ {getOpenProductData.NewPrice}</Text>
						<Spacer/>
						<Button onClick={CloseProduct}>Sluit</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
	function HookUsage() {
		const pq = (inCart(getOpenProductData.Item)&&getItem(getOpenProductData.Item).quantity)||0

		const handlePlus = () => {
			if (pq===0) { addItem({...getOpenProductData, price: getOpenProductData.NewPrice.toFixed(2), id: getOpenProductData.Item }, 1); return; }
			updateItemQuantity(getOpenProductData.Item, pq+1);
		};
		const handleMinus = () => {
				if (pq-1===0) { removeItem(getOpenProductData.Item); return; }
				updateItemQuantity(getOpenProductData.Item, pq-1)
		};
		function debounce(func, timeout = 1500){
			let timer;
			return (...args) => {
			clearTimeout(timer);
			timer = setTimeout(() => { func.apply(this, args); }, timeout);
			};
		}

		const debounceInput = debounce((x) => handleInput(x));
		const handleInput = (x) => {
			if (x.target.value===0||x.target.value==="") { removeItem(getOpenProductData.Item); return; }
			if (pq===0) { addItem({...getOpenProductData, price: getOpenProductData.NewPrice.toFixed(2), id: getOpenProductData.Item }, Number(x.target.value)); return; }
			updateItemQuantity(getOpenProductData.Item, Number(x.target.value));
		};
		return (
			<HStack>
				<IconButton borderRadius={"md"} color="red.400"
					size={'sm'}
					me={2}
					icon={<FaTrashAlt />}
					aria-label={'Uit winkelwagen'}
					disabled={!inCart(getOpenProductData.Item)}
					onClick={()=>removeItem(getOpenProductData.Item)}
				/>
				<Button borderRadius={"md"} size={"sm"} disabled={!inCart(getOpenProductData.Item)} onClick={handleMinus}>-</Button>
				<Input textAlign={"center"} borderRadius={"lg"} size={"sm"} type={"number"} defaultValue={pq||""} onChange={debounceInput}/>
				<Button borderRadius={"md"} size={"sm"} onClick={handlePlus}>+</Button>
			</HStack>
		)
	}
}



/* function HookUsage() {
	const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
	  useNumberInput({
		step: 1,
		defaultValue: 0,
		min: 1,
		max: 10000,
		precision: 0,
	  })

	const inc = getIncrementButtonProps()
	const dec = getDecrementButtonProps()
	const input = getInputProps()

	return (
	  <HStack>
		<Button {...dec}>-</Button>
		<Input {...input} />
		<Button {...inc}>+</Button>
	  </HStack>
	)
} */