import React from 'react';
import {
	Badge, Box, Button, Checkbox, Divider, Flex, FormControl, FormLabel, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spacer, Text, VStack, useColorModeValue as mode, Select, InputGroup, InputLeftElement, InputRightElement, Radio
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import numericalFieldHandler from '../../../utils/numericalFieldHandler';
import translateLabel from '../../../utils/translateLabel';

const RepeatMonthlyOn = ({
	id,
	mode,
	on,
	hasMoreModes,
	handleChange,
	translations
}) => {
	const isActive = mode === 'on';

	return (
		<HStack className={`${!isActive && 'opacity-50'}`} mt={4}>
			<Box>
				{hasMoreModes && (
					<Radio
						id={id}
						type="radio"
						name="repeat.monthly.mode"
						aria-label="Repeat monthly on"
						value="on"
						isChecked={isActive}
						onChange={handleChange}
					>
						{translateLabel(translations, 'repeat.monthly.on_day')}
					</Radio>
				)}
			</Box>

			<Box>
				<Select
					id={`${id}-day`}
					name="repeat.monthly.on.day"
					aria-label="Repeat monthly on a day"
					className="form-control"
					value={on.day}
					disabled={!isActive}
					onChange={numericalFieldHandler(handleChange)}
				>
					{[...new Array(31)].map((day, i) => <option key={i} value={i + 1}>{i + 1}</option>)}
				</Select>
			</Box>
		</HStack>
	);
};
RepeatMonthlyOn.propTypes = {
	id: PropTypes.string.isRequired,
	mode: PropTypes.oneOf(['on', 'on the']).isRequired,
	on: PropTypes.shape({
		day: PropTypes.number.isRequired,
	}).isRequired,
	hasMoreModes: PropTypes.bool.isRequired,
	handleChange: PropTypes.func.isRequired,
	translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default RepeatMonthlyOn;
